/* eslint-disable */
import {
  Staff,
  Resident,
  Plan,
  Company,
  Office,
  PlanHistory,
  PlanMonitoringDetail
} from '../index';

declare var Object: any;
export interface PlanMonitoringInterface {
  "newTask"?: string;
  "summary"?: string;
  "id"?: number;
  "staffId"?: number;
  "residentId"?: number;
  "planId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "recordedAt"?: Date|string;
  "updaterId"?: number;
  "_details"?: Array<any>;
  staff?: Staff;
  resident?: Resident;
  plan?: Plan;
  company?: Company;
  office?: Office;
  history?: PlanHistory;
  details?: PlanMonitoringDetail[];
}

export class PlanMonitoring implements PlanMonitoringInterface {
  "newTask": string = '';
  "summary": string = '';
  "id": number = <any>null;
  "staffId": number = <any>null;
  "residentId": number = <any>null;
  "planId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "recordedAt": Date|string = <any>null;
  "updaterId": number = <any>null;
  "_details": Array<any> = <any>[];
  staff?: Staff;
  resident?: Resident;
  plan?: Plan;
  company?: Company;
  office?: Office;
  history?: PlanHistory;
  details?: PlanMonitoringDetail[];
  constructor(data?: Partial<PlanMonitoringInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PlanMonitoring`.
   */
  public static getModelName() {
    return "PlanMonitoring";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PlanMonitoring for dynamic purposes.
  **/
  public static factory(data: PlanMonitoringInterface): PlanMonitoring{
    return new PlanMonitoring(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PlanMonitoring',
      plural: 'PlanMonitorings',
      path: 'PlanMonitorings',
      idName: 'id',
      properties: {
        "newTask": {
          name: 'newTask',
          type: 'string'
        },
        "summary": {
          name: 'summary',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "planId": {
          name: 'planId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "recordedAt": {
          name: 'recordedAt',
          type: 'Date|string',
          default: <any>null
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "_details": {
          name: '_details',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        plan: {
          name: 'plan',
          type: 'Plan',
          model: 'Plan',
          relationType: 'belongsTo',
                  keyFrom: 'planId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        history: {
          name: 'history',
          type: 'PlanHistory',
          model: 'PlanHistory',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'recordId'
        },
        details: {
          name: 'details',
          type: 'PlanMonitoringDetail[]',
          model: 'PlanMonitoringDetail',
          relationType: 'embedsMany',
                  keyFrom: '_details',
          keyTo: 'id'
        },
      }
    }
  }
}
