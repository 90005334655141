/* eslint-disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { CompanyApi } from './services/custom/Company';
import { OfficeApi } from './services/custom/Office';
import { ResidentApi } from './services/custom/Resident';
import { MoveHistoryApi } from './services/custom/MoveHistory';
import { StaffApi } from './services/custom/Staff';
import { FloorApi } from './services/custom/Floor';
import { RoomApi } from './services/custom/Room';
import { NoteApi } from './services/custom/Note';
import { CareHistoryApi } from './services/custom/CareHistory';
import { ProgressRecordApi } from './services/custom/ProgressRecord';
import { HospitalRecordApi } from './services/custom/HospitalRecord';
import { MealRecordApi } from './services/custom/MealRecord';
import { VitalRecordApi } from './services/custom/VitalRecord';
import { ExcretionRecordApi } from './services/custom/ExcretionRecord';
import { BathingRecordApi } from './services/custom/BathingRecord';
import { TransportRecordApi } from './services/custom/TransportRecord';
import { AbsenceApi } from './services/custom/Absence';
import { ReportApi } from './services/custom/Report';
import { ReportReviewApi } from './services/custom/ReportReview';
import { ReportConfirmationApi } from './services/custom/ReportConfirmation';
import { PlanHistoryApi } from './services/custom/PlanHistory';
import { AssessmentApi } from './services/custom/Assessment';
import { AssessmentDetailApi } from './services/custom/AssessmentDetail';
import { PlanApi } from './services/custom/Plan';
import { Plan2DetailApi } from './services/custom/Plan2Detail';
import { PlanWeeklyDetailApi } from './services/custom/PlanWeeklyDetail';
import { PlanDailyDetailApi } from './services/custom/PlanDailyDetail';
import { OptionApi } from './services/custom/Option';
import { RoleGroupApi } from './services/custom/RoleGroup';
import { FileInfoApi } from './services/custom/FileInfo';
import { GenericContentApi } from './services/custom/GenericContent';
import { ContactApi } from './services/custom/Contact';
import { InsuranceCardApi } from './services/custom/InsuranceCard';
import { PlanReviewApi } from './services/custom/PlanReview';
import { PlanMonitoringApi } from './services/custom/PlanMonitoring';
import { PlanMonitoringDetailApi } from './services/custom/PlanMonitoringDetail';
import { VisitPlanApi } from './services/custom/VisitPlan';
import { VisitSummaryApi } from './services/custom/VisitSummary';
import { VisitReportApi } from './services/custom/VisitReport';
import { VisitPlanDetailApi } from './services/custom/VisitPlanDetail';
import { AnnouncementApi } from './services/custom/Announcement';
import { ScheduleEventApi } from './services/custom/ScheduleEvent';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders<SDKBrowserModule> {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        CompanyApi,
        OfficeApi,
        ResidentApi,
        MoveHistoryApi,
        StaffApi,
        FloorApi,
        RoomApi,
        NoteApi,
        CareHistoryApi,
        ProgressRecordApi,
        HospitalRecordApi,
        MealRecordApi,
        VitalRecordApi,
        ExcretionRecordApi,
        BathingRecordApi,
        TransportRecordApi,
        AbsenceApi,
        ReportApi,
        ReportReviewApi,
        ReportConfirmationApi,
        PlanHistoryApi,
        AssessmentApi,
        AssessmentDetailApi,
        PlanApi,
        Plan2DetailApi,
        PlanWeeklyDetailApi,
        PlanDailyDetailApi,
        OptionApi,
        RoleGroupApi,
        FileInfoApi,
        GenericContentApi,
        ContactApi,
        InsuranceCardApi,
        PlanReviewApi,
        PlanMonitoringApi,
        PlanMonitoringDetailApi,
        VisitPlanApi,
        VisitSummaryApi,
        VisitReportApi,
        VisitPlanDetailApi,
        AnnouncementApi,
        ScheduleEventApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

