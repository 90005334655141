import { Type } from '@angular/core';
import { MatDateFormats } from '@angular/material/core';
import * as moment from 'moment';
import { CareDetailPageTrait } from './classes/care-detail-page-trait';
import { CareDetailBathingComponent } from './shared/dialogs/care-detail-bathing/care-detail-bathing.component';
import { CareDetailExcretionComponent } from './shared/dialogs/care-detail-excretion/care-detail-excretion.component';
import { CareDetailHospitalComponent } from './shared/dialogs/care-detail-hospital/care-detail-hospital.component';
import { CareDetailMealComponent } from './shared/dialogs/care-detail-meal/care-detail-meal.component';
import { CareDetailProgressComponent } from './shared/dialogs/care-detail-progress/care-detail-progress.component';
import { CareDetailTransportComponent } from './shared/dialogs/care-detail-transport/care-detail-transport.component';
import { CareDetailVitalComponent } from './shared/dialogs/care-detail-vital/care-detail-vital.component';
import { CareRecordType, RecordType } from './types';

export const careRecordTypes: CareRecordType[] = [
  'progress',
  'meal',
  'vital',
  'excretion',
  'bathing',
  'hospital',
  'transport',
];

export const recordModelNames: { [type in RecordType]: string } = {
  progress: 'ProgressRecord',
  meal: 'MealRecord',
  vital: 'VitalRecord',
  excretion: 'ExcretionRecord',
  bathing: 'BathingRecord',
  hospital: 'HospitalRecord',
  transport: 'TransportRecord',
  report: 'Report',
  note: 'Note',
  assessment: 'Assessment',
  plan: 'Plan',
  planMonitoring: 'PlanMonitoring',
  planReview: 'PlanReview',
  visitPlan: 'VisitPlan',
  visitReport: 'VisitReport',
  visitSummary: 'VisitSummary',
  schedule: 'ScheduleEvent',
};

export const careDetailDialogs: { [name in CareRecordType]: Type<CareDetailPageTrait<any>> } = {
  progress: CareDetailProgressComponent,
  meal: CareDetailMealComponent,
  vital: CareDetailVitalComponent,
  excretion: CareDetailExcretionComponent,
  bathing: CareDetailBathingComponent,
  hospital: CareDetailHospitalComponent,
  transport: CareDetailTransportComponent,
};

/**
 * 各記録タイプに対するアイコンやラベルの設定
 */
export const typeBadges: { [type in RecordType]: { icon: string; label: string } } = {
  progress: { icon: 'timeline', label: '経過' },
  hospital: { icon: 'local_hospital', label: '入院' },
  meal: { icon: 'restaurant', label: '食事水分' },
  vital: { icon: 'heartbeat', label: 'バイタル' },
  excretion: { icon: 'toilet', label: '排泄' },
  bathing: { icon: 'bath', label: '入浴' },
  transport: { icon: 'directions_car', label: '送迎' },
  report: { icon: 'description', label: '報告書' },
  note: { icon: 'comment', label: '' },
  assessment: { icon: 'assessment', label: 'アセスメント' },
  plan: { icon: 'assessment', label: '計画' },
  planMonitoring: { icon: 'assignment_turned_in', label: 'モニタリング' },
  planReview: { icon: 'question_answer', label: '担当者会議' },
  visitPlan: { icon: 'assessment', label: '訪問計画' },
  visitReport: { icon: 'description', label: '訪問報告書' },
  visitSummary: { icon: 'description', label: '情報提供書' },
  schedule: { icon: 'event_note', label: '' },
};

/**
 * レポート種類に対するバッジ名
 */
export const reportBadgeNames: { [num: number]: string } = {
  1: 'accident',
  2: 'complaint',
  3: 'nearmiss',
};

/**
 * 入居者の連絡先種別アイコン
 */
export const contactTypeIcons: { [type: string]: string } = {
  family: 'account_box',
  hospital: 'local_hospital',
  pharmacy: 'local_pharmacy',
};

/**
 * 入居者情報取得時のincludeデフォルト設定
 */
export const residentInclusionDefaults = [
  'photo',
  {
    relation: 'moveHistories',
    scope: {
      include: {
        relation: 'room',
        scope: { include: 'floor' },
      },
      where: {
        and: [
          { movedInAt: { lte: moment().endOf('day') } },
          {
            or: [
              { movedOutAt: { gte: moment().startOf('day') } },
              { movedOutAt: null },
            ],
          },
        ],
      },
    },
  },
];

/**
 * 計画書3/4のタイムラインの開始時間
 */
export const scheduleTimelineStart = 4;

/**
 * Material Datepickerの日付書式設定（デフォルト）
 */
export const CM_DATE_SELECTOR_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY年M月D日(ddd)',
    monthYearLabel: 'YYYY年M月',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM',
  },
};

/**
 * Material Datepickerの日付書式設定（曜日なし）
 */
export const CM_DATE_SELECTOR_FORMATS_WITHOUT_WEEKDAY: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY年M月D日',
    monthYearLabel: 'YYYY年M月',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM',
  },
};

/**
 * Material Datepickerの日付書式設定（月のみ）
 */
export const CM_MONTH_SELECTOR_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY年M月',
    monthYearLabel: 'YYYY年M月',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM',
  },
};
