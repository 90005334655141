<div class="mat-calendar-header">
  <div class="mat-calendar-controls">
    <span class="px-4 leading-9">{{ label }}</span>
    <div class="mat-calendar-spacer"></div>
    <button mat-button (click)="selectToday()">今日</button>
    <button mat-icon-button class="mat-calendar-previous-button" matTooltip="長押しすると1年戻ります"
      (mousedown)="onNavMouseDown('prev')" (mouseup)="onNavMouseUp('prev')"></button>
    <button mat-icon-button class="mat-calendar-next-button" matTooltip="長押しすると1年進みます"
      (mousedown)="onNavMouseDown('next')" (mouseup)="onNavMouseUp('next')"></button>
  </div>
</div>
