/* eslint-disable */

declare var Object: any;
export interface VisitPlanDetailInterface {
  "id"?: string;
  "date"?: Date|string;
  "problem"?: string;
  "solution"?: string;
  "evaluation"?: string;
}

export class VisitPlanDetail implements VisitPlanDetailInterface {
  "id": string = '';
  "date": Date|string = <any>null;
  "problem": string = '';
  "solution": string = '';
  "evaluation": string = '';
  constructor(data?: Partial<VisitPlanDetailInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VisitPlanDetail`.
   */
  public static getModelName() {
    return "VisitPlanDetail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of VisitPlanDetail for dynamic purposes.
  **/
  public static factory(data: VisitPlanDetailInterface): VisitPlanDetail{
    return new VisitPlanDetail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VisitPlanDetail',
      plural: 'VisitPlanDetails',
      path: 'VisitPlanDetails',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'Date|string'
        },
        "problem": {
          name: 'problem',
          type: 'string'
        },
        "solution": {
          name: 'solution',
          type: 'string'
        },
        "evaluation": {
          name: 'evaluation',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
