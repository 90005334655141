import { Component, OnInit, Input, Output, ViewChild, EventEmitter, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { CdkScrollable } from '@angular/cdk/overlay';
import { GlobalEventService } from 'src/app/services/global-event.service';

/**
 * 先頭に戻る・無限スクロール機能付きのコンテナ
 */
@Component({
  selector: 'cm-scroll-content',
  templateUrl: 'scroll-content.component.html',
  styleUrls: ['scroll-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollContentComponent implements OnInit {

  /**
   * 先頭に戻るボタンが表示されているかどうかのフラグ
   */
  backTopVisible = false;

  /**
   * 無限スクロールイベントを起こすかどうかのフラグ
   */
  infiniteObserved = true;

  /**
   * 無限スクロールのページカウント
   */
  infinitePageCount = 1;

  /**
   * 先頭に戻るボタンの表示を開始するスクロール位置
   * この値以上スクロールするとボタンが表示する
   */
  @Input() backTopThreshold = 500;

  /**
   * 先頭に戻るボタンの表示アニメーションのスピード
   */
  @Input() backTopShowSpeed = 500;

  /**
   * 先頭に戻るスクロールアニメーションのスピード
   */
  @Input() backTopMoveSpeed = 500;

  /**
   * 無限スクロールイベントを起こす下からのスクロール位置
   * 一番下までの距離がこの値を下回るとイベントを起こす
   */
  @Input() infiniteThreshold = 200;

  /**
   * 無限スクロールイベント通知用EventEmitter
   * page -> 現在のページ数
   * callback -> イベント処理後に無限スクロールを継続可否を引数にして呼ぶ
   */
  @Output() infinite = new EventEmitter<{page: number; callback: (continued: boolean) => void}>();

  /**
   * コンテナへの参照
   */
  @ViewChild(CdkScrollable, { static: true }) scrollable: CdkScrollable;

  constructor(
    private globalEvent: GlobalEventService,
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef,
  ) {
    this.globalEvent.on('scrollToTop').subscribe(() => this.scrollToTop());
  }

  ngOnInit () {
    this.scrollable.elementScrolled().subscribe(
      () => {
        const scrollTop = this.scrollable.measureScrollOffset('top');
        const distance = this.scrollable.measureScrollOffset('bottom');

        const backTopVisible = scrollTop > this.backTopThreshold;

        if (this.backTopVisible !== backTopVisible) {
          this.ngZone.run(() => {
            this.backTopVisible = backTopVisible;
            this.cdRef.markForCheck();
          });
        }

        if (this.infiniteObserved && distance <= this.infiniteThreshold) {
          this.ngZone.run(() => {
            this.infiniteObserved = false;
            this.infinite.emit({
              page: ++this.infinitePageCount,
              callback: (continued: boolean) => {
                if (continued) {
                  this.infiniteObserved = true;
                }
              },
            });
          });
        }
      },
    );
  }

  /**
   * ページの先頭に戻る
   */
  scrollToTop() {
    this.scrollable.scrollTo({top: 0, behavior: 'smooth'});
  }

  resetInfiniteState() {
    this.infiniteObserved = true;
    this.infinitePageCount = 1;
  }

  onBackTopClick(e: MouseEvent) {
    e.preventDefault();
    this.scrollToTop();
  }
}
