import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ImageResizerService } from 'src/app/services/image-resizer.service';
import { FileInfo } from 'src/loopback';

export type FileInfoWithData = FileInfo & {
  _blob?: File;
  _nameForm?: FormControl<string>;
  _editing?: boolean;
}

/**
 * ファイル選択ダイアログを開くボタン
 * @example
 * <cm-file-selector (fileSelect)="processFile($event)">ファイル選択</cm-file-selector>
 */
@Component({
  selector: 'cm-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
})
export class FileSelectorComponent implements OnChanges {

  /**
   * セレクターのタイプ
   * imageの場合は画像のプレビューを表示する
   */
  @Input() type: 'file' | 'image' = 'file';

  /**
   * 複数ファイルの指定可否
   */
  @Input() multiple = false;

  /**
   * file inputのaccept属性
   */
  @Input() accept: string;

  /**
   * 初期状態で選択済みとして表示するファイル
   * 既にアップロード済みのファイルなどを設定する
   */
  @Input() currentFile: FileInfo | FileInfo[];

  /**
   * 画像の大きさ制限
   * 一辺がこの数値の正方形に収まるように縦横比を維持してリサイズされる
   */
  @Input() imageMaxSize: number;

  /**
   * ファイル変更後に発火するイベント
   */
  @Output() fileChange = new EventEmitter<FileInfoWithData | FileInfoWithData[]>();

  previewFiles: FileInfoWithData[] = [];
  previewImageStyle: SafeStyle;
  currentFileRemoved = false;

  get previewFileAvailable() {
    return this.previewFiles.length || this.previewImageStyle;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private imageResizer: ImageResizerService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentFile) {
      const files: FileInfoWithData[] = [].concat(changes.currentFile.currentValue);

      for (const file of files) {
        if (!file || this.previewFiles.indexOf(file) !== -1) {
          continue;
        }

        file._nameForm = new FormControl(file.description);
        this.previewFiles.push(file);
      }
    }
  }

  async addFiles(files: FileList) {
    for (const file of files) {
      const fileInfo: FileInfoWithData = new FileInfo({ description: file.name });

      if (this.type === 'image' && this.imageMaxSize) {
        const resized = await this.imageResizer.resizeToContainInSquare(file, this.imageMaxSize);
        fileInfo._blob = new File([resized], file.name);
      } else {
        fileInfo._blob = file;
      }

      if (this.type === 'image') {
        this.previewImageStyle = this.sanitizer.bypassSecurityTrustStyle(`url(${URL.createObjectURL(file)})`);
      }

      fileInfo._nameForm = new FormControl(file.name);
      this.previewFiles.push(fileInfo);
    }

    this.fileChange.emit(this.multiple ? this.previewFiles : this.previewFiles[0]);
  }

  removeFile(index: number) {
    this.previewFiles.splice(index, 1);

    if (this.previewImageStyle) {
      this.previewImageStyle = undefined;
    }

    this.fileChange.emit(this.multiple ? this.previewFiles : null);
  }

  cancelFileNameEdit(file: FileInfoWithData) {
    file._nameForm.reset(file.description);
    file._editing = false;
  }

  applyFileNameEdit(index: number) {
    const file = this.previewFiles[index];
    file.description = file._nameForm.value;
    file._editing = false;
    this.fileChange.emit(this.multiple ? this.previewFiles : this.previewFiles[0]);
  }

  sortFiles(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.previewFiles, event.previousIndex, event.currentIndex);
    this.fileChange.emit(this.previewFiles);
  }

  /**
   * ファイル選択されたらfileChangeイベントをemitする
   * @param e inputのchangeイベント
   */
  async onFileSelect(e: Event) {
    const input = e.target as HTMLInputElement;

    await this.addFiles(input.files);
  }

  @HostListener('dragover', ['$event'])
  onDragOver(e: DragEvent) {
    e.preventDefault();
  }

  @HostListener('drop', ['$event'])
  async onDrop(e: DragEvent) {
    e.preventDefault();

    // 複数ファイル不可でファイル設定済みなら何もしない
    if (!this.multiple && this.previewFileAvailable) {
      return;
    }

    await this.addFiles(e.dataTransfer.files);
  }

}
