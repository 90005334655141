import { BaseStorage } from 'src/loopback';

/**
 * LoopbackのStorageのSessionStorageによる実装
 */
export class SessionStorage extends BaseStorage {
  /**
   * @param key Storage key name
   **/
  get(key: string): any {
    const data = sessionStorage.getItem(key);
    return this.parse(data);
  }

  /**
   * @param key Storage key name
   * @param value Any value
   **/
  set(key: string, value: any): void {
    sessionStorage.setItem(
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    );
  }

  /**
   * @param key Storage key name
   **/
  remove(key: string): void {
    if (sessionStorage[key]) {
      sessionStorage.removeItem(key);
    } else {
      console.log('Trying to remove unexisting key: ', key);
    }
  }

  /**
   * @param value Input data expected to be JSON
   **/
  private parse(value: any) {
    try {
        return JSON.parse(value);
    } catch (e) {
        return value;
    }
  }
}
