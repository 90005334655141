/* eslint-disable */
import {
  Staff,
  ReportConfirmation,
  FileInfo,
  Company
} from '../index';

declare var Object: any;
export interface AnnouncementInterface {
  "title"?: string;
  "body"?: string;
  "id"?: number;
  "staffId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "updaterId"?: number;
  staff?: Staff;
  confirmations?: ReportConfirmation[];
  attachment?: FileInfo;
  company?: Company;
}

export class Announcement implements AnnouncementInterface {
  "title": string = '';
  "body": string = '';
  "id": number = <any>null;
  "staffId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "updaterId": number = <any>null;
  staff?: Staff;
  confirmations?: ReportConfirmation[];
  attachment?: FileInfo;
  company?: Company;
  constructor(data?: Partial<AnnouncementInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Announcement`.
   */
  public static getModelName() {
    return "Announcement";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Announcement for dynamic purposes.
  **/
  public static factory(data: AnnouncementInterface): Announcement{
    return new Announcement(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Announcement',
      plural: 'Announcements',
      path: 'Announcements',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "body": {
          name: 'body',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        confirmations: {
          name: 'confirmations',
          type: 'ReportConfirmation[]',
          model: 'ReportConfirmation',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'referenceId'
        },
        attachment: {
          name: 'attachment',
          type: 'FileInfo',
          model: 'FileInfo',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'imageableId'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
      }
    }
  }
}
