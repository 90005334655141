import { Component, Inject, Injector, INJECTOR, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { TypedCareDetailPageTrait } from 'src/app/classes/care-detail-page-trait';
import { TypedDetailPageTrait } from 'src/app/classes/detail-page-trait';
import { PageBase } from 'src/app/classes/page-base';
import { ScrollContentComponent } from 'src/app/shared/components/scroll-content/scroll-content.component';
import { Mixin } from 'src/app/utils/mixin';
import { HospitalRecord, HospitalRecordApi } from 'src/loopback';

@Component({
  selector: 'cm-care-detail-hospital',
  templateUrl: './care-detail-hospital.component.html',
  styleUrls: ['./care-detail-hospital.component.scss'],
})
export class CareDetailHospitalComponent extends Mixin(
  PageBase,
  TypedDetailPageTrait(HospitalRecord),
  TypedCareDetailPageTrait(HospitalRecord),
) implements OnInit {

  isDialog = true;
  preventRedirectAfterCreate = true;
  titleBase = '入院記録';
  model = HospitalRecord;
  places: string[] = [];
  categories: string[] = [];
  canGoBack = true;

  @ViewChild(ScrollContentComponent) content: ScrollContentComponent;

  constructor(
    private recordApi: HospitalRecordApi,
    @Inject(INJECTOR) injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.init().subscribe(
      result => {
        this.setPropertyFromRecord(result);
        this.setTitle(this.generateTitle(result));
      },
    );
  }

  fetch(id: number) {
    return this.recordApi.findById(id, {
      include: this.recordInclusionDefaults,
    });
  }

  save(data: HospitalRecord): Observable<HospitalRecord> {
    if (this.id) {
      return this.recordApi.patchAttributes(this.id, data);
    } else {
      data.residentId = this.resident.id;
      data.officeId = this.resident.officeId;

      return this.recordApi.create(data);
    }
  }

}
