<div mat-dialog-title class="flex flex-row justify-between items-center">
  <span>項目の編集</span>
  <button mat-button *ngIf="!isNew" color="warn" (click)="onDeleteClick()">
    <mat-icon>clear</mat-icon> 削除
  </button>
</div>
<div mat-dialog-content [formGroup]="form">
  <mat-form-field *ngIf="!isAnyTime" class="w-full">
    <mat-label>開始時間</mat-label>
    <mat-select formControlName="start">
      <mat-option *ngFor="let time of times" [value]="time.value">{{ time.label }}</mat-option>
    </mat-select>
    <mat-hint *ngIf="form.controls['start'].errors as errors">
      <ng-container *ngIf="errors['startAfterEnd']">開始時間は終了時間より前にしてください</ng-container>
      <ng-container *ngIf="errors['intersected']">他の項目と時間が重複しています</ng-container>
    </mat-hint>
  </mat-form-field>
  <mat-form-field *ngIf="!isAnyTime" class="w-full">
    <mat-label>終了時間</mat-label>
    <mat-select formControlName="end">
      <mat-option *ngFor="let time of times" [value]="time.value">{{ time.label }}</mat-option>
    </mat-select>
    <mat-hint *ngIf="form.controls['end'].errors as errors">
      <ng-container *ngIf="errors['endBeforeStart']">終了時間は開始時間より後にしてください</ng-container>
      <ng-container *ngIf="errors['intersected']">他の項目と時間が重複しています</ng-container>
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>内容</mat-label>
    <textarea matInput formControlName="content" rows="5"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>キャンセル</button>
  <button mat-stroked-button color="primary" [disabled]="form.invalid"
    (click)="onOkClick()">OK</button>
</div>
