/* eslint-disable */
import {
  Resident,
  Staff,
  Company,
  Office,
  Note
} from '../index';

declare var Object: any;
export interface ScheduleEventInterface {
  "type"?: number;
  "startAt"?: Date|string;
  "endAt"?: Date|string;
  "withTime"?: boolean;
  "color"?: string;
  "title"?: string;
  "memo"?: string;
  "id"?: number;
  "residentId"?: number;
  "staffId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "noteContent"?: string;
  "updaterId"?: number;
  resident?: Resident;
  staff?: Staff;
  company?: Company;
  office?: Office;
  note?: Note;
}

export class ScheduleEvent implements ScheduleEventInterface {
  "type": number = <any>null;
  "startAt": Date|string = <any>null;
  "endAt": Date|string = <any>null;
  "withTime": boolean = false;
  "color": string = '';
  "title": string = '';
  "memo": string = '';
  "id": number = <any>null;
  "residentId": number = <any>null;
  "staffId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "noteContent": string = '';
  "updaterId": number = <any>null;
  resident?: Resident;
  staff?: Staff;
  company?: Company;
  office?: Office;
  note?: Note;
  constructor(data?: Partial<ScheduleEventInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ScheduleEvent`.
   */
  public static getModelName() {
    return "ScheduleEvent";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ScheduleEvent for dynamic purposes.
  **/
  public static factory(data: ScheduleEventInterface): ScheduleEvent{
    return new ScheduleEvent(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ScheduleEvent',
      plural: 'ScheduleEvents',
      path: 'ScheduleEvents',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'number'
        },
        "startAt": {
          name: 'startAt',
          type: 'Date|string'
        },
        "endAt": {
          name: 'endAt',
          type: 'Date|string'
        },
        "withTime": {
          name: 'withTime',
          type: 'boolean'
        },
        "color": {
          name: 'color',
          type: 'string'
        },
        "title": {
          name: 'title',
          type: 'string'
        },
        "memo": {
          name: 'memo',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "noteContent": {
          name: 'noteContent',
          type: 'string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        note: {
          name: 'note',
          type: 'Note',
          model: 'Note',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'relatedRecordId'
        },
      }
    }
  }
}
