import { Component, Inject, Injector, INJECTOR, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Params } from '@angular/router';
import * as moment from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { TypedCareDetailPageTrait } from 'src/app/classes/care-detail-page-trait';
import { TypedDetailPageTrait } from 'src/app/classes/detail-page-trait';
import { PageBase } from 'src/app/classes/page-base';
import { DialogWidth } from 'src/app/services/dialog.service';
import { ScrollContentComponent } from 'src/app/shared/components/scroll-content/scroll-content.component';
import { Mixin } from 'src/app/utils/mixin';
import { ScheduleEvent, ScheduleEventApi } from 'src/loopback';
import { DateSelectorCalendarHeaderComponent } from '../../components/date-selector-calendar-header/date-selector-calendar-header.component';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';

@Component({
  selector: 'cm-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent extends Mixin(
  PageBase,
  TypedDetailPageTrait(ScheduleEvent),
  TypedCareDetailPageTrait(ScheduleEvent),
) implements OnInit {

  isDialog = true;
  preventRedirectAfterCreate = true;
  titleBase = 'スケジュール';
  model = ScheduleEvent;
  places: string[] = [];
  categories: string[] = [];
  canGoBack = true;
  withEnd = new FormControl(false);
  calendarHeader = DateSelectorCalendarHeaderComponent;

  validators = {
    title: [Validators.required],
  };

  validationMessages = {
    title: {
      required: '予定を入力してください。',
    },
  };

  @ViewChild(ScrollContentComponent) content: ScrollContentComponent;

  constructor(
    private eventApi: ScheduleEventApi,
    @Inject(INJECTOR) injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.init().subscribe(
      result => {
        this.setPropertyFromRecord(result);
        this.setTitle(this.generateTitle(result));
      },
    );
  }

  beforeFetch(params: Params): Observable<any> {
    this.form.addControl('recordedAt', this.formBuilder.control(null));
    let datetime = this.data.datetime || moment();
    this.form.get('startAt').setValue(datetime.startOf('day').toDate());
    return super.beforeFetch(params);
  }

  fetch(id: number) {
    return this.eventApi.findById(id, {
      include: this.recordInclusionDefaults,
    }).pipe(
      tap(event => {
        this.withEnd.setValue(event.endAt != null);
      }),
    );
  }

  checkValidity(): boolean {
    super.checkValidity();

    if (this.withEnd.value) {
      if (moment(this.form.value.startAt).isAfter(this.form.value.endAt)) {
        this.errorMessages.push('終了日時が開始日時より前になっています');
      }
    }

    return this.errorMessages.length === 0;
  }

  save(data: ScheduleEvent): Observable<ScheduleEvent | ScheduleEvent[]> {
    if (!this.withEnd.value) {
      data.endAt = null;
    }

    if (!data.withTime) {
      data.startAt = moment(data.startAt).startOf('day').toDate();
      if (data.endAt) {
        data.endAt = moment(data.endAt).startOf('day').toDate();
      }
    }

    if (this.id) {
      return this.eventApi.patchAttributes(this.id, data);
    } else if (this.residents) {
      data.type = 1;
      const arr = this.residents.map(r => {
        return { ...data, residentId: r.id, officeId: r.officeId };
      });
      return this.eventApi.createMany(arr);
    } else if (this.resident) {
      data.type = 1;
      data.residentId = this.resident.id;
      data.officeId = this.resident.officeId;
      return this.eventApi.create(data);
    } else {
      data.type = 2;
      data.officeId = this.data.officeId;
      return this.eventApi.create(data);
    }
  }

  delete() {
    this.dialog.open(GenericDialogComponent, {
      data: {
        type: 'confirm',
        title: 'お知らせの削除',
        message: 'このお知らせを削除します',
        okButtonColor: 'warn',
      },
      size: DialogWidth.md,
    }).afterClosed().pipe(
      mergeMap(res => res ? of(null) : EMPTY),
      mergeMap(() => this.eventApi.deleteById(this.id)),
    ).subscribe(
      () => this.ref.close(new ScheduleEvent({ id: -1 })),
    );
  }

}
