/* eslint-disable */
import {
  PlanMonitoringDetail
} from '../index';

declare var Object: any;
export interface Plan2DetailInterface {
  "id"?: string;
  "task"?: string;
  "longTermGoal"?: string;
  "longTermGoalPeriod"?: string;
  "shortTermGoal"?: string;
  "shortTermGoalPeriod"?: string;
  "service"?: string;
  "personnel"?: string;
  "frequency"?: string;
  "period"?: string;
  monitoring?: PlanMonitoringDetail;
}

export class Plan2Detail implements Plan2DetailInterface {
  "id": string = '';
  "task": string = '';
  "longTermGoal": string = '';
  "longTermGoalPeriod": string = '';
  "shortTermGoal": string = '';
  "shortTermGoalPeriod": string = '';
  "service": string = '';
  "personnel": string = '';
  "frequency": string = '';
  "period": string = '';
  monitoring?: PlanMonitoringDetail;
  constructor(data?: Partial<Plan2DetailInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Plan2Detail`.
   */
  public static getModelName() {
    return "Plan2Detail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Plan2Detail for dynamic purposes.
  **/
  public static factory(data: Plan2DetailInterface): Plan2Detail{
    return new Plan2Detail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Plan2Detail',
      plural: 'Plan2Details',
      path: 'Plan2Details',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "task": {
          name: 'task',
          type: 'string'
        },
        "longTermGoal": {
          name: 'longTermGoal',
          type: 'string'
        },
        "longTermGoalPeriod": {
          name: 'longTermGoalPeriod',
          type: 'string'
        },
        "shortTermGoal": {
          name: 'shortTermGoal',
          type: 'string'
        },
        "shortTermGoalPeriod": {
          name: 'shortTermGoalPeriod',
          type: 'string'
        },
        "service": {
          name: 'service',
          type: 'string'
        },
        "personnel": {
          name: 'personnel',
          type: 'string'
        },
        "frequency": {
          name: 'frequency',
          type: 'string'
        },
        "period": {
          name: 'period',
          type: 'string'
        },
      },
      relations: {
        monitoring: {
          name: 'monitoring',
          type: 'PlanMonitoringDetail',
          model: 'PlanMonitoringDetail',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'plan2DetailId'
        },
      }
    }
  }
}
