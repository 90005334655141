import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Resident } from 'src/loopback';
import { TimelineItem } from 'src/app/classes/timeline';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { ScreenSize } from 'src/app/enums/screen-size.enum';
import { DialogComponentBase } from 'src/app/services/dialog.service';

@Component({
  selector: 'cm-timeline-dialog',
  templateUrl: './timeline-dialog.component.html',
  styleUrls: ['./timeline-dialog.component.scss'],
})
export class TimelineDialogComponent extends DialogComponentBase<TimelineDialogData, void> implements OnInit {

  type: 'careHistory' | 'note';
  resident: Resident;
  records: TimelineItem[];
  explicitMode = false;
  compact = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: TimelineDialogData,
    private state: GlobalStateService,
  ) {
    super();
  }

  ngOnInit() {
    this.type = this.data.type;
    this.resident = this.data.resident;
    this.records = this.data.records;

    if (this.records) {
      this.explicitMode = true;
    }

    this.state.onChanged('screenSize').subscribe(
      size => {
        this.compact = size.currentValue === ScreenSize.xsmall;
      },
    );
  }

}

export interface TimelineDialogData {
  type: 'careHistory' | 'note',
  resident: Resident,
  records?: TimelineItem[],
}
