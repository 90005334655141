import { InsuranceCard } from 'src/loopback';
import { InsuranceStatus } from '../enums/insurance-status.enum';
import * as moment from 'moment';

/**
 * 介護保険の状態の要約クラス
 */
export class InsuranceSummary {

  current: InsuranceCard;
  next: InsuranceCard;

  get status(): InsuranceStatus {
    if (!this.current && !this.next) {
      return InsuranceStatus.expired;
    } else if (!this.current && this.next && !this.next.isPending) {
      return InsuranceStatus.upcoming;
    } else if (this.next) {
      if (this.next.isPending) {
        return InsuranceStatus.pending;
      } else {
        return InsuranceStatus.default;
      }
    } else if (moment(this.current.expiredAt).diff(moment(), 'day') < 60) {
      return InsuranceStatus.needRenew;
    } else if (this.current.isPending) {
      return InsuranceStatus.pending;
    } else {
      return InsuranceStatus.default;
    }
  }

  get badgeLabel() {
    if (this.status === InsuranceStatus.needRenew) {
      return '!';
    } else if (this.status === InsuranceStatus.pending) {
      if (this.next && this.next.isModification || this.current && this.current.isModification) {
        return '区';
      } else {
        return '更';
      }
    }
  }

  get badgeColor() {
    if (this.status === InsuranceStatus.needRenew) {
      if (moment(this.current.expiredAt).diff(moment(), 'day') < 30) {
        return 'warn';
      } else {
        return 'accent';
      }
    } else {
      return 'primary';
    }
  }

  get tooltip() {
    switch (this.status) {
      case 'needRenew': {
        return '保険証の更新期間です';
      }
      case 'expired': {
        return '保険証の情報がありません';
      }
      case 'upcoming': {
        return moment(this.next.startAt).format('M月D日') + 'から有効な認定情報が設定されています';
      }
      case 'pending': {
        if (this.next && this.next.isModification || this.current && this.current.isModification) {
          return '区分変更申請中です';
        } else {
          return '保険証更新申請中です';
        }
      }
    }
  }

  get forEdit(): InsuranceCard {
    if (this.next && !this.next.isPending) {
      return this.next;
    } else if (this.current) {
      return this.current;
    }
  }

  static fromCards(cards: InsuranceCard[]) {
    const now = moment();
    const summary = new InsuranceSummary();

    for (const card of cards) {
      const expiredAt = card.realExpiredAt || card.expiredAt;

      if (card.isPending || now.isBefore(card.startAt, 'day')) {
        summary.next = card;
      } else if (now.isSameOrAfter(card.startAt, 'day') && now.isSameOrBefore(expiredAt, 'day')) {
        summary.current = card;
      }
    }

    return summary;
  }
}
