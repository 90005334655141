import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { iif, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OptionApi } from 'src/loopback';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {

  private itemDefinitions = {
    'care-progress-category': new AppSettingsItem({ label: '分類の選択肢', type: 'stringOptions' }),
    'care-progress-place': new AppSettingsItem({ label: '場所の選択肢', type: 'stringOptions' }),
    'care-progress-template': new AppSettingsItem({ label: 'テンプレート設定', type: 'templates' }),
    'care-vital-pulseStandard': new AppSettingsItem({
      label: '脈拍正常値の目安', type: 'numberRange', options: { unit: '回/分' },
    }),
    'care-vital-respirationStandard': new AppSettingsItem({
      label: '呼吸正常値の目安', type: 'numberRange', options: { unit: '回/分' },
    }),
    'care-vital-systolicBPStandard': new AppSettingsItem({
      label: '血圧（高）正常値の目安', type: 'numberRange', options: { unit: 'mmHg' },
    }),
    'care-vital-diastolicBPStandard': new AppSettingsItem({
      label: '血圧（低）正常値の目安', type: 'numberRange', options: { unit: 'mmHg' },
    }),
    'care-vital-temperatureStandard': new AppSettingsItem({
      label: '体温正常値の目安', type: 'numberRange', options: { unit: '℃' },
    }),
    'care-vital-oxygenStandard': new AppSettingsItem({
      label: '酸素飽和度正常値の目安', type: 'numberRange', options: { unit: '%' },
    }),
    'care-excretion-useFailed': new AppSettingsItem({
      label: '失禁項目を使用', type: 'boolean', options: {
        trueLabel: 'する', falseLabel: 'しない',
      },
    }),
    'care-excretion-useImplement': new AppSettingsItem({
      label: '排泄用具項目を使用', type: 'boolean', options: {
        trueLabel: 'する', falseLabel: 'しない',
      },
    }),
    'care-excretion-implement': new AppSettingsItem({ label: '排泄用具の選択肢', type: 'stringOptions' }),
    'care-excretion-template': new AppSettingsItem({ label: 'テンプレート設定', type: 'templates' }),
    'care-bathing-useType': new AppSettingsItem({
      label: '入浴方法項目を使用', type: 'boolean', options: {
        trueLabel: 'する', falseLabel: 'しない',
      },
    }),
    'care-bathing-type': new AppSettingsItem({ label: '入浴方法の選択肢', type: 'stringOptions' }),
    'plan-assessment-adl': new AppSettingsItem({ label: 'ADL項目', type: 'stringOptions' }),
    'plan-assessment-iadl': new AppSettingsItem({ label: '家事・整容(IADL)項目', type: 'stringOptions' }),
    'plan-plan1-subjects': new AppSettingsItem({
      label: '計画書1の項目', type: 'checkboxes', options: [
        { name: 'intention', label: '利用者及び家族の生活に対する意向' },
        { name: 'advice', label: '介護認定審査会の意見及びサービスの種類' },
        { name: 'policy', label: '総合的な援助の方針' },
      ],
    }),
    'plan-plan2-useShortTermGoal': new AppSettingsItem({
      label: '長期・短期別目標を設定', type: 'boolean', options: {
        trueLabel: 'する', falseLabel: 'しない',
      },
    }),
    'plan-plan2-useGoalPeriod': new AppSettingsItem({
      label: '目標の期間を設定', type: 'boolean', options: {
        trueLabel: 'する', falseLabel: 'しない',
      },
    }),
    'plan-weekly-template': new AppSettingsItem({ label: '週間計画表テンプレート', type: 'scheduledPlan' }),
  } as const;

  officeId: number;

  private settings = new Map<number, AppSettingsDictionary>();
  private get allNames(): AppSettingsItemName[] {
    return Object.keys(this.itemDefinitions) as AppSettingsItemName[];
  };

  constructor(
    private optionApi: OptionApi,
  ) { }

  load(officeId: number): Observable<AppSettingsDictionary> {
    return this.optionApi.getByNames(this.allNames, officeId).pipe(
      tap(res => {
        this.officeId = officeId;
        this.reset(res, officeId);
      }),
    );
  }

  save(values: Partial<AppSettingsDictionary>, officeId: number) {
    return this.optionApi.upsertByNames(values, officeId).pipe(
      tap(res => Object.keys(res).forEach((name: AppSettingsItemName) => this.set(name, res[name], officeId))),
    );
  }

  get<N extends AppSettingsItemName>(name: N, officeId: number) {
    return this.getAll(officeId).pipe(
      map(settings => settings[name]),
    );
  }

  getAll(officeId: number) {
    return iif(
      () => !this.settings.has(officeId),
      this.load(officeId),
      of(null),
    ).pipe(
      map(() => this.settings.get(officeId)),
    );
  }

  set<N extends AppSettingsItemName>(name: N, value: AppSettingsDictionary[N], officeId: number) {
    if (this.settings.has(officeId)) {
      this.settings.get(officeId)[name] = value;
    }
  }

  reset(settings: AppSettingsDictionary, officeId: number) {
    this.settings.set(officeId, settings);
  }

  getDefinition<N extends AppSettingsItemName>(name: N) {
    const def = this.itemDefinitions[name];
    def.name = name;
    return def;
  }

}

export type AppSettingsItemDefinitions = AppSettingsService['itemDefinitions'];

export type AppSettingsItemType =
  'stringOptions' |
  'numberRange' |
  'boolean' |
  'checkboxes' |
  'templates' |
  'scheduledPlan';

export type AppSettingsItemName<T = AppSettingsItemType> = keyof {
  [name in keyof AppSettingsItemDefinitions as T extends AppSettingsItemDefinitions[name]['type'] ? name : never]: any
};

export type AppSettingsItemOptions<T extends AppSettingsItemType>
  = T extends 'boolean' ? { trueLabel: string; falseLabel: string }
  : T extends 'checkboxes' ? { name: string; label: string }[]
  : T extends 'numberRange' ? { unit: string }
  : never;

export type AppSettingsItemValue<T extends AppSettingsItemType>
  = T extends 'boolean' ? boolean
  : T extends 'stringOptions' ? string[]
  : T extends 'numberRange' ? { min: number; max: number }
  : T extends 'checkboxes' ? { [name: string]: boolean }
  : any[];

export type AppSettingsItemControl<T extends AppSettingsItemType>
  = T extends 'stringOptions' ? FormArray<FormControl<string>>
  : T extends 'numberRange' ? FormGroup<{ min: FormControl<number>; max: FormControl<number> }>
  : T extends 'checkboxes' ? FormGroup<{ [name: string]: FormControl<boolean> }>
  : T extends 'templates' ? FormArray<FormControl<any>>
  : FormControl<AppSettingsItemValue<T>>;

export interface AppSettingsItem<T extends AppSettingsItemType = AppSettingsItemType> {
  name?: AppSettingsItemName<T>;
  label: string;
  type: T;
  options?: AppSettingsItemOptions<T>;
  form?: AppSettingsItemControl<T>;
}

export class AppSettingsItem<T extends AppSettingsItemType = AppSettingsItemType> {
  constructor(def: AppSettingsItem<T>) {
    Object.assign(this, def);
  }
}

export interface AppSettingsGroup {
  label: string;
  items: AppSettingsItemName[];
  form?: FormGroup<{ [name in keyof AppSettingsItemDefinitions]?: AppSettingsItemControl<AppSettingsItemDefinitions[name]['type']> }>;
}

export type AppSettingsDictionary = {
  [name in keyof AppSettingsItemDefinitions]: AppSettingsItemValue<AppSettingsItemDefinitions[name]['type']>
};
