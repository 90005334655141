import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleService } from '../services/role.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectAuthorizedGuard implements CanActivate {

  constructor(
    private role: RoleService,
    private router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.role.currentUser) {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      return true;
    }
  }
}
