import { Component, Host, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar, MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'cm-month-selector-calendar-header',
  templateUrl: './month-selector-calendar-header.component.html',
  styleUrls: ['./month-selector-calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthSelectorCalendarHeaderComponent<D> {

  constructor(
    private calendar: MatCalendar<D>,
    private picker: MatDatepicker<D>,
    private dateAdapter: DateAdapter<D>,
    private cdRef: ChangeDetectorRef,
  ) {
    this.calendar.stateChanges.subscribe(() => this.cdRef.markForCheck());
  }

  selectThisMonth() {
    this.calendar.selected = this.calendar.activeDate = this.dateAdapter.today();
    this.calendar.monthSelected.emit(this.calendar.selected);
    this.picker.close();
  }

  addCalendarYears(amount: number) {
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, amount);
  }

}
