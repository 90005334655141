import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Resident, Room } from 'src/loopback';

@Component({
  selector: 'cm-resident-panel',
  templateUrl: './resident-panel.component.html',
  styleUrls: ['./resident-panel.component.scss'],
})
export class ResidentPanelComponent {

  @Input() resident: Resident = new Resident();
  @Input() room: Room;
  @Input() dismissible = false;
  @Input() tabOrder: ('careHistory' | 'planHistory' | 'note')[] = ['careHistory', 'planHistory', 'note'];
  @Output() dismiss = new EventEmitter<void>();

  tabSettings = {
    careHistory: {
      label: 'ケア',
      icon: 'assignment',
      timelineCollapsible: true,
      timelineHideTime: false,
    },
    planHistory: {
      label: '計画',
      icon: 'assessment',
      timelineCollapsible: true,
      timelineHideTime: true,
    },
    note: {
      label: 'ノート',
      icon: 'comment',
      timelineCollapsible: false,
      timelineHideTime: false,
    },
  };

}
