import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from 'src/app/services/dialog.service';

/**
 * 汎用ダイアログ
 * alert, confirm, promptに対応
 */
@Component({
  selector: 'cm-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericDialogComponent extends DialogComponentBase<GenericDialogData, GenericDialogResult> implements OnInit {
  /**
   * プロンプト用FormControl
   */
  promptText = new FormControl('');

  /**
   * dataのデフォルト値
   */
  defaults: Partial<GenericDialogData> = {
    title: '',
    message: [],
    selectOptions: [],
    promptValidators: null,
    promptInputType: 'text',
    cancelButtonText: 'キャンセル',
    okButtonText: 'OK',
    okButtonColor: 'primary',
  };

  get okDisabled() {
    return this.data.promptValidators && !this.promptText.valid;
  }

  constructor(
    protected ref: MatDialogRef<GenericDialogComponent, GenericDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: GenericDialogData,
  ) {
    super();
  }

  ngOnInit() {
    this.data = { ...this.defaults, ...this.data };

    if (!Array.isArray(this.data.message)) {
      this.data.message = [this.data.message];
    }

    if (this.data.promptValidators) {
      this.promptText.setValidators(this.data.promptValidators);
    }
  }

  /**
   * OKボタンクリックハンドラ
   * promptの場合は入力値、それ以外はtrueを渡す
   */
  onOkClick() {
    const value = this.data.type.startsWith('prompt') ? this.promptText.value : true;
    this.ref.close(value);
  }

}

export interface GenericDialogData {
  type: GenericDialogType;
  title: string;
  message?: string | string[];
  promptInputType?: string;
  promptValidators?: ValidatorFn[];
  selectOptions?: string[];
  cancelButtonText?: string;
  okButtonText?: string;
  okButtonColor?: ThemePalette;
}

export type GenericDialogType = 'alert' | 'confirm' | 'prompt' | 'promptMultiline' | 'promptSelect';
export type GenericDialogResult = boolean | string;
