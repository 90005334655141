<ng-container *ngIf="staff; else empty">
  <div class="staff-avatar">
    <div *ngIf="staff.photo" [style.background-image]="staff.photo | fileUrl:'css'"
      [style.width.px]="avatarSize" [style.height.px]="avatarSize"></div>
    <cm-identicon *ngIf="!staff.photo" [userName]="staff.username" [size]="avatarSize - 2"></cm-identicon>
  </div>
  <div *ngIf="!avatarOnly" class="staff-office-name">
    <div *ngIf="showOffice && staff.office" class="staff-office">
      {{ staff.office.abbr || staff.office.name }}
    </div>
    <div class="staff-name">
      <ruby class="last-name">{{ staff.lastName }}<rt *ngIf="size !== 'small'">{{ staff.lastNameKana }}</rt></ruby>
      <ruby class="first-name">{{ staff.firstName }}<rt *ngIf="size !== 'small'">{{ staff.firstNameKana }}</rt></ruby>
    </div>
  </div>
</ng-container>

<ng-template #empty>
  <div class="staff-avatar">
    <div [style.width.px]="avatarSize" [style.height.px]="avatarSize"></div>
  </div>
  <div *ngIf="!avatarOnly" class="staff-office-name">
    <div class="staff-name">削除された職員</div>
  </div>
</ng-template>
