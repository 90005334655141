import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { CareHistory, Note, PlanHistory, ScheduleEvent, Staff } from 'src/loopback';
import { recordModelNames } from '../constants';
import { CareRecordType, PlanRecordType, RecordModel, RecordType } from '../types';

/**
 * タイムライン項目表示用クラス
 */
export class TimelineItem<T extends RecordType = RecordType> {
  id: number;
  date: moment.Moment = null;
  firstOfDay = false;
  type: T = null;
  sticky?: boolean;
  record: RecordModel<T>;
  staff: Staff = null;
  replies?: TimelineItem<'note'>[];
  replyForm?: FormControl<string>;
  related?: TimelineItem<RecordType>;
  _relatedExpanded: boolean;
  _bodyExpanded: boolean;

  /**
   * ケア記録/計画履歴データから作成
   * @param history 履歴データ
   * @returns TimelineItem
   */
  static fromRecordHistory<T extends CareRecordType | PlanRecordType | 'report'>(history: CareHistory | PlanHistory): TimelineItem<T> {
    return new TimelineItem({
      id: history.id,
      date: moment(history.recordedAt),
      type: Object.entries(recordModelNames).find(([_, m]) => history.recordType === m)?.[0] as T,
      record: history.record,
      staff: history.staff,
    });
  }

  /**
   * ノートから作成
   * @param note
   * @param isReply ノートが返信かどうか
   * @returns
   */
  static fromNote(note: Note, isReply = false): TimelineItem<'note'> {
    return new TimelineItem({
      id: note.id,
      date: moment(note.createdAt),
      type: 'note',
      record: note,
      staff: note.staff,
      replies: isReply ? null : (note.replies || []).map(r => TimelineItem.fromNote(r, true)),
      replyForm: isReply ? null : new FormControl(''),
      related: note.relatedRecord ? new TimelineItem({
        date: moment(note.createdAt),
        type: Object.entries(recordModelNames).find(([_, m]) => note.relatedRecordType === m)?.[0] as RecordType,
        record: note.relatedRecord,
        staff: note.staff,
      }) : null,
    });
  }

  /**
   * スケジュールイベントから作成
   * @param scheduleEvent
   * @returns
   */
  static fromScheduleEvent(scheduleEvent: ScheduleEvent): TimelineItem<'schedule'> {
    return new TimelineItem({
      id: scheduleEvent.id,
      date: moment(scheduleEvent.startAt),
      type: 'schedule',
      record: scheduleEvent,
      staff: scheduleEvent.staff,
    });
  }

  constructor(data: Partial<TimelineItem<T>>) {
    Object.assign(this, data);
  }
}
