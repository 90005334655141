import { OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnInit, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { tableExpand } from 'src/app/animations';
import { TimelineItem } from 'src/app/classes/timeline';
import { careDetailDialogs, residentInclusionDefaults, typeBadges } from 'src/app/constants';
import { AppSettingsDictionary, AppSettingsService } from 'src/app/services/app-settings.service';
import { DialogService, DialogWidth } from 'src/app/services/dialog.service';
import { NoteUtilService } from 'src/app/services/note-util.service';
import { RoleService } from 'src/app/services/role.service';
import { CareRecordType, RecordModel, RecordType } from 'src/app/types';
import { isSameTime } from 'src/app/utils/date';
import { BaseLoopBackApi, BathingRecordApi, CareHistory, ExcretionRecordApi, HospitalRecordApi, MealRecordApi, Note, NoteApi, PlanHistory, ProgressRecordApi, ReportApi, ReportConfirmation, Resident, ResidentApi, Staff, StaffApi, TransportRecordApi, VitalRecordApi } from 'src/loopback';
import { ConfirmationListComponent } from '../../dialogs/confirmation-list/confirmation-list.component';
import { EventDetailComponent } from '../../dialogs/event-detail/event-detail.component';
import { GenericDialogComponent } from '../../dialogs/generic-dialog/generic-dialog.component';

type TimelineType = 'careHistory' | 'planHistory' | 'note' | 'schedule';

@Component({
  selector: 'cm-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  animations: [tableExpand],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent implements OnInit, OnChanges {

  typeBadges = typeBadges;
  typeToApi: { [type in (CareRecordType | 'report')]: BaseLoopBackApi<RecordModel<type>> } = {
    progress: this.progressRecordApi,
    meal: this.mealRecordApi,
    vital: this.vitalRecordApi,
    excretion: this.excretionRecordApi,
    bathing: this.bathingRecordApi,
    hospital: this.hospitalRecordApi,
    transport: this.transportRecordApi,
    report: this.reportApi,
  };

  @Input() items: TimelineItem[];
  @Input() type: TimelineType = 'careHistory';
  @Input() resident: Resident;
  @Input() officeId: number;
  @Input() collapsible = false;
  @Input() hideTime = false;
  @Input() explicitMode = false;
  @Input() showAddNoteButton = false;
  @Input() enableNoteSearch = false;
  @Input() filter: string;
  @HostBinding('class.compact') @Input() compact = false;

  dataSource = new MatTableDataSource<TimelineItem>();
  visibleItems: TimelineItem[] = [];
  newNoteForm = new FormControl('');
  settings: Partial<AppSettingsDictionary> = {};
  initialized = false;
  staffList$: Observable<Staff[]>;
  noteSearchForm: FormGroup<{
    staffId: FormControl<number>;
    period: FormControl<{ start: moment.Moment; end: moment.Moment }>
  }>;

  editorOverlay: OverlayRef;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  filterPredicate = (data: TimelineItem, filter: string) => {
    switch (filter) {
      case 'noteResident': {
        return !!data.record.residentId;
      }
      case 'noteNoResident': {
        return !data.record.residentId;
      }
    }
  };

  get isSuperAdmin() {
    return this.role.can('superAdmin');
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    @Optional() private ref: MatDialogRef<any>,
    private dialog: DialogService,
    private residentApi: ResidentApi,
    private staffApi: StaffApi,
    private noteApi: NoteApi,
    private appSettings: AppSettingsService,
    private progressRecordApi: ProgressRecordApi,
    private mealRecordApi: MealRecordApi,
    private vitalRecordApi: VitalRecordApi,
    private excretionRecordApi: ExcretionRecordApi,
    private bathingRecordApi: BathingRecordApi,
    private hospitalRecordApi: HospitalRecordApi,
    private transportRecordApi: TransportRecordApi,
    private reportApi: ReportApi,
    private role: RoleService,
    private noteUtil: NoteUtilService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.type === 'careHistory') {
      this.appSettings.getAll(this.resident.officeId).subscribe(
        settings => this.settings = settings,
      );
    }

    if (this.type === 'note') {
      this.staffList$ = this.staffApi.find({ where: { officeId: this.role.currentOffice?.id } });

      if (this.enableNoteSearch) {
        this.noteSearchForm = this.formBuilder.group({
          staffId: [null],
          period: [{ start: moment().subtract(6, 'days'), end: moment() }],
        });
        this.noteSearchForm.valueChanges.subscribe(
          () => this.resetItems(),
        );
      }
    }

    if (!this.items) {
      this.resetItems();
    }

    this.dataSource.filterPredicate = this.filterPredicate;
    this.dataSource.paginator = this.paginator;
    this.dataSource.connect().subscribe(
      res => {
        res.forEach((item, index, items) => {
          if (index === 0 || !isSameTime(items[index - 1].date.toDate(), item.date.toDate(), 'day')) {
            item.firstOfDay = true;
          } else {
            item.firstOfDay = false;
          }
        });
        this.visibleItems = res;
      },
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.explicitMode && this.initialized && (changes.resident || changes.officeId)) {
      this.resetItems();
    }
    if (changes.filter) {
      this.dataSource.filter = changes.filter.currentValue;
    }
    if (changes.items) {
      this.setDataSource(changes.items.currentValue);
    }
  }


  resetItems() {
    let fetch: Observable<any>;

    if (this.type === 'note') {
      const conds: any = [{ parentId: null }];
      const include: any[] = [
        'staff', 'relatedRecord',
        {
          relation: 'replies',
          scope: { include: ['staff', 'relatedRecord'] },
        },
        {
          relation: 'confirmations',
          scope: { where: { staffId: this.role.currentUser.id } },
        },
      ];

      if (this.noteSearchForm) {
        const value = this.noteSearchForm.value;
        if (value.staffId) {
          conds.push({ staffId: value.staffId });
        }
        if (value.period.start) {
          conds.push({ createdAt: { gte: value.period.start.startOf('day') } });
        }
        if (value.period.end) {
          conds.push({ createdAt: { lte: value.period.end.endOf('day') } });
        }
      }

      if (this.resident) {
        conds.push({ residentId: this.resident.id });
      } else {
        include.push({
          relation: 'resident',
          scope: { include: residentInclusionDefaults },
        });
      }

      if (this.officeId) {
        conds.push({ officeId: this.officeId });
      }

      fetch = this.noteApi.find({
        where: { and: conds }, include,
        order: 'createdAt DESC',
      });
    } else if (this.type === 'careHistory') {
      fetch = this.residentApi.getCareHistories(this.resident.id, {
        include: ['staff', 'record'],
        order: 'recordedAt DESC',
      });
    } else if (this.type === 'planHistory') {
      fetch = this.residentApi.getPlanHistories(this.resident.id, {
        include: ['staff', 'record'],
        order: 'recordedAt DESC',
      });
    }

    fetch.subscribe(
      res => {
        this.initialized = true;

        if (this.type === 'note') {
          this.items = (res as Note[]).map(note => TimelineItem.fromNote(note));
        } else {
          this.items = (res as CareHistory[] | PlanHistory[]).filter(history => !!history.record).map(history => TimelineItem.fromRecordHistory(history));
        }
        this.setDataSource(this.items);
      },
    );
  }

  setDataSource(items: TimelineItem[]) {
    if (!items) {
      return;
    }

    const sortedItems = [];

    for (const item of items) {
      if (item.sticky) {
        sortedItems.unshift(item);
      } else {
        sortedItems.push(item);
      }
    }

    this.dataSource.data = sortedItems;
  }

  edit(item: TimelineItem<CareRecordType | 'report' | 'note' | 'schedule'>) {
    if (this.isItemType(item, 'report')) {
      // ダイアログ内に表示されている場合はダイアログを閉じる
      if (this.ref) {
        this.ref.close();
      }
      this.router.navigate(['/report', item.record.id]);
    } else if (this.isItemType(item, 'note')) {
      this.openNoteEditor(item);
    } else if (this.isItemType(item, 'schedule')) {
      this.dialog.open(EventDetailComponent, {
        data: { id: item.record.id },
        disableClose: true,
        size: DialogWidth.lg,
      }).afterClosed().subscribe(
        result => {
          if (result && !Array.isArray(result)) {
            Object.assign(item.record, result);
            item.date = moment(result.startAt);
            this.cdRef.markForCheck();
          }
        },
      );
    } else {
      this.dialog.open(careDetailDialogs[item.type as CareRecordType], {
        data: { id: item.record.id },
        disableClose: true,
        size: DialogWidth.lg,
      }).afterClosed().subscribe(
        result => {
          if (result && !Array.isArray(result)) {
            Object.assign(item.record, result);
            item.date = moment(result.recordedAt);
            this.cdRef.markForCheck();
          }
        },
      );
    }
  }

  delete(item: TimelineItem) {
    this.dialog.open(GenericDialogComponent, {
      data: {
        type: 'confirm',
        title: '記録の削除',
        message: ['この記録を削除します'],
        okButtonColor: 'warn',
      },
      size: DialogWidth.md,
    }).afterClosed().pipe(
      mergeMap(result => result ? this.deleteRecord(item) : EMPTY),
    ).subscribe(
      _ => {
        const index = this.items.indexOf(item);
        this.items.splice(index, 1);
        this.setDataSource(this.items);
      },
    );
  }

  deleteNoteReply(parent: TimelineItem<'note'>, reply: TimelineItem<'note'>) {
    this.dialog.open(GenericDialogComponent, {
      data: {
        type: 'confirm',
        title: '返信の削除',
        message: ['この返信を削除します'],
        okButtonColor: 'warn',
      },
      size: DialogWidth.md,
    }).afterClosed().pipe(
      mergeMap(result => result ? this.deleteRecord(reply) : EMPTY),
    ).subscribe(
      _ => {
        const index = parent.replies.indexOf(parent);
        parent.replies.splice(index, 1);
        this.cdRef.markForCheck();
      },
    );
  }

  deleteRecord(item: TimelineItem) {
    if (this.type === 'careHistory') {
      const api = this.typeToApi[item.type as (CareRecordType | 'report')];
      return api.deleteById(item.record.id);
    } else if (this.type === 'note') {
      return this.noteApi.deleteById(item.record.id);
    }
  }

  openNoteEditor(item?: TimelineItem<'note'>) {
    if (item) {
      this.noteUtil.openUpdateEditor(item.record).subscribe(
        res => {
          item.record = res;
          this.cdRef.markForCheck();
        },
      );
    } else {
      this.noteUtil.openEditor(this.resident).pipe(
        // mergeMap(note => this.createConfirmation(note).pipe(mapTo(note))),
      ).subscribe(
        res => {
          this.items.push(TimelineItem.fromNote(res));
        },
      );
    }
  }

  openNoteReplyEditor(parent: TimelineItem<'note'>) {
    this.noteUtil.openReplyEditor(parent.record).subscribe(
      res => {
        parent.replies.push(TimelineItem.fromNote(res, true));
        this.cdRef.markForCheck();
      },
    );
  }

  confirm(item: TimelineItem) {
    if (!this.isItemType(item, 'note') || this.confirmed(item)) {
      return;
    }

    this.createConfirmation(item.record).subscribe(
      _ => this.cdRef.markForCheck(),
    );
  }

  confirmed(item: TimelineItem<'note'>) {
    if (!this.role.currentUser || !item.record.confirmations) {
      return false;
    }

    return item.record.confirmations.some((c: ReportConfirmation) => c.staffId === this.role.currentUser.id);
  }

  createConfirmation(note: Note) {
    return this.noteApi.createConfirmations(note.id, {
      confirmedAt: new Date(),
      staffId: this.role.currentUser.id,
    }).pipe(
      tap(c => {
        c.staff = this.role.currentUser;

        if (note.confirmations) {
          note.confirmations.push(c);
        } else {
          note.confirmations = [c];
        }
      }),
    );
  }

  openConfirmationList(item: TimelineItem) {
    this.dialog.open(ConfirmationListComponent, {
      data: { record: item.record },
      size: DialogWidth.md,
    });
  }

  canEdit(item: TimelineItem): item is TimelineItem<CareRecordType | 'report' | 'note'> {
    if (!this.role.currentUser) {
      return false;
    }

    const isMine = item.record.staffId === this.role.currentUser.id;

    if (this.type === 'careHistory') {
      return isMine || this.role.can('editCareRecord');
    } else if (this.type === 'note') {
      return isMine;
    } else {
      return false;
    }
  }

  canDelete(item: TimelineItem) {
    if (!this.role.currentUser) {
      return false;
    }

    const isMine = item.record.staffId === this.role.currentUser.id;

    if (this.type === 'careHistory') {
      return this.role.can('editCareRecord');
    } else if (this.type === 'note') {
      return isMine;
    } else {
      return false;
    }
  }

  isItemType<T extends RecordType>(item: TimelineItem, type: T): item is TimelineItem<T> {
    return item.type === type;
  }

}
