<div mat-dialog-title>{{ title }}</div>
<div mat-dialog-content [formGroup]="form">
  <cm-message-box message="入力エラーがあります。" [listItems]="errorMessages"></cm-message-box>
  <mat-card appearance="outlined" class="meta-card mb-8">
    <mat-card-content>
      <table class="meta-table w-full">
        <tbody>
          <tr>
            <th>入居者</th>
            <td>
              <cm-resident-name-card *ngIf="resident" [resident]="resident"
                [room]="resident.moveHistories[0]?.room" size="small"></cm-resident-name-card>
              <span *ngIf="residents" [matTooltip]="residentNames">{{ residents.length }}人の入居者</span>
            </td>
          </tr>
          <tr>
            <th>記録日時</th>
            <td>
              <cm-date-time-picker formControlName="recordedAt"></cm-date-time-picker>
            </td>
          </tr>
          <tr>
            <th>記録者</th>
            <td>
              <mat-select *ngIf="staffList$ | async as staffList" formControlName="staffId" class="transparent-input">
                <mat-option *ngFor="let staff of staffList" [value]="staff.id">
                  {{ staff.lastName }} {{ staff.firstName }}
                </mat-option>
              </mat-select>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
  </mat-card>
  <h2 class="section-header mat-h2">種別</h2>
  <div class="section-content">
    <mat-button-toggle-group formControlName="category">
      <mat-button-toggle *ngFor="let i of ['朝食', '昼食', '夕食', '間食', '水分補給']" [value]="i">
        {{ i }}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <h2 class="section-header mat-h2">食事</h2>
  <div class="section-content">
    <div class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[100px]">
        <mat-label>主食</mat-label>
        <input type="number" pattern="\d*" matInput formControlName="staple" [value]="form.value.staple"
          [min]="0" [max]="100">
        <span matTextSuffix>%</span>
      </mat-form-field>
      <mat-slider class="flex-1" discrete [min]="0" [max]="100"
        [step]="10"><input matSliderThumb formControlName="staple" [value]="form.value.staple" /></mat-slider>
    </div>
    <div class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[100px]">
        <mat-label>副食</mat-label>
        <input type="number" pattern="\d*" matInput formControlName="sidedish" [value]="form.value.sidedish"
          [min]="0" [max]="100">
        <span matTextSuffix>%</span>
      </mat-form-field>
      <mat-slider class="flex-1" discrete [min]="0" [max]="100"
        [step]="10"><input matSliderThumb formControlName="sidedish" [value]="form.value.sidedish" /></mat-slider>
    </div>
  </div>
  <h2 class="section-header mat-h2">水分</h2>
  <div class="section-content">
    <div class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[100px]">
        <mat-label>汁物</mat-label>
        <input type="number" pattern="\d*" matInput formControlName="soup" [value]="form.value.soup"
          [min]="0">
        <span matTextSuffix>cc</span>
      </mat-form-field>
      <mat-slider class="flex-1" discrete [min]="0" [max]="250"
        [step]="10"><input matSliderThumb formControlName="soup" [value]="form.value.soup" /></mat-slider>
    </div>
    <div class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[100px]">
        <mat-label>お茶類</mat-label>
        <input type="number" pattern="\d*" matInput formControlName="drink" [value]="form.value.drink"
          [min]="0">
        <span matTextSuffix>cc</span>
      </mat-form-field>
      <mat-slider class="flex-1" discrete [min]="0" [max]="500"
        [step]="10"><input matSliderThumb formControlName="drink"[value]="form.value.drink" /></mat-slider>
    </div>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <div class="section-content">
    <mat-form-field class="w-full">
      <mat-label>メモ</mat-label>
      <textarea matInput rows="5" formControlName="memo"></textarea>
    </mat-form-field>
  </div>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="flex flex-row gap-4">
        <div class="rounded-icon">
          <mat-icon>comment</mat-icon>
        </div>
        <div class="flex-1">
          <mat-form-field class="w-full">
            <mat-label>この記録に関するノート<ng-container *ngIf="!id || !note">を追加</ng-container>
            </mat-label>
            <textarea matInput #noteContent="matInput" rows="5" formControlName="noteContent"></textarea>
            <mat-hint *ngIf="!id || !note">記録の保存と同時にノートに投稿されます</mat-hint>
          </mat-form-field>
          <ng-container *ngIf="note">
            <mat-divider></mat-divider>
            <cm-timeline-note-replies *ngIf="noteReplies && noteReplies.length" [items]="noteReplies"
              class="mb-4"></cm-timeline-note-replies>
            <div class="text-right">
              <button mat-button (click)="openNoteReplyEditor()">
                <mat-icon>reply</mat-icon> 返信
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>キャンセル</button>
  <button mat-button mat-stroked-button (click)="noteContent.focus()">
    <mat-icon>comment</mat-icon> ノートを編集
  </button>
  <button mat-button mat-flat-button type="submit" color="primary" (click)="onSubmit($event)"
    [disabled]="!isDirty || isSaving">
    <mat-icon>save</mat-icon> 保存
  </button>
</div>
