import { Pipe, PipeTransform } from '@angular/core';
import { TimelineItem } from 'src/app/classes/timeline';
import { RecordType } from 'src/app/types';

@Pipe({
  name: 'isTimelineItemType',
})
export class IsTimelineItemTypePipe implements PipeTransform {

  transform<T extends RecordType>(item: TimelineItem, type: T): item is TimelineItem<T> {
    return item.type === type;
  }

}
