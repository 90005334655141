<a (click)="onItemClick($event)" class="item-link">
  <mat-icon *ngIf="menuItem.icon" class="item-icon" [style.color]="menuItem.iconColor"
    [matTooltip]="compact ? menuItem.title : ''" matBadge="!" matBadgeSize="small" matBadgePosition="before" [matBadgeHidden]="!showBadge">{{ menuItem.icon }}</mat-icon>
  <span *ngIf="!compact" class="item-title">{{ menuItem.title }}</span>
  <mat-icon *ngIf="!compact && menuItem.children" class="expand-arrow">arrow_drop_down</mat-icon>
</a>

<div *ngIf="!compact && menuItem.children" class="sublist">
  <cm-menu-item
    *ngFor="let subItem of menuItem.children"
    [menuItem]="subItem"
    [child]="true"
    (itemClick)="onChildItemClick($event)"
  ></cm-menu-item>
</div>
