import { Injectable } from '@angular/core';
import { RoleService } from './role.service';

/**
 * ログインユーザー別設定サービス
 */
@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {

  constructor(
    private role: RoleService,
  ) {}

  /**
   * 設定値を取得
   * @param name 設定名
   * @returns
   */
  get<T>(name: string): T {
    if (!this.role.currentUser) {
      return null;
    }
    return localStorage.getItem(`cm.${this.role.currentUser.id}.${name}`) as any;
  }

  /**
   * 設定値を保存
   * @param name 設定名
   * @param value 値
   * @returns
   */
  set(name: string, value: string) {
    if (!this.role.currentUser) {
      return;
    }
    localStorage.setItem(`cm.${this.role.currentUser.id}.${name}`, value);
  }
}
