import { Pipe, PipeTransform } from '@angular/core';
import { isSameTime } from 'src/app/utils/date';

@Pipe({
  name: 'isSameTime',
})
export class IsSameTimePipe implements PipeTransform {

  transform(time1: string|Date, time2: string|Date, unit: 'month'|'day'|'hour'): boolean {
    return isSameTime(new Date(time1), new Date(time2), unit);
  }

}
