/* eslint-disable */
import {
  Resident,
  Staff,
  Company,
  Office,
  CareHistory,
  Note
} from '../index';

declare var Object: any;
export interface MealRecordInterface {
  "category"?: string;
  "staple"?: number;
  "sidedish"?: number;
  "soup"?: number;
  "drink"?: number;
  "memo"?: string;
  "id"?: number;
  "residentId"?: number;
  "staffId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "recordedAt"?: Date|string;
  "noteContent"?: string;
  "updaterId"?: number;
  resident?: Resident;
  staff?: Staff;
  company?: Company;
  office?: Office;
  history?: CareHistory;
  note?: Note;
}

export class MealRecord implements MealRecordInterface {
  "category": string = '';
  "staple": number = <any>null;
  "sidedish": number = <any>null;
  "soup": number = <any>null;
  "drink": number = <any>null;
  "memo": string = '';
  "id": number = <any>null;
  "residentId": number = <any>null;
  "staffId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "recordedAt": Date|string = <any>null;
  "noteContent": string = '';
  "updaterId": number = <any>null;
  resident?: Resident;
  staff?: Staff;
  company?: Company;
  office?: Office;
  history?: CareHistory;
  note?: Note;
  constructor(data?: Partial<MealRecordInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MealRecord`.
   */
  public static getModelName() {
    return "MealRecord";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MealRecord for dynamic purposes.
  **/
  public static factory(data: MealRecordInterface): MealRecord{
    return new MealRecord(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MealRecord',
      plural: 'MealRecords',
      path: 'MealRecords',
      idName: 'id',
      properties: {
        "category": {
          name: 'category',
          type: 'string'
        },
        "staple": {
          name: 'staple',
          type: 'number'
        },
        "sidedish": {
          name: 'sidedish',
          type: 'number'
        },
        "soup": {
          name: 'soup',
          type: 'number'
        },
        "drink": {
          name: 'drink',
          type: 'number'
        },
        "memo": {
          name: 'memo',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "recordedAt": {
          name: 'recordedAt',
          type: 'Date|string',
          default: <any>null
        },
        "noteContent": {
          name: 'noteContent',
          type: 'string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        history: {
          name: 'history',
          type: 'CareHistory',
          model: 'CareHistory',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'recordId'
        },
        note: {
          name: 'note',
          type: 'Note',
          model: 'Note',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'relatedRecordId'
        },
      }
    }
  }
}
