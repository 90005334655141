import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PlatformModule } from '@angular/cdk/platform';
import { LayoutModule } from '@angular/cdk/layout';

import { ScrollContentComponent } from './components/scroll-content/scroll-content.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { BadgeComponent } from './components/badge/badge.component';
import { DateIntervalInputComponent } from './components/date-interval-input/date-interval-input.component';
import { MonthIntervalInputComponent } from './components/month-interval-input/month-interval-input.component';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { IdenticonComponent } from './components/identicon/identicon.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { WarekiDateInputComponent } from './components/wareki-date-input/wareki-date-input.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { ResidentPanelComponent } from './components/resident-panel/resident-panel.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { CustomSliderComponent } from './components/custom-slider/custom-slider.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { MonthSelectorComponent } from './components/month-selector/month-selector.component';
import { DateSelectorCalendarHeaderComponent } from './components/date-selector-calendar-header/date-selector-calendar-header.component';
import { MonthSelectorCalendarHeaderComponent } from './components/month-selector-calendar-header/month-selector-calendar-header.component';
import { ResidentNameCardComponent } from './components/resident-name-card/resident-name-card.component';
import { ResidentPanelMultiComponent } from './components/resident-panel-multi/resident-panel-multi.component';
import { TimelineItemContentComponent } from './components/timeline-item-content/timeline-item-content.component';
import { TimelineNoteRepliesComponent } from './components/timeline-note-replies/timeline-note-replies.component';

import { GenericDialogComponent } from './dialogs/generic-dialog/generic-dialog.component';
import { TimelineDialogComponent } from './dialogs/timeline-dialog/timeline-dialog.component';
import { ResidentInfoComponent } from './dialogs/resident-info/resident-info.component';
import { ResidentSelectComponent } from './dialogs/resident-select/resident-select.component';
import { StaffSelectorComponent } from './dialogs/staff-selector/staff-selector.component';
import { FullScreenTextareaComponent } from './dialogs/full-screen-textarea/full-screen-textarea.component';
import { EpisodeEditorComponent } from './dialogs/episode-editor/episode-editor.component';
import { InsuranceCardEditorComponent } from './dialogs/insurance-card-editor/insurance-card-editor.component';

import { AutoAddressDirective, AutoAddressButtonDirective } from './directives/auto-address.directive';

import { FileUrlPipe } from './pipes/file-url.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { AbsenceBadgePipe } from './pipes/absence-badge.pipe';
import { ExcretionDegreeLabelPipe } from './pipes/excretion-degree-label.pipe';

import { FileInfoApi } from '../../loopback';
import { CM_DATE_SELECTOR_FORMATS } from '../constants';
import { WarekiDatePipe } from './pipes/wareki-date.pipe';
import { AgePipe } from './pipes/age.pipe';
import { OptionLabelPipe } from './pipes/option-label.pipe';
import { FileInfoApiOverrideService } from '../services/file-info-api-override.service';
import { CareDetailProgressComponent } from './dialogs/care-detail-progress/care-detail-progress.component';
import { CareDetailVitalComponent } from './dialogs/care-detail-vital/care-detail-vital.component';
import { CareDetailMealComponent } from './dialogs/care-detail-meal/care-detail-meal.component';
import { CareDetailExcretionComponent } from './dialogs/care-detail-excretion/care-detail-excretion.component';
import { CareDetailBathingComponent } from './dialogs/care-detail-bathing/care-detail-bathing.component';
import { CareDetailHospitalComponent } from './dialogs/care-detail-hospital/care-detail-hospital.component';
import { StaffNameCardComponent } from './components/staff-name-card/staff-name-card.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { AddTimePipe } from './pipes/add-time.pipe';
import { ConfirmationListComponent } from './dialogs/confirmation-list/confirmation-list.component';
import { CareDetailTransportComponent } from './dialogs/care-detail-transport/care-detail-transport.component';
import { ScheduledPlanTableComponent } from './components/scheduled-plan-table/scheduled-plan-table.component';
import { ScheduledItemEditorComponent } from './dialogs/scheduled-item-editor/scheduled-item-editor.component';
import { ExcretionTemplateSelectorComponent } from './dialogs/excretion-template-selector/excretion-template-selector.component';
import { ProgressTemplateSelectorComponent } from './dialogs/progress-template-selector/progress-template-selector.component';
import { AutoKanaDirective, AutoKanaInputDirective } from './directives/auto-kana.directive';
import { IsTimelineItemTypePipe } from './pipes/is-timeline-item-type.pipe';
import { IsSettingsItemTypePipe } from './pipes/is-settings-item-type.pipe';
import { EventDetailComponent } from './dialogs/event-detail/event-detail.component';
import { IsSameTimePipe } from './pipes/is-same-time.pipe';

const COMPONENTS = [
  ScrollContentComponent, MessageBoxComponent, BadgeComponent,
  DateIntervalInputComponent, MonthIntervalInputComponent,
  FileSelectorComponent, IdenticonComponent,
  MenuComponent, MenuItemComponent,
  WarekiDateInputComponent, DateTimePickerComponent,
  ResidentPanelComponent, TimelineComponent, TimelineItemContentComponent, TimelineNoteRepliesComponent,
  CustomSliderComponent,
  DateSelectorComponent, MonthSelectorComponent,
  ResidentNameCardComponent, ResidentPanelMultiComponent,
  StaffNameCardComponent,
  MonthPickerComponent,
  ScheduledPlanTableComponent,
];

const DIALOGS = [
  GenericDialogComponent,
  CareDetailProgressComponent,
  CareDetailMealComponent,
  CareDetailVitalComponent,
  CareDetailExcretionComponent,
  CareDetailBathingComponent,
  CareDetailHospitalComponent,
  CareDetailTransportComponent,
  ExcretionTemplateSelectorComponent,
  ProgressTemplateSelectorComponent,
  ImageEditorComponent, ResidentInfoComponent, TimelineDialogComponent,
  DateSelectorCalendarHeaderComponent, MonthSelectorCalendarHeaderComponent,
  ResidentSelectComponent, StaffSelectorComponent,
  InsuranceCardEditorComponent,
  FullScreenTextareaComponent,
  EpisodeEditorComponent,
  ConfirmationListComponent,
  ScheduledItemEditorComponent,
  EventDetailComponent,
];

const DIRECTIVES = [
  AutoAddressDirective, AutoAddressButtonDirective,
  AutoKanaDirective, AutoKanaInputDirective,
];

const PIPES = [
  FileUrlPipe, FileSizePipe, RelativeTimePipe,
  AbsenceBadgePipe, ExcretionDegreeLabelPipe, WarekiDatePipe,
  AgePipe, OptionLabelPipe, AddTimePipe,
  IsTimelineItemTypePipe, IsSettingsItemTypePipe, IsSameTimePipe,
];

const EXTERNAL_MODULES = [
  PortalModule, OverlayModule,
  MatCheckboxModule, MatSelectModule, MatRadioModule, MatInputModule, MatDatepickerModule,
  MatSliderModule, MatFormFieldModule, MatSlideToggleModule, MatAutocompleteModule,
  MatToolbarModule, MatSidenavModule, MatMenuModule,
  MatListModule, MatTabsModule, MatCardModule, MatGridListModule, MatDividerModule,
  MatExpansionModule,
  MatButtonModule, MatButtonToggleModule, MatIconModule, MatProgressSpinnerModule,
  MatChipsModule, MatBadgeModule,
  MatDialogModule, MatTooltipModule, MatSnackBarModule, MatBottomSheetModule,
  MatTableModule, MatSortModule, MatPaginatorModule,
  MatMomentDateModule,
  DragDropModule, ScrollingModule, PlatformModule, LayoutModule,
  LazyLoadImageModule,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIALOGS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ...EXTERNAL_MODULES,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    ...EXTERNAL_MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CM_DATE_SELECTOR_FORMATS },
  ],
})
export class SharedModule {
  constructor(paginatorIntl: MatPaginatorIntl) {
    paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `全 ${length} 件中の 0 件`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `全 ${length} 件中の ${startIndex + 1} - ${endIndex} 件`;
    };
    paginatorIntl.itemsPerPageLabel = '表示件数';
  }

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        { provide: FileInfoApi, useClass: FileInfoApiOverrideService },
      ],
    };
  }
}
