import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, QueryList, Self, ViewChildren } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { gengoDefinitions, getWarekiComponent, momentFromWareki, WarekiComponent } from 'src/app/utils/date';

/**
 * 和暦での日付入力用コントロール
 * 対応する日付をmomentで返す。日付を完全に入力していない場合はnullになる
 */
@Component({
  selector: 'cm-wareki-date-input',
  templateUrl: './wareki-date-input.component.html',
  styleUrls: ['./wareki-date-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: WarekiDateInputComponent },
  ],
})
export class WarekiDateInputComponent implements MatFormFieldControl<moment.Moment>, ControlValueAccessor, OnInit, OnDestroy {
  static idSeq = 0;
  autofilled?: boolean;

  form: FormGroup<{ [K in keyof WarekiComponent]: FormControl<WarekiComponent[K]> }>;
  stateChanges = new Subject<void>();
  errorState = false;
  controlType = 'warekit-date-input';

  @Input() mode: 'month' | 'date' | 'datetime' = 'date';
  gengos = gengoDefinitions.map(def => def.name);
  years: number[] = [];
  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  dates: number[] = [];

  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  @HostBinding() id = `cm-wareki-date-input-${WarekiDateInputComponent.idSeq++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input()
  get value(): moment.Moment {
    return momentFromWareki(this.form.value);
  }
  set value(value: moment.Moment) {
    if (!moment.isMoment(value)) {
      value = moment(value);
    }

    const wareki = getWarekiComponent(value);

    if (wareki) {
      this.form.setValue(wareki);
      this.rebuildYears(wareki.gengo);
      this.rebuildDates();
    }

    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get focused() {
    return this.selects != null && this.selects.some(select => select.focused);
  }

  get empty() {
    return Object.values(this.form.value).every(k => k == null);
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = !!required;
    this.stateChanges.next();
  }
  private _required: boolean;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = !!disabled;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get readonly() {
    return this._readonly;
  }
  set readonly(readonly: boolean) {
    this._readonly = !!readonly;
    this.stateChanges.next();
  }
  private _readonly: boolean;

  private _onChange: any;
  private _onTouched: any;

  constructor(
    protected formBuilder: FormBuilder,
    private focusMonitor: FocusMonitor,
    private el: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this.form = this.formBuilder.group({
      gengo: [null],
      year: [null],
      month: [null],
      date: [null],
      hour: [null],
      minute: [null],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.focusMonitor.monitor(this.el).subscribe(
      origin => {
        if (!origin) {
          this.onTouched();
        }
      },
    );
  }

  ngOnDestroy() {
    this.focusMonitor.stopMonitoring(this.el);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick() {

  }

  writeValue(value: moment.Moment) {
    this.value = value;
  }

  registerOnChange(fn: (_: moment.Moment) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  /**
   * 元号によって年の選択肢を再構築する
   * @param gengo 元号名
   */
  rebuildYears(gengo: string) {
    const gengoDef = gengoDefinitions.find(def => def.name === gengo);
    this.years = Array(gengoDef.years).fill(null).map((v, i) => i + 1);

    if (this.form.value.year > gengoDef.years) {
      this.form.get('year').setValue(1);
    }
  }

  /**
   * 月と閏年を考慮して日の選択肢を再構築する
   * 元号・年・月のいずれかが指定されていない場合は何もしない
   */
  rebuildDates() {
    const wareki = { ...this.form.value };

    if (wareki.gengo == null || wareki.year == null || wareki.month == null) {
      return;
    }

    // 日が未指定だとmomentFromWarekiがnullを返すので仮に1日とする
    if (wareki.date == null) {
      wareki.date = 1;
    }

    const value = momentFromWareki(wareki);
    const month = value.month() + 1;
    let dateLen = 31;

    if ([4, 6, 9, 11].indexOf(month) !== -1) {
      dateLen = 30;
    } else if (month === 2) {
      if (value.isLeapYear()) {
        dateLen = 29;
      } else {
        dateLen = 28;
      }
    }

    this.dates = Array(dateLen).fill(null).map((v, i) => i + 1);
  }

  onChange(value: moment.Moment) {
    this._onChange(value);
    this.errorState = this.ngControl && !!this.ngControl.errors;
  }

  onTouched() {
    this._onTouched();
  }

  setDisabledState(disabled: boolean) {
    this.form[disabled ? 'disable' : 'enable']();
    this.disabled = disabled;
  }
}
