/* eslint-disable */
import {
  Company,
  Office,
  RoleGroup,
  FileInfo
} from '../index';

declare var Object: any;
export interface StaffInterface {
  "code"?: string;
  "firstName"?: string;
  "lastName"?: string;
  "firstNameKana"?: string;
  "lastNameKana"?: string;
  "sex"?: string;
  "position"?: string;
  "birthday"?: Date|string;
  "zipCode"?: string;
  "address1"?: string;
  "address2"?: string;
  "phone"?: string;
  "mobilePhone"?: string;
  "fax"?: string;
  "email"?: string;
  "mobileMail"?: string;
  "employedAt"?: Date|string;
  "remark"?: string;
  "isSuspended"?: boolean;
  "suspensionReason"?: string;
  "status": number;
  "realm"?: string;
  "username"?: string;
  "emailVerified"?: boolean;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "updaterId"?: number;
  "roleGroupId"?: number;
  "password"?: string;
  accessTokens?: any[];
  company?: Company;
  office?: Office;
  roleGroup?: RoleGroup;
  photo?: FileInfo;
}

export class Staff implements StaffInterface {
  "code": string = '';
  "firstName": string = '';
  "lastName": string = '';
  "firstNameKana": string = '';
  "lastNameKana": string = '';
  "sex": string = '';
  "position": string = '';
  "birthday": Date|string = <any>null;
  "zipCode": string = '';
  "address1": string = '';
  "address2": string = '';
  "phone": string = '';
  "mobilePhone": string = '';
  "fax": string = '';
  "email": string = '';
  "mobileMail": string = '';
  "employedAt": Date|string = <any>null;
  "remark": string = '';
  "isSuspended": boolean = false;
  "suspensionReason": string = '';
  "status": number = 1;
  "realm": string = '';
  "username": string = '';
  "emailVerified": boolean = false;
  "id": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "updaterId": number = <any>null;
  "roleGroupId": number = <any>null;
  "password": string = '';
  accessTokens?: any[];
  company?: Company;
  office?: Office;
  roleGroup?: RoleGroup;
  photo?: FileInfo;
  constructor(data?: Partial<StaffInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Staff`.
   */
  public static getModelName() {
    return "Staff";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Staff for dynamic purposes.
  **/
  public static factory(data: StaffInterface): Staff{
    return new Staff(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Staff',
      plural: 'Staff',
      path: 'Staff',
      idName: 'id',
      properties: {
        "code": {
          name: 'code',
          type: 'string'
        },
        "firstName": {
          name: 'firstName',
          type: 'string'
        },
        "lastName": {
          name: 'lastName',
          type: 'string'
        },
        "firstNameKana": {
          name: 'firstNameKana',
          type: 'string'
        },
        "lastNameKana": {
          name: 'lastNameKana',
          type: 'string'
        },
        "sex": {
          name: 'sex',
          type: 'string'
        },
        "position": {
          name: 'position',
          type: 'string'
        },
        "birthday": {
          name: 'birthday',
          type: 'Date|string'
        },
        "zipCode": {
          name: 'zipCode',
          type: 'string'
        },
        "address1": {
          name: 'address1',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "mobilePhone": {
          name: 'mobilePhone',
          type: 'string'
        },
        "fax": {
          name: 'fax',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "mobileMail": {
          name: 'mobileMail',
          type: 'string'
        },
        "employedAt": {
          name: 'employedAt',
          type: 'Date|string'
        },
        "remark": {
          name: 'remark',
          type: 'string'
        },
        "isSuspended": {
          name: 'isSuspended',
          type: 'boolean'
        },
        "suspensionReason": {
          name: 'suspensionReason',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "roleGroupId": {
          name: 'roleGroupId',
          type: 'number'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        roleGroup: {
          name: 'roleGroup',
          type: 'RoleGroup',
          model: 'RoleGroup',
          relationType: 'belongsTo',
                  keyFrom: 'roleGroupId',
          keyTo: 'id'
        },
        photo: {
          name: 'photo',
          type: 'FileInfo',
          model: 'FileInfo',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'imageableId'
        },
      }
    }
  }
}
