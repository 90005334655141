import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { scheduleTimelineStart } from 'src/app/constants';
import { DialogService, DialogWidth } from 'src/app/services/dialog.service';
import { PlanWeeklyDetail } from 'src/loopback';
import { ScheduledItemEditorComponent } from '../../dialogs/scheduled-item-editor/scheduled-item-editor.component';

@Component({
  selector: 'cm-scheduled-plan-table',
  templateUrl: './scheduled-plan-table.component.html',
  styleUrls: ['./scheduled-plan-table.component.scss'],
})
export class ScheduledPlanTableComponent implements OnInit {

  columns: string[] = [];
  rows = Array(24).fill(undefined).map((_, i) => {
    let hour = i + scheduleTimelineStart;
    return { value: hour, label: `${hour >= 24 ? hour - 24 : hour}:00` };
  });
  otherCategory: string;

  rowHeight = 48;

  @Input() type: 'weekly' | 'daily' = 'weekly';
  @Input() details: PlanWeeklyDetail[] = [];
  @Output() edit = new EventEmitter<PlanWeeklyDetail[]>();

  constructor(
    private dialog: DialogService,
  ) { }

  ngOnInit() {
    this.columns = this.type === 'weekly'
      ? ['月', '火', '水', '木', '金', '土', '日', '主な日常生活上の活動']
      : ['生活行為', '意向・好み', '自分でできること', 'サポートの必要なこと', '対応（より詳しく）', '考えられるリスク'];
    this.otherCategory = this.type === 'weekly' ? '週単位以外のサービス' : 'その他のサービス';
  }

  getDetail(row: number, column: string) {
    return this.details.find(r => r.startTime === `${row}:00` && r.category === column);
  }

  getDuration(detail: PlanWeeklyDetail) {
    let diff = parseInt(detail.endTime) - parseInt(detail.startTime);

    return diff < 0 ? diff + 24 : diff;
  }

  openNewItemEditor(start: number, category: string) {
    const end = start === -1 ? -1 : start + 1;

    this.dialog.open(ScheduledItemEditorComponent, {
      data: {
        item: { start, end },
        isNew: true,
        others: this.details.filter(r => r.category === category),
      },
      size: DialogWidth.md,
    }).afterClosed().subscribe(
      result => {
        if (result && result.action === 'edit') {
          const record = new PlanWeeklyDetail({
            id: undefined,
            startTime: `${result.item.start}:00`,
            endTime: `${result.item.end}:00`,
            content: result.item.content,
            category: category,
          });
          this.details.push(record);
          this.edit.emit(this.details);
        }
      },
    );
  }

  openItemEditor(item: PlanWeeklyDetail) {
    const start = item.startTime === 'any' ? -1 : parseInt(item.startTime);
    const end = item.endTime === 'any' ? -1 : parseInt(item.endTime);

    this.dialog.open(ScheduledItemEditorComponent, {
      data: {
        item: { start, end, content: item.content },
        isNew: false,
        others: this.details.filter(r => r.category === item.category && r !== item),
      },
      size: DialogWidth.md,
    }).afterClosed().subscribe(
      result => {
        if (result) {
          if (result.action === 'delete') {
            const index = this.details.indexOf(item);
            this.details.splice(index, 1);
            this.edit.emit(this.details);
          } else if (result.action === 'edit') {
            item.startTime = `${result.item.start}:00`;
            item.endTime = `${result.item.end}:00`;
            item.content = result.item.content;
            this.edit.emit(this.details);
          }
        }
      },
    );
  }

}
