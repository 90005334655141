/* eslint-disable */
import {
  Company
} from '../index';

declare var Object: any;
export interface RoleGroupInterface {
  "name"?: string;
  "caps"?: any;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "updaterId"?: number;
  company?: Company;
}

export class RoleGroup implements RoleGroupInterface {
  "name": string = '';
  "caps": any = <any>null;
  "id": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "updaterId": number = <any>null;
  company?: Company;
  constructor(data?: Partial<RoleGroupInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RoleGroup`.
   */
  public static getModelName() {
    return "RoleGroup";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RoleGroup for dynamic purposes.
  **/
  public static factory(data: RoleGroupInterface): RoleGroup{
    return new RoleGroup(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RoleGroup',
      plural: 'RoleGroups',
      path: 'RoleGroups',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "caps": {
          name: 'caps',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
      }
    }
  }
}
