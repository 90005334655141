<div mat-dialog-title>テンプレートを選択</div>
<div mat-dialog-content>
  <div class="flex flex-row">
    <div class="column-left basis-[30%]">
      <mat-action-list>
        <h3 matSubheader>分類</h3>
        <ng-container *ngFor="let category of categories">
          <button mat-list-item [class.selected]="selectedCategory === category" (click)="selectCategory(category)">
            {{ category }}
          </button>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-action-list>
    </div>
    <div class="column-right basis-[70%]">
      <table *ngIf="selectedCategory; else noSelectionMessage" mat-table matSort [dataSource]="currentList"
        class="w-full template-list">
        <ng-container matColumnDef="place">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>場所</th>
          <td mat-cell *matCellDef="let row">{{ row.place }}</td>
        </ng-container>

        <ng-container matColumnDef="memo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>記録</th>
          <td mat-cell *matCellDef="let row">{{ row.memo }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['place', 'memo']; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: ['place', 'memo']" (click)="selectTemplate(row)"></tr>
      </table>

      <ng-template #noSelectionMessage>
        <p>分類を選択してください</p>
      </ng-template>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>キャンセル</button>
</div>
