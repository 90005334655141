/* eslint-disable */
import {
  Staff,
  Report,
  Company,
  Office,
  ReportConfirmation
} from '../index';

declare var Object: any;
export interface ReportReviewInterface {
  "heldAt"?: Date|string;
  "content"?: string;
  "managerName"?: string;
  "isShared"?: boolean;
  "id"?: number;
  "staffId"?: number;
  "reportId"?: number;
  "memberIds"?: Array<any>;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "updaterId"?: number;
  staff?: Staff;
  report?: Report;
  members?: Staff[];
  company?: Company;
  office?: Office;
  confirmations?: ReportConfirmation[];
}

export class ReportReview implements ReportReviewInterface {
  "heldAt": Date|string = <any>null;
  "content": string = '';
  "managerName": string = '';
  "isShared": boolean = false;
  "id": number = <any>null;
  "staffId": number = <any>null;
  "reportId": number = <any>null;
  "memberIds": Array<any> = <any>[];
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "updaterId": number = <any>null;
  staff?: Staff;
  report?: Report;
  members?: Staff[];
  company?: Company;
  office?: Office;
  confirmations?: ReportConfirmation[];
  constructor(data?: Partial<ReportReviewInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ReportReview`.
   */
  public static getModelName() {
    return "ReportReview";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ReportReview for dynamic purposes.
  **/
  public static factory(data: ReportReviewInterface): ReportReview{
    return new ReportReview(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ReportReview',
      plural: 'ReportReviews',
      path: 'ReportReviews',
      idName: 'id',
      properties: {
        "heldAt": {
          name: 'heldAt',
          type: 'Date|string'
        },
        "content": {
          name: 'content',
          type: 'string'
        },
        "managerName": {
          name: 'managerName',
          type: 'string'
        },
        "isShared": {
          name: 'isShared',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "reportId": {
          name: 'reportId',
          type: 'number'
        },
        "memberIds": {
          name: 'memberIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        report: {
          name: 'report',
          type: 'Report',
          model: 'Report',
          relationType: 'belongsTo',
                  keyFrom: 'reportId',
          keyTo: 'id'
        },
        members: {
          name: 'members',
          type: 'Staff[]',
          model: 'Staff',
          relationType: 'referencesMany',
                  keyFrom: 'memberIds',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        confirmations: {
          name: 'confirmations',
          type: 'ReportConfirmation[]',
          model: 'ReportConfirmation',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'referenceId'
        },
      }
    }
  }
}
