import { Input, HostBinding, Directive } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

@Directive()
export class FormFieldControlBase<T> implements MatFormFieldControl<T>, ControlValueAccessor {

  static idSeq = 0;
  autofilled?: boolean;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;

  @Input()
  get value(): T {
    throw new Error('get value() not implemented.');
  }
  set value(value: T) {
    throw new Error('set value() not implemented.');
  }

  id: string;
  describedBy: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  protected _placeholder: string;

  get empty(): boolean {
    throw new Error('get empty() not implemented.');
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = !!required;
    this.stateChanges.next();
  }
  protected _required: boolean;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = !!disabled;
    this.stateChanges.next();
  }
  protected _disabled = false;

  @Input()
  get readonly() {
    return this._readonly;
  }
  set readonly(readonly: boolean) {
    this._readonly = !!readonly;
    this.stateChanges.next();
  }
  protected _readonly: boolean;

  protected _onChange: (_: any) => void;
  protected _onTouched: (_: any) => void;

  constructor(
    public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(e: MouseEvent) {

  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onChange(value: any) {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
