import { Injectable } from '@angular/core';
import { ResidentStatus } from '../enums/resident-status';

export interface OptionItem {
  value: number;
  label: string;
}

export type OptionPropertyName = keyof { [P in keyof OptionsService as OptionsService[P] extends OptionItem[] ? P : never]: any }

/**
 * 各種選択肢の値とラベルの設定用クラス
 */
@Injectable({
  providedIn: 'root',
})
export class OptionsService {

  /**
   * 汎用のレコードステータス
   */
  recordStatus: OptionItem[] = [
    { value: 1, label: '有効' },
    { value: 2, label: '無効' },
  ];

  /**
   * チェック項目の対象作業
   */
  officeCategories: OptionItem[] = [
    { value: 1, label: '特養' },
    { value: 2, label: 'グループホーム' },
    { value: 3, label: '特定施設' },
    { value: 4, label: '定期巡回' },
  ];

  /**
   * 排尿量
   */
  urineDegrees: OptionItem[] = [
    { value: 0, label: 'なし' },
    { value: 1, label: '少量' },
    { value: 2, label: '中量' },
    { value: 3, label: '多量' },
    { value: -1, label: '計量する' },
  ];

  /**
   * 排尿量
   */
  fecesDegrees: OptionItem[] = [
    { value: 0, label: 'なし' },
    { value: 1, label: '付着' },
    { value: 2, label: '少量' },
    { value: 3, label: '中量' },
    { value: 4, label: '多量' },
    { value: -1, label: '計量する' },
  ];

  /**
   * 報告書：受付方法
   */
  reportReceptionWay: OptionItem[] = [
    { value: 1, label: '来訪' },
    { value: 2, label: '電話' },
    { value: 3, label: 'メール' },
    { value: -1, label: 'その他' },
  ];

  /**
   * 報告書：申立者種別
   */
  reportClaimantKind: OptionItem[] = [
    { value: 1, label: '入居者本人' },
    { value: 2, label: '他の入居者' },
    { value: 3, label: '入居者の家族' },
    { value: 4, label: '地域住民' },
    { value: 5, label: '業者' },
    { value: -1, label: 'その他' },
  ];

  /**
   * 介護度
   */
  insuranceCertification: OptionItem[] = [
    { value: 1, label: '要支援1' },
    { value: 2, label: '要支援2' },
    { value: 11, label: '要介護1' },
    { value: 12, label: '要介護2' },
    { value: 13, label: '要介護3' },
    { value: 14, label: '要介護4' },
    { value: 15, label: '要介護5' },
    { value: -1, label: '非該当' },
  ];

  /**
   * 介助区分
   */
  assistanceDegrees: OptionItem[] = [
    { value: 1, label: '自立' },
    { value: 2, label: '一部介助' },
    { value: 3, label: '全介助' },
  ];

  /**
   * 入居者ステータス
   */
  residentStatus: OptionItem[] = [
    { value: ResidentStatus.enabled, label: '有効' },
    { value: ResidentStatus.shortStay, label: 'ショートステイ利用' },
    { value: ResidentStatus.disabled, label: '退去済' },
    { value: ResidentStatus.inquiring, label: '問い合わせ中' },
    { value: ResidentStatus.candidate, label: '入居予定' },
    { value: ResidentStatus.trial, label: '体験入居中' },
    { value: ResidentStatus.cancelled, label: 'キャンセル' },
  ];

  /**
   * 日本の都道府県
   */
  prefectures: string[] = [
    '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県',
    '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
    '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県',
    '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県',
    '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県',
    '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県',
  ];

  /**
   * 選択肢のラベルを取得する
   * @param property プロパティ名
   * @param value 選択肢の番号
   */
  getOptionLabel(property: OptionPropertyName, value: number): string {
    const items = this[property];
    const found = items.find(status => status.value === value);

    return found ? found.label : null;
  }

}
