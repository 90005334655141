<div *ngFor="let reply of items" class="timeline-item-reply">
  <div class="timeline-item-header">
    <small class="timeline-item-creator">
      <mat-icon [inline]="true">edit</mat-icon>
      <ng-container *ngIf="reply.staff; else replyStaffEmpty">
        {{ reply.staff.lastName }} {{ reply.staff.firstName }}
      </ng-container>
      <ng-template #replyStaffEmpty>(削除された職員)</ng-template>
    </small>
    <small class="timeline-item-creator">
      <mat-icon [inline]="true">access_time</mat-icon>
      {{ reply.date.toDate() | date:'M月d日(EEE) H:mm' }}
    </small>
    <button *ngIf="canEdit(reply)" mat-button (click)="$event.stopPropagation();edit.emit(reply)"
      class="edit-button">編集</button>
    <button *ngIf="canEdit(reply)" mat-button color="warn"
      (click)="$event.stopPropagation();delete.emit(reply)" class="edit-button">削除</button>
  </div>
  <div class="timeline-item-body">
    <cm-timeline-item-content [item]="reply"></cm-timeline-item-content>
  </div>
</div>
