import { Component, Input, HostBinding, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'src/app/menu';
import { GlobalEventService } from 'src/app/services/global-event.service';

/**
 * メニュー項目
 */
@Component({
  selector: 'cm-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  /**
   * メニュー項目
   */
  @Input() menuItem: MenuItem;

  /**
   * 子アイテムフラグ
   */
  @Input() child = false;

  /**
   * コンパクト表示
   */
  @Input() compact = false;

  showBadge = false;

  @Output() itemClick = new EventEmitter<MenuItem>();

  @HostBinding('class.selected') get cSelected() { return this.menuItem.selected && (!this.menuItem.expanded || this.compact); }
  @HostBinding('class.expanded') get cExpanded() { return this.menuItem.expanded; }
  @HostBinding('class.subitem') get cSubitem() { return this.child; }
  @HostBinding('class.haschildren') get cHaschildren() { return this.menuItem.children; }
  @HostBinding('class.devided-bottom') get cDevidedBottom() { return this.menuItem.devideBottom; }
  @HostBinding('class.rounded') get cRounded() { return this.menuItem.appearance === 'rounded'; }

  constructor(
    private router: Router,
    private globalEvent: GlobalEventService,
  ) {}

  ngOnInit() {
    if (this.menuItem.observeBadgeStatus) {
      this.globalEvent.on(`showMenuBadge.${this.menuItem.paths.join('-')}`).subscribe(
        show => this.showBadge = show,
      );
    }
  }

  /**
   * メニューアイテムクリックハンドラ
   * @param event DOMイベント
   */
  onItemClick(event: MouseEvent): boolean {
    if (!this.menuItem.children || this.menuItem.children.length === 0) {
      this.navigate();
      this.itemClick.emit(this.menuItem);
    } else if (!this.compact) {
      this.toggleSubMenu();
    } else {
      const firstChild = this.menuItem.children[0];
      this.navigate(firstChild);
      this.itemClick.emit(this.menuItem);
    }

    return false;
  }

  onChildItemClick(item: MenuItem) {
    this.itemClick.emit(item);
  }

  /**
   * 子メニューの開閉
   */
  toggleSubMenu() {
    this.menuItem.expanded = !this.menuItem.expanded;
  }

  /**
   * メニューリンクの処理
   * urlプロパティがある場合は新しいウィンドウでURLを開く
   */
  private navigate(item?: MenuItem) {
    item = item || this.menuItem;

    if (item.url) {
      window.open(item.url, item.target);
    } else {
      this.router.navigate(item.paths);
    }
  }

}
