<div [formGroup]="form" class="flex flex-row gap-2">
  <div class="basis-1/2">
    <input matInput #startInput="matDatepickerInput" formControlName="start"
      [matDatepicker]="startDatepicker" [readonly]="readonly" [max]="form.value.end" (click)="startDatepicker.open()"
      (dateChange)="onChange(value)">
    <mat-datepicker #startDatepicker startView="multi-year"
      [calendarHeaderComponent]="calendarHeader"
      (monthSelected)="onMonthSelected($event, 'start', startDatepicker)"></mat-datepicker>
  </div>
  <button mat-icon-button type="button" class="clear-button" (click)="clearStart()">
    <mat-icon>clear</mat-icon>
  </button>
  <span>～</span>
  <div class="basis-1/2">
    <input matInput #endInput="matDatepickerInput" formControlName="end"
      [matDatepicker]="endDatepicker" [readonly]="readonly" [min]="form.value.start" (click)="endDatepicker.open()"
      (dateChange)="onChange(value)">
    <mat-datepicker #endDatepicker startView="multi-year" [calendarHeaderComponent]="calendarHeader"
      (monthSelected)="onMonthSelected($event, 'end', endDatepicker)"></mat-datepicker>
  </div>
  <button mat-icon-button type="button" class="clear-button" (click)="clearStart()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
