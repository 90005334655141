import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Resident, Assessment, Plan, Room, CareHistoryApi, CareHistory } from 'src/loopback';
import * as moment from 'moment';
import { InsuranceSummary } from 'src/app/classes/insurance-summary';
import { contactTypeIcons } from 'src/app/constants';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { ScreenSize } from 'src/app/enums/screen-size.enum';
import { TimelineItem } from 'src/app/classes/timeline';
import { AppSettingsDictionary, AppSettingsService } from 'src/app/services/app-settings.service';
import { AES, HmacSHA256 } from 'crypto-js';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { mergeMap, tap } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { Validators } from '@angular/forms';
import { DialogComponentBase, DialogService, DialogWidth } from 'src/app/services/dialog.service';

@Component({
  selector: 'cm-resident-info',
  templateUrl: './resident-info.component.html',
  styleUrls: ['./resident-info.component.scss'],
})
export class ResidentInfoComponent extends DialogComponentBase<ResidentInfoDialogData, void> implements OnInit {

  resident: Resident;
  nameCardSize: 'small' | 'medium' = 'medium';
  room: Room;
  assessment: Assessment;
  plan: Plan;
  isBirthday = false;
  insuranceSummary: InsuranceSummary;
  histories: TimelineItem[];
  settings: AppSettingsDictionary;
  contactTypeIcons = contactTypeIcons;
  offlineDataKey: string;
  isOffline = false;

  constructor(
    private state: GlobalStateService,
    private appSettings: AppSettingsService,
    private careHistoryApi: CareHistoryApi,
    private dialog: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: ResidentInfoDialogData,
  ) {
    super();
  }

  ngOnInit() {
    this.isOffline = this.data.isOffline;
    this.resident = this.data.resident;
    this.assessment = this.resident.assessments[0];
    this.plan = this.resident.plans[0];
    this.insuranceSummary = this.data.insuranceCards;

    if (this.isOffline) {
      this.histories = (this.data.histories || []).map(h => TimelineItem.fromRecordHistory(h));
      this.settings = this.data.settings;
      this.appSettings.reset(this.data.settings, this.resident.officeId);
    } else {
      this.room = this.data.room;
      this.appSettings.getAll(this.resident.officeId).subscribe(settings => this.settings = settings);
      this.offlineDataKey = localStorage.getItem(`cm.offlineDataKey.resident${this.resident.id}`);
    }

    this.state.onChanged('screenSize').subscribe(
      size => {
        if (size.currentValue === ScreenSize.xsmall) {
          this.nameCardSize = 'small';
        } else {
          this.nameCardSize = 'medium';
        }
      },
    );

    const now = moment();
    const birthday = moment(this.resident.birthday);
    this.isBirthday = now.month() === birthday.month() && now.date() === birthday.date();
  }

  saveOfflineData() {
    let secret: string;
    const message = [
      'インターネットに繋がっていなくてもデータを閲覧できるように、端末に入居者情報を保存します。',
      '閲覧用の暗証番号を設定してください。',
    ];

    if (this.offlineDataKey) {
      message.push('前回保存時の暗証番号は無効になりますのでご注意ください。');
    }

    this.dialog.open(GenericDialogComponent, {
      data: {
        type: 'prompt',
        promptValidators: [Validators.required, Validators.pattern(/^\d*$/)],
        promptInputType: 'tel',
        title: '持ち出し用にデータを保存',
        message,
      },
      size: DialogWidth.md,
    }).afterClosed().pipe(
      tap<string>(res => secret = res),
      mergeMap(res => res ? this.getHistories() : EMPTY),
      tap(histories => {
        const keyHash = HmacSHA256('cm.offlineData', secret).toString();

        if (this.offlineDataKey !== keyHash && localStorage.getItem(keyHash) != null) {
          this.dialog.open(GenericDialogComponent, {
            data: {
              type: 'alert',
              title: '暗証番号が重複しています',
              message: [
                'この暗証番号で別の入居者データが保存されています。',
                '別の暗証番号を指定してください。',
              ],
            },
            size: DialogWidth.md,
          });
          return;
        }

        if (this.offlineDataKey) {
          localStorage.removeItem(this.offlineDataKey);
        }

        const data = {
          resident: this.resident,
          insuranceCards: this.insuranceSummary,
          histories,
          settings: this.settings,
        };
        const encryptedData = AES.encrypt(JSON.stringify(data), secret).toString();

        localStorage.setItem(`cm.offlineDataKey.resident${this.resident.id}`, keyHash);
        localStorage.setItem(keyHash, encryptedData);

        this.offlineDataKey = keyHash;
        this.dialog.open(GenericDialogComponent, {
          data: {
            type: 'alert',
            title: '保存しました',
            message: [
              'ログイン画面で「持ち出し用データの確認」ボタンを押して暗証番号を入力すると入居者情報が閲覧できます。',
              'ブラウザのキャッシュやサイトデータ削除を行うとデータが消去されますのでご注意ください。',
            ],
          },
          size: DialogWidth.md,
        });
      }),
    ).subscribe();
  }

  deleteOfflineData() {
    if (!this.offlineDataKey) {
      return;
    }

    this.dialog.open(GenericDialogComponent, {
      data: {
        type: 'alert',
        title: '持ち出し用データの削除',
        message: ['端末に保存したデータを削除します。'],
        okButtonColor: 'warn',
      },
      size: DialogWidth.md,
    }).afterClosed().pipe(
      mergeMap(ok => ok ? of(null) : EMPTY),
    ).subscribe(
      () => {
        localStorage.removeItem(this.offlineDataKey);
        localStorage.removeItem(`cm.offlineDataKey.resident${this.resident.id}`);
        this.offlineDataKey = undefined;
      },
    );
  }

  getHistories() {
    const start = moment().subtract(1, 'month').startOf('day');
    const end = moment().endOf('day');

    return this.careHistoryApi.find({
      where: {
        and: [
          { residentId: this.resident.id },
          { recordedAt: { gte: start } },
          { recordedAt: { lte: end } },
        ],
      },
      include: ['staff', 'record'],
      order: 'recordedAt DESC',
    });
  }

}

export interface ResidentInfoDialogData {
  isOffline?: boolean;
  resident: Resident;
  insuranceCards: InsuranceSummary;
  histories?: CareHistory[];
  settings?: AppSettingsDictionary;
  room?: Room;
}
