/* eslint-disable */

declare var Object: any;
export interface AssessmentDetailInterface {
  "id"?: string;
  "title"?: string;
  "body"?: string;
  "assistance"?: number;
  "hiddenInPopup"?: boolean;
}

export class AssessmentDetail implements AssessmentDetailInterface {
  "id": string = '';
  "title": string = '';
  "body": string = '';
  "assistance": number = <any>null;
  "hiddenInPopup": boolean = false;
  constructor(data?: Partial<AssessmentDetailInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AssessmentDetail`.
   */
  public static getModelName() {
    return "AssessmentDetail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AssessmentDetail for dynamic purposes.
  **/
  public static factory(data: AssessmentDetailInterface): AssessmentDetail{
    return new AssessmentDetail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AssessmentDetail',
      plural: 'AssessmentDetails',
      path: 'AssessmentDetails',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "title": {
          name: 'title',
          type: 'string'
        },
        "body": {
          name: 'body',
          type: 'string'
        },
        "assistance": {
          name: 'assistance',
          type: 'number'
        },
        "hiddenInPopup": {
          name: 'hiddenInPopup',
          type: 'boolean'
        },
      },
      relations: {
      }
    }
  }
}
