<div mat-dialog-title>{{ title }}</div>
<div mat-dialog-content [formGroup]="form">
  <mat-card appearance="outlined" class="meta-card mb-8">
    <mat-card-content>
      <table class="meta-table w-full">
        <tbody>
          <tr>
            <th>入居者</th>
            <td>
              <cm-resident-name-card *ngIf="resident" [resident]="resident"
                [room]="resident.moveHistories[0]?.room" size="small"></cm-resident-name-card>
              <span *ngIf="residents" [matTooltip]="residentNames">{{ residents.length }}人の入居者</span>
            </td>
          </tr>
          <tr>
            <th>記録日時</th>
            <td>
              <cm-date-time-picker formControlName="recordedAt"></cm-date-time-picker>
            </td>
          </tr>
          <tr>
            <th>記録者</th>
            <td>
              <mat-select *ngIf="staffList$ | async as staffList" formControlName="staffId" class="transparent-input">
                <mat-option *ngFor="let staff of staffList" [value]="staff.id">
                  {{ staff.lastName }} {{ staff.firstName }}
                </mat-option>
              </mat-select>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
  </mat-card>
  <div class="section-content">
    <button mat-stroked-button (click)="openTemplateSelector()">
      <mat-icon>list</mat-icon> テンプレート
    </button>
  </div>
  <ng-container *ngIf="useFailed || useImplements">
    <h2 class="section-header mat-h2">排泄の状況</h2>
    <div class="section-content flex flex-col sm:flex-row sm:gap-4">
      <mat-form-field *ngIf="useFailed" class="flex-1" floatLabel="always">
        <mat-label>失禁</mat-label>
        <mat-radio-group formControlName="isFailed">
          <mat-radio-button [value]="false">なし</mat-radio-button>
          <mat-radio-button [value]="true">あり</mat-radio-button>
        </mat-radio-group>
        <input matInput class="!hidden">
      </mat-form-field>
      <mat-form-field *ngIf="useImplements" class="flex-1">
        <mat-label>排泄用具</mat-label>
        <mat-select formControlName="implement">
          <mat-option *ngFor="let o of implements" [value]="o">{{ o }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
  <h2 class="section-header mat-h2">排尿</h2>
  <div class="section-content">
    <mat-form-field class="w-full">
      <mat-label>排尿量</mat-label>
      <mat-select formControlName="urineDegree"
        (selectionChange)="onUrineSelectionChange($event.value)">
        <mat-option *ngFor="let o of opt.urineDegrees" [value]="o.value">{{ o.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="form.value.urineDegree === -1" class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[120px]">
        <mat-label>計量</mat-label>
        <input type="number" pattern="\d*" matInput formControlName="urineAmount"
          [value]="form.value.urineAmount || ''">
        <span matTextSuffix>cc</span>
      </mat-form-field>
      <mat-slider class="flex-1" discrete [min]="0"
        [max]="3000" [step]="10"><input matSliderThumb formControlName="urineAmount" [value]="form.value.urineAmount" /></mat-slider>
    </div>
  </div>
  <h2 class="section-header mat-h2">排便</h2>
  <div class="section-content">
    <mat-form-field class="w-full">
      <mat-label>排便量</mat-label>
      <mat-select formControlName="fecesDegree"
        (selectionChange)="onFecesSelectionChange($event.value)">
        <mat-option *ngFor="let o of opt.fecesDegrees" [value]="o.value">{{ o.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="form.value.fecesDegree === -1" class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[120px]">
        <mat-label>計量</mat-label>
        <input type="number" pattern="\d*" matInput formControlName="fecesAmount"
          [value]="form.value.fecesAmount || ''">
        <span matTextSuffix>cc</span>
      </mat-form-field>
      <mat-slider class="flex-1" discrete [min]="0"
        [max]="3000" [step]="10"><input matSliderThumb formControlName="fecesAmount" [value]="form.value.fecesAmount" /></mat-slider>
    </div>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <mat-form-field class="section-content w-full">
    <mat-label>メモ</mat-label>
    <textarea matInput rows="5" formControlName="memo"></textarea>
  </mat-form-field>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="flex flex-row gap-4">
        <div class="rounded-icon">
          <mat-icon>comment</mat-icon>
        </div>
        <div class="flex-1">
          <mat-form-field class="w-full">
            <mat-label>この記録に関するノート<ng-container *ngIf="!id || !note">を追加</ng-container>
            </mat-label>
            <textarea matInput #noteContent="matInput" rows="5" formControlName="noteContent"></textarea>
            <mat-hint *ngIf="!id || !note">記録の保存と同時にノートに投稿されます</mat-hint>
          </mat-form-field>
          <ng-container *ngIf="note">
            <mat-divider></mat-divider>
            <cm-timeline-note-replies *ngIf="noteReplies && noteReplies.length" [items]="noteReplies"
              class="mb-4"></cm-timeline-note-replies>
            <div class="text-right">
              <button mat-button (click)="openNoteReplyEditor()">
                <mat-icon>reply</mat-icon> 返信
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>キャンセル</button>
  <button mat-button mat-stroked-button (click)="noteContent.focus()">
    <mat-icon>comment</mat-icon> ノートを編集
  </button>
  <button mat-button mat-flat-button type="submit" color="primary" (click)="onSubmit($event)"
    [disabled]="!isDirty || isSaving">
    <mat-icon>save</mat-icon> 保存
  </button>
</div>
