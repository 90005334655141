<h1 *ngIf="appTitleLogo; else textTitle"><img [src]="appTitleLogo" [alt]="appTitle" class="w-[480px]"></h1>
<ng-template #textTitle><h1>{{ appTitle }}</h1></ng-template>

<form [formGroup]="form" (ngSubmit)="onSubmit($event)" class="form-horizontal">
  <mat-form-field class="w-full">
    <mat-label>ユーザー名またはメールアドレス</mat-label>
    <input matInput formControlName="usernameOrEmail" type="text" class="form-control" id="login-input-username">
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>パスワード</mat-label>
    <input matInput formControlName="password" type="password" class="form-control" id="login-input-password">
  </mat-form-field>
  <button mat-stroked-button color="primary" [disabled]="!form.valid" type="submit" class="btn btn-secondary btn-auth">ログイン</button>
</form>

<button mat-stroked-button (click)="showOfflineData()" class="mt-4">持ち出し用データの確認</button>
