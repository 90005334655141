import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {

  transform(date: string|Date|moment.Moment): string {
    date = moment(date);
    const now = moment();
    const secondsDiff = now.diff(date, 'seconds');
    const daysDiff = now.clone().startOf('day').diff(date.clone().startOf('day'), 'days');
    const threshMinute = 60;
    const threshHour = 60 * 60;
    const threshDay = 60 * 60 * 12;

    if (secondsDiff < threshMinute) {
      return '1分以内';
    } else if (secondsDiff < threshHour) {
      return Math.round(secondsDiff / 60) + '分前';
    } else if (secondsDiff < threshDay && daysDiff === 0) {
      return Math.round(secondsDiff / 60 / 60) + '時間前';
    } else if (daysDiff === 0) {
      return '今日 ' + date.format('HH:mm');
    } else if (daysDiff === 1) {
      return '昨日 ' + date.format('HH:mm');
    } else if (daysDiff === 2) {
      return 'おととい ' + date.format('HH:mm');
    } else {
      return date.format('M月D日 HH:mm');
    }
  }

}
