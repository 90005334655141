/* eslint-disable */
import {
  Staff,
  Resident,
  VisitPlan,
  AssessmentDetail,
  Company,
  Office,
  PlanHistory
} from '../index';

declare var Object: any;
export interface VisitSummaryInterface {
  "targetMonth"?: Date|string;
  "sickness"?: string;
  "medicalHistory"?: string;
  "condition"?: string;
  "visitDays"?: number;
  "visitTimes"?: number;
  "familyInformation"?: string;
  "nursingDetail"?: string;
  "nursingConsideration"?: string;
  "necessaryService"?: string;
  "remark"?: string;
  "id"?: number;
  "staffId"?: number;
  "residentId"?: number;
  "planId"?: number;
  "_adls"?: Array<any>;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "recordedAt"?: Date|string;
  "updaterId"?: number;
  staff?: Staff;
  resident?: Resident;
  plan?: VisitPlan;
  adls?: AssessmentDetail[];
  company?: Company;
  office?: Office;
  history?: PlanHistory;
}

export class VisitSummary implements VisitSummaryInterface {
  "targetMonth": Date|string = <any>null;
  "sickness": string = '';
  "medicalHistory": string = '';
  "condition": string = '';
  "visitDays": number = <any>null;
  "visitTimes": number = <any>null;
  "familyInformation": string = '';
  "nursingDetail": string = '';
  "nursingConsideration": string = '';
  "necessaryService": string = '';
  "remark": string = '';
  "id": number = <any>null;
  "staffId": number = <any>null;
  "residentId": number = <any>null;
  "planId": number = <any>null;
  "_adls": Array<any> = <any>[];
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "recordedAt": Date|string = <any>null;
  "updaterId": number = <any>null;
  staff?: Staff;
  resident?: Resident;
  plan?: VisitPlan;
  adls?: AssessmentDetail[];
  company?: Company;
  office?: Office;
  history?: PlanHistory;
  constructor(data?: Partial<VisitSummaryInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VisitSummary`.
   */
  public static getModelName() {
    return "VisitSummary";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of VisitSummary for dynamic purposes.
  **/
  public static factory(data: VisitSummaryInterface): VisitSummary{
    return new VisitSummary(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VisitSummary',
      plural: 'VisitSummaries',
      path: 'VisitSummaries',
      idName: 'id',
      properties: {
        "targetMonth": {
          name: 'targetMonth',
          type: 'Date|string'
        },
        "sickness": {
          name: 'sickness',
          type: 'string'
        },
        "medicalHistory": {
          name: 'medicalHistory',
          type: 'string'
        },
        "condition": {
          name: 'condition',
          type: 'string'
        },
        "visitDays": {
          name: 'visitDays',
          type: 'number'
        },
        "visitTimes": {
          name: 'visitTimes',
          type: 'number'
        },
        "familyInformation": {
          name: 'familyInformation',
          type: 'string'
        },
        "nursingDetail": {
          name: 'nursingDetail',
          type: 'string'
        },
        "nursingConsideration": {
          name: 'nursingConsideration',
          type: 'string'
        },
        "necessaryService": {
          name: 'necessaryService',
          type: 'string'
        },
        "remark": {
          name: 'remark',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "planId": {
          name: 'planId',
          type: 'number'
        },
        "_adls": {
          name: '_adls',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "recordedAt": {
          name: 'recordedAt',
          type: 'Date|string',
          default: <any>null
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        plan: {
          name: 'plan',
          type: 'VisitPlan',
          model: 'VisitPlan',
          relationType: 'belongsTo',
                  keyFrom: 'planId',
          keyTo: 'id'
        },
        adls: {
          name: 'adls',
          type: 'AssessmentDetail[]',
          model: 'AssessmentDetail',
          relationType: 'embedsMany',
                  keyFrom: '_adls',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        history: {
          name: 'history',
          type: 'PlanHistory',
          model: 'PlanHistory',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'recordId'
        },
      }
    }
  }
}
