import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';

import { AuthRequiredGuard } from './guards/auth-required.guard';
import { RedirectAuthorizedGuard } from './guards/redirect-authorized.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: 'login', component: LoginComponent, canActivate: [RedirectAuthorizedGuard] },
  {
    path: 'dashboard', canActivate: [AuthRequiredGuard],
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'care', canLoad: [AuthRequiredGuard], canActivateChild: [AuthRequiredGuard], data: { caps: ['readCareRecord'] },
    loadChildren: () => import('./features/care/care.module').then(m => m.CareModule),
  },
  {
    path: 'report', canLoad: [AuthRequiredGuard], canActivateChild: [AuthRequiredGuard], data: { caps: ['readReport'] },
    loadChildren: () => import('./features/report/report.module').then(m => m.ReportModule),
  },
  {
    path: 'service-plan', canLoad: [AuthRequiredGuard], canActivateChild: [AuthRequiredGuard], data: { caps: ['readServicePlan'] },
    loadChildren: () => import('./features/service-plan/service-plan.module').then(m => m.ServicePlanModule),
  },
  {
    path: 'resident', canLoad: [AuthRequiredGuard], canActivateChild: [AuthRequiredGuard], data: { caps: ['editResident'] },
    loadChildren: () => import('./features/resident/resident.module').then(m => m.ResidentModule),
  },
  {
    path: 'office', canLoad: [AuthRequiredGuard], canActivateChild: [AuthRequiredGuard], data: { caps: ['editOffice'] },
    loadChildren: () => import('./features/office/office.module').then(m => m.OfficeModule),
  },
  {
    path: 'user', canLoad: [AuthRequiredGuard], canActivateChild: [AuthRequiredGuard], data: { caps: ['editStaff'] },
    loadChildren: () => import('./features/user/user.module').then(m => m.UserModule),
  },
  {
    path: 'settings', canLoad: [AuthRequiredGuard], canActivateChild: [AuthRequiredGuard], data: { caps: ['editSettings'] },
    loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'event', canLoad: [AuthRequiredGuard], canActivateChild: [AuthRequiredGuard], data: { caps: ['readCareRecord'] },
    loadChildren: () => import('./features/event/event.module').then(m => m.EventModule),
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
