import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { GlobalEventService } from './global-event.service';
import { LoopBackAuth, LoopBackConfig } from 'src/loopback';
import { Observable, Subject, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * WebSocketサービス
 */
@Injectable({
  providedIn: 'root',
})
export class SocketService {

  socket: Socket;
  authenticated = false;
  connectionSubject: Subject<void>;
  eventListeners: { [event: string]: (data: any) => void } = {};

  constructor(
    private globalEvent: GlobalEventService,
    private auth: LoopBackAuth,
  ) {
    this.socket = io(LoopBackConfig.getPath(), {
      autoConnect: false,
      secure: LoopBackConfig.isSecureWebSocketsSet(),
      forceNew: true,
      transports: ['websocket'],
    });

    this.socket.on('connect', () => {
      console.log('connect -> auth');
      const token = this.auth.getToken();
      this.emit('authentication', token);
    });

    this.socket.on('connect_error', (err: any) => {
      console.log('connect_error');
      this.authenticated = false;
      console.log(err);
      this.connectionSubject.error(err);
    });

    this.socket.on('connect_timeout', (err: any) => {
      console.log('connect_timeout');
      this.authenticated = false;
      this.connectionSubject.error(err);
    });

    this.socket.on('error', (err: any) => {
      console.log('error');
      this.authenticated = false;
      this.connectionSubject.error(err);
    });

    this.socket.on('authenticated', () => {
      console.log('authenticated');
      this.authenticated = true;
      this.connectionSubject.next();
      this.connectionSubject.complete();
    });

    this.socket.on('unauthorized', (err: any) => {
      console.log('unauthorized');
      this.authenticated = false;
      this.connectionSubject.error(err);
    });

    this.socket.on('reconnecting', (attempt) => {
      console.log(`reconnecting:${attempt}`);
    });

    this.socket.on('reconnect', (attempt) => {
      console.log(`reconnected:${attempt}`);
    });

    this.socket.on('disconnect', reason => {
      console.log(`disconnected:${reason}`);
    });
  }

  /**
   * 接続
   * socket.ioのイベントリスナー経由で接続完了を検知する
   * @returns
   */
  connect(): Observable<void> {
    this.connectionSubject = new Subject<void>();

    if (!this.socket.connected) {
      this.socket.open();
    } else {
      return throwError('Socket already connected.');
    }

    return this.connectionSubject.asObservable().pipe(
      tap(() => {
        Object.keys(this.eventListeners).forEach(event => {
          this.ensureSocketListened(event);
        });
      }),
    );
  }

  /**
   * WebSocketのメッセージを購読
   * @param event イベント名
   * @returns
   */
  on(event: string): Observable<any> {
    const globalEventName = `socket:${event}`;

    if (!this.eventListeners[event]) {
      this.eventListeners[event] = (data: any) => {
        this.globalEvent.trigger(globalEventName, data);
      };
    }

    if (this.socket) {
      this.ensureSocketListened(event);
    }

    return this.globalEvent.on(globalEventName);
  }

  /**
   * WebSocketへメッセージを送信
   * @param event イベント名
   * @param data データ
   */
  emit(event: string, data?: any): void {
    if (data) {
      this.socket.emit(event, data);
    } else {
      this.socket.emit(event);
    }
  }

  /**
   * 切断
   */
  disconnect() {
    this.authenticated = false;
    if (this.socket) {
      this.socket.close();
    }
  }

  /**
   * WebSocket側にイベントリスナーが登録されていない場合は登録する
   * @param event イベント名
   */
  private ensureSocketListened(event: string) {
    if (this.eventListeners[event] && this.socket.listeners(event).length === 0) {
      this.socket.on(event, (data: any) => {
        this.eventListeners[event](data);
      });
    }
  }
}
