import { Component, Input, HostBinding } from '@angular/core';
import { Staff } from 'src/loopback';

@Component({
  selector: 'cm-staff-name-card',
  templateUrl: './staff-name-card.component.html',
  styleUrls: ['./staff-name-card.component.scss'],
})
export class StaffNameCardComponent {

  @Input() staff: Staff;
  @Input() showOffice = false;
  @Input() avatarOnly = false;
  @Input() size: 'medium' | 'small' = 'medium';
  @Input() avatarPosition: 'left' | 'right' = 'left';
  @Input() avatarSizeOverride: number;
  @HostBinding('class.medium') get hostClassMedium() { return this.size === 'medium'; }
  @HostBinding('class.small') get hostClassSmall() { return this.size === 'small'; }
  @HostBinding('class.avatar-left') get hostClassAvatarLeft() { return this.avatarPosition === 'left'; }
  @HostBinding('class.avatar-right') get hostClassAvatarRight() { return this.avatarPosition === 'right'; }
  @HostBinding('class.deleted') get hostClassDeleted() { return this.staff == null; }

  get avatarSize() {
    if (this.avatarSizeOverride) {
      return this.avatarSizeOverride;
    } else if (this.size === 'small') {
      return 32;
    } else if (this.size === 'medium') {
      return 82;
    } else {
      return 144;
    }
  }

  constructor() { }

}
