import { Injector, Type } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Params } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MoveHistory, MoveHistoryApi, Note, Resident, ResidentApi, Room, Staff, StaffApi } from 'src/loopback';
import { residentInclusionDefaults } from '../constants';
import { DialogComponentBase } from '../services/dialog.service';
import { NoteUtilService } from '../services/note-util.service';
import { RoleService } from '../services/role.service';
import { OmitOptional } from '../types';
import { TimelineItem } from './timeline';

export interface CareRecordModel {
  id?: number;
  staffId?: number;
  residentId?: number;
  resident?: Resident;
  note?: Note;
  recordedAt?: Date|string;
}

/**
 * ケア記録編集画面の共通ロジック
 */
export class CareDetailPageTrait<T extends CareRecordModel> extends DialogComponentBase<CareDetailDialogData, T | T[]> {

  titleBase: string;
  id: number;
  resident: Resident;
  residents: Resident[];
  residentNames: string;
  moveHistories: MoveHistory[];
  previousResident: { data: Resident; room: Room };
  nextResident: { data: Resident; room: Room };
  note: Note;
  noteReplies: TimelineItem<'note'>[];
  form: FormGroup<{ [K in keyof OmitOptional<T>]?: FormControl<T[K]> }>;
  staffList$: Observable<Staff[]>;
  routeAfterCreate: any[];
  isDialog: boolean;
  recordInclusionDefaults: any;

  protected residentApi: ResidentApi;
  protected staffApi: StaffApi;
  protected moveHistoryApi: MoveHistoryApi;
  protected roleService: RoleService;
  protected noteUtil: NoteUtilService;
  protected data: CareDetailDialogData;
  protected ref: MatDialogRef<CareDetailPageTrait<T>, T | T[]>;

  inject(injector: Injector) {
    this.residentApi = injector.get(ResidentApi);
    this.staffApi = injector.get(StaffApi);
    this.moveHistoryApi = injector.get(MoveHistoryApi);
    this.roleService = injector.get(RoleService);
    this.noteUtil = injector.get(NoteUtilService);

    try {
      this.data = injector.get(MAT_DIALOG_DATA);
      this.ref = injector.get(MatDialogRef);
    } catch (e) {}

    this.recordInclusionDefaults = [
      'staff',
      {
        relation: 'resident',
        scope: { include: residentInclusionDefaults },
      },
      {
        relation: 'note',
        scope: {
          include: [
            'staff',
            {
              relation: 'replies',
              scope: { include: 'staff' },
            },
          ],
        },
      },
    ];
  }

  beforeFetch(params: Params) {
    const tasks: Observable<any>[] = [];

    this.staffList$ = this.staffApi.find({ where: { officeId: this.roleService.currentUser.officeId } });

    let idParam: number;
    let residentIds: number[];
    let recordedAt;

    if (this.isDialog) {
      const { id, residentId, datetime } = this.data;
      idParam = this.id = id;
      residentIds = residentId == null ? [] : Array.isArray(residentId) ? residentId : [residentId];
      recordedAt = datetime || moment();
    } else {
      idParam = +params['id'];
      residentIds = params['resident'] && params['resident'].split(',').map((id: string) => +id) || [];
      recordedAt = params['datetime'] ? moment(+params['datetime']) : moment();
    }

    this.form.controls.recordedAt.setValue(recordedAt.toDate());

    if (!idParam) {
      // 単一入居者の記録
      if (residentIds.length === 1) {
        this.routeAfterCreate = undefined; // 新規作成後のルートは指定しない（デフォルト：作成した記録の編集画面へ）
        const task = this.residentApi.findById(residentIds[0], {
          include: residentInclusionDefaults,
        }).pipe(
          tap(resident => this.resident = resident),
        );
        tasks.push(task);
      }
      // 一括作成
      else if (residentIds.length > 1) {
        this.routeAfterCreate = ['/care']; // 新規作成後はケア記録一覧へ
        const task = this.residentApi.find({
          where: { id: { inq: residentIds } },
          include: [
            ...residentInclusionDefaults,
            {
              relation: 'insuranceCards',
              scope: {
                order: 'startAt DESC',
                limit: 2,
              },
            },
            {
              relation: 'assessments',
              scope: {
                order: 'recordedAt',
                include: ['adls', 'iadls', 'episodes'],
                limit: 1,
              },
            },
            {
              relation: 'plans',
              scope: {
                order: 'recordedAt',
                include: 'plan2',
                limit: 1,
              },
            },
            'contacts',
            'episodes',
          ],
        }).pipe(
          tap(residents => {
            this.residents = residents;
            this.residentNames = residents.map(r => `${r.lastName} ${r.firstName}`).join(', ');
          }),
        );
        tasks.push(task);
      }
    }

    return tasks.length ? forkJoin(tasks) : of(null);
  }

  setPropertyFromRecord(record?: T) {
    if (record) {
      if (record.note) {
        this.note = record.note;
        this.noteReplies = record.note.replies.map(n => TimelineItem.fromNote(n));
      }

      this.resident = record.resident;
      this.form.controls.recordedAt.setValue(record.recordedAt);
    } else {
      this.form.controls.staffId.setValue(this.roleService.currentUser.id);
    }
  }

  afterSave(result: T | T[]) {
    if (this.isDialog) {
      this.ref.close(result);
    }
  }

  generateTitle(record?: T) {
    if (this.resident) {
      let title = `${this.resident.lastName} ${this.resident.firstName}さんの${this.titleBase}`;

      if (!record) {
        title += '（新規）';
      }

      return title;
    } else if (this.residents) {
      return this.titleBase + 'の一括作成';
    } else {
      let title = this.titleBase;

      if (!record) {
        title += '（新規）';
      }

      return title;
    }
  }

  openNoteReplyEditor() {
    this.noteUtil.openReplyEditor(this.note).subscribe(
      res => {
        this.noteReplies.push(TimelineItem.fromNote(res, true));
      },
    );
  }

}

export interface CareDetailDialogData {
  /** 既存データ編集の場合に指定 */
  id?: number;
  /** [新規作成時のみ] 入居者に紐付くデータの場合に指定 一括の場合は配列 */
  residentId?: number | number[];
  /** [新規作成時のみ] 施設に紐付くデータの場合に指定 */
  officeId?: number;
  /** [新規作成時のみ] 記録日時（イベントの場合は開始日時）初期値 */
  datetime?: moment.Moment;
}

export function TypedCareDetailPageTrait<T>(_model: Type<T>): Type<CareDetailPageTrait<T>> {
  return CareDetailPageTrait as Type<CareDetailPageTrait<T>>;
}
