<mat-toolbar *ngIf="title">{{ title }}</mat-toolbar>
<textarea #textarea="matInput" matInput class="input" [formControl]="control"></textarea>
<mat-toolbar class="page-footer">
  <div class="page-footer-left"></div>
  <div class="page-footer-right">
    <button mat-button (click)="buttonClick.emit()">キャンセル</button>
    <button mat-stroked-button color="primary" [disabled]="control.value.trim() === ''" (click)="buttonClick.emit(control.value)">
      {{ buttonLabel }}
    </button>
  </div>
</mat-toolbar>
