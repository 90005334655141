import { Component, OnInit, Inject, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Staff, StaffApi } from 'src/loopback';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';
import { RoleService } from 'src/app/services/role.service';
import { DialogComponentBase } from 'src/app/services/dialog.service';

@Component({
  selector: 'cm-staff-selector',
  templateUrl: './staff-selector.component.html',
  styleUrls: ['./staff-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaffSelectorComponent extends DialogComponentBase<StaffSelectorDialogData, Staff[]> implements OnInit {

  staffs: Staff[];
  selectedIds: number[];
  @ViewChild('list', { static: true }) list: MatSelectionList;

  get okDisabled() {
    return !this.staffs || this.list.selectedOptions.isEmpty();
  }

  constructor(
    protected ref: MatDialogRef<StaffSelectorComponent, Staff[]>,
    @Inject(MAT_DIALOG_DATA) protected data: StaffSelectorDialogData,
    private staffApi: StaffApi,
    private role: RoleService,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.selectedIds = this.data.selectedIds || [];
    this.staffApi.find({
      where: { officeId: this.role.currentUser.officeId },
      include: ['photo'],
    }).subscribe(
      res => {
        this.staffs = res;
        this.cdRef.markForCheck();
      },
    );
  }

  onOkClick(selection: SelectionModel<MatListOption>) {
    const ids = selection.selected.map(option => option.value as Staff);
    this.ref.close(ids);
  }

}

export interface StaffSelectorDialogData {
  selectedIds: number[];
}
