import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { OptionsService } from './options.service';

export interface YubinBangoAddressData {
  region_id: string;
  region: string;
  locality: string;
  street: string;
  extended: string;
}

/**
 * 郵便番号関連サービス
 * 参照：https://github.com/yubinbango/yubinbango-core
 */
@Injectable({
  providedIn: 'root',
})
export class YubinBangoService {

  private _cache: { [yubin3: string]: { [yubin7: string]: string[] } } = {};
  private _jsonpUrl = 'https://yubinbango.github.io/yubinbango-data/data';
  private _regions: { [id: number]: string } = [];

  constructor(private opt: OptionsService) {
    this._regions = [null].concat(this.opt.prefectures);
  }

  /**
   * 郵便番号から住所を取得する
   * @param yubinBango 郵便番号
   */
  async autoAddress(yubinBango = ''): Promise<YubinBangoAddressData> {
    if (yubinBango) {
      // 全角の数字を半角に変換 ハイフンが入っていても数字のみの抽出
      const a = yubinBango.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248));
      const b = a.match(/\d/g);
      const c = b.join('');
      const yubin7 = c.length === 7 ? c : null;
      // 7桁の数字の時のみ作動
      if (yubin7) {
        return this.fetchAddressData(yubin7);
      } else {
        return this.addrDic([]);
      }
    }
  }

  /**
   * ハイフン無しの郵便番号を有りに置き換える
   * @param control ReactiveFormのコントロール
   */
  normalize(control: AbstractControl) {
    if (control?.value?.match(/^[0-9]{3}[0-9]{4}$/)) {
      control.setValue(control.value.replace(/([0-9]{3})([0-9]{4})/, '$1-$2'));
    }
  }

  private addrDic(addr: string[]): YubinBangoAddressData {
    let [region_id = '', locality = '', street = '', extended = ''] = addr;
    return { region_id, region: this._regions[+region_id], locality, street, extended };
  }

  private jsonp(url: string): Promise<{ [key: string]: string[] }> {
    return new Promise((resolve, reject) => {
      (window as any)['$yubin'] = (data: { [key: string]: string[] }) => resolve(data);
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("type", "text/javascript");
      scriptTag.setAttribute("charset", "UTF-8");
      scriptTag.setAttribute("src", url);
      try {
        document.head.appendChild(scriptTag);
      } catch (e) {
        reject(e);
      }
    });
  }

  private async fetchAddressData(yubin7: string): Promise<YubinBangoAddressData> {
    const yubin3 = yubin7.substring(0, 3);
    // 郵便番号上位3桁でキャッシュデータを確認
    if (yubin3 in this._cache) {
      return this.addrDic(this._cache[yubin3][yubin7] ?? []);
    } else {
      const data = await this.jsonp(`${this._jsonpUrl}/${yubin3}.js`);
      this._cache[yubin3] = data;
      return this.addrDic(data[yubin7] ?? []);
    }
  }

}
