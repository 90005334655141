/* eslint-disable */
import {
  Company,
  Floor,
  Room
} from '../index';

declare var Object: any;
export interface OfficeInterface {
  "code": string;
  "name"?: string;
  "abbr"?: string;
  "kana"?: string;
  "zipCode"?: string;
  "address1"?: string;
  "address2"?: string;
  "phone"?: string;
  "fax"?: string;
  "category"?: number;
  "hasShortStay"?: boolean;
  "status": number;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "updaterId"?: number;
  company?: Company;
  floors?: Floor[];
  rooms?: Room[];
}

export class Office implements OfficeInterface {
  "code": string = '';
  "name": string = '';
  "abbr": string = '';
  "kana": string = '';
  "zipCode": string = '';
  "address1": string = '';
  "address2": string = '';
  "phone": string = '';
  "fax": string = '';
  "category": number = <any>null;
  "hasShortStay": boolean = false;
  "status": number = 1;
  "id": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "updaterId": number = <any>null;
  company?: Company;
  floors?: Floor[];
  rooms?: Room[];
  constructor(data?: Partial<OfficeInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Office`.
   */
  public static getModelName() {
    return "Office";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Office for dynamic purposes.
  **/
  public static factory(data: OfficeInterface): Office{
    return new Office(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Office',
      plural: 'Offices',
      path: 'Offices',
      idName: 'id',
      properties: {
        "code": {
          name: 'code',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "abbr": {
          name: 'abbr',
          type: 'string'
        },
        "kana": {
          name: 'kana',
          type: 'string'
        },
        "zipCode": {
          name: 'zipCode',
          type: 'string'
        },
        "address1": {
          name: 'address1',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "fax": {
          name: 'fax',
          type: 'string'
        },
        "category": {
          name: 'category',
          type: 'number'
        },
        "hasShortStay": {
          name: 'hasShortStay',
          type: 'boolean'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        floors: {
          name: 'floors',
          type: 'Floor[]',
          model: 'Floor',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'officeId'
        },
        rooms: {
          name: 'rooms',
          type: 'Room[]',
          model: 'Room',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'officeId'
        },
      }
    }
  }
}
