import { Component, Inject, INJECTOR, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { TypedCareDetailPageTrait } from 'src/app/classes/care-detail-page-trait';
import { TypedDetailPageTrait } from 'src/app/classes/detail-page-trait';
import { PageBase } from 'src/app/classes/page-base';
import { ScrollContentComponent } from 'src/app/shared/components/scroll-content/scroll-content.component';
import { Mixin } from 'src/app/utils/mixin';
import { MealRecord, MealRecordApi } from 'src/loopback';

@Component({
  selector: 'cm-care-detail-meal',
  templateUrl: './care-detail-meal.component.html',
  styleUrls: ['./care-detail-meal.component.scss'],
})
export class CareDetailMealComponent extends Mixin(
  PageBase,
  TypedDetailPageTrait(MealRecord),
  TypedCareDetailPageTrait(MealRecord),
) implements OnInit {

  isDialog = true;
  preventRedirectAfterCreate = true;
  titleBase = '食事水分記録';
  canGoBack = true;
  model = MealRecord;

  validators = {
    category: [Validators.required],
    staple: [Validators.required, Validators.min(0), Validators.max(100)],
    sidedish: [Validators.required, Validators.min(0), Validators.max(100)],
    soup: [Validators.required, Validators.min(0)],
    drink: [Validators.required, Validators.min(0)],
  };

  validationMessages = {
    category: {
      required: '種別を選択してください。',
    },
    staple: {
      required: '主食を入力してください。',
      min: '主食は0以上で入力してください。',
      max: '主食は100以下で入力してください。',
    },
    sidedish: {
      required: '副食を入力してください。',
      min: '副食は0以上を入力してください。',
      max: '副食は100以下で入力してください。',
    },
    soup: {
      required: '汁物を入力してください。',
      min: '汁物は0以上を入力してください。',
    },
    drink: {
      required: 'お茶類を入力してください。',
      min: 'お茶類は0以上を入力してください。',
    },
  };

  @ViewChild(ScrollContentComponent) content: ScrollContentComponent;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    private mealRecordApi: MealRecordApi,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.init().subscribe(result => {
      this.setPropertyFromRecord(result);
      this.setTitle(this.generateTitle(result));
    });
  }

  setPropertyFromRecord(record?: MealRecord): void {
    super.setPropertyFromRecord(record);

    if (!record) {
      this.form.controls.staple.setValue(0);
      this.form.controls.sidedish.setValue(0);
      this.form.controls.soup.setValue(0);
      this.form.controls.drink.setValue(0);
    }
  }

  fetch(id: number) {
    return this.mealRecordApi.findById(id, {
      include: this.recordInclusionDefaults,
    });
  }

  save(data: MealRecord) {
    if (this.id) {
      return this.mealRecordApi.patchAttributes(this.id, data);
    } else {
      if (this.residents) {
        const arr = this.residents.map(r => {
          return { ...data, residentId: r.id, officeId: r.officeId };
        });
        return this.mealRecordApi.createMany(arr);
      } else {
        data.residentId = this.resident.id;
        data.officeId = this.resident.officeId;
        return this.mealRecordApi.create(data);
      }
    }
  }

}
