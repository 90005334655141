import { Component, OnDestroy, Input, Output, EventEmitter, IterableDiffers, IterableDiffer, DoCheck } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { RoleService } from 'src/app/services/role.service';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { MenuItem } from 'src/app/menu';


/**
 * メニューコンポーネント
 */
@Component({
  selector: 'cm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements DoCheck, OnDestroy {
  /**
   * メニュー設定
   */
  @Input() menuConfig: MenuItem[] = [];

  /**
   * コンパクト表示
   * アイコンだけを表示し、子メニューを展開しない
   */
  @Input() compact = false;

  @Output() itemClick = new EventEmitter<MenuItem>();

  /**
   * 表示用のメニューデータ
   */
  menuItems: MenuItem[];

  menuDiffer: IterableDiffer<MenuItem>;

  /**
   * 現在選択されているメニュー項目
   */
  get currentItem(): MenuItem { return this.currentMenuItem; }
  private currentMenuItem: MenuItem;

  /**
   * subscriptions
   */
  private subscriptions: Subscription[];

  constructor(
    private router: Router,
    private role: RoleService,
    private state: GlobalStateService,
    private iterableDiffers: IterableDiffers,
  ) {
    this.subscriptions = [
      // ナビゲーション終了後に各メニュー項目の状態を更新する
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (this.menuItems) {
            this.updateStateAndNotify();
          }
        }
      }),
      // 権限が変更されたらメニューを作り直す
      this.role.roleChanged.subscribe(
        () => {
          this.menuItems = this.createMenu(this.menuConfig);
        },
      ),
    ];

    this.menuDiffer = this.iterableDiffers.find([]).create();
  }

  ngDoCheck() {
    const diff = this.menuDiffer.diff(this.menuConfig);

    if (diff) {
      this.menuItems = this.createMenu(this.menuConfig);
    }
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * 選択・開閉状態などを更新して現在の選択メニューを通知する
   */
  private updateStateAndNotify() {
    if (this.menuItems) {
      this.updateItemsState(this.menuItems);
      this.state.set('menu.activeLink', this.currentItem);
    }
  }

  /**
   * 設定からメニューを作成
   * @param config メニュー設定の配列
   * @param parent 親メニュー項目
   */
  private createMenu(config: MenuItem[], parent?: MenuItem): MenuItem[] {
    const items: MenuItem[] = [];

    config.forEach(itemConfig => {
      // ログインユーザに指定された権限がない場合は飛ばす
      if (itemConfig.caps && !this.role.canEvery(itemConfig.caps)) {
        return;
      }

      if (typeof itemConfig.condition === 'function' && !itemConfig.condition(this.role.currentUser)) {
        return;
      }

      const item = { ...itemConfig };

      item.paths = parent && parent.paths ? parent.paths.slice(0) : [];
      item.path.split('/').forEach(part => {
        if (part !== '') {
          item.paths.push(part);
        }
      });

      if (item.query) {
        item.paths.push(item.query);
      }

      if (item.children && item.children.length > 0) {
        item.children = this.createMenu(item.children, item);
      }

      // 表示できる子メニューがなかったら親ごと飛ばす
      if (item.children && item.children.length === 0) {
        return;
      }

      items.push(item);
    });

    return items;
  }

  /**
   * 選択・開閉の状態を更新する
   * @param menuItems メニュー項目の配列
   * @param parent 親メニュー
   */
  private updateItemsState(menuItems: MenuItem[], parent?: MenuItem): MenuItem[] {
    return menuItems.map(item => {
      this.checkSelected(item);

      if ((item.selected || item.expanded) && parent) {
        parent.expanded = true;
      }

      if (item.selected) {
        this.currentMenuItem = item;

        if (parent) {
          parent.selected = true;
        }
      }

      if (item.children && item.children.length > 0) {
        item.children = this.updateItemsState(item.children, item);
      }

      return item;
    });
  }

  /**
   * 現在のURLを調べて項目を選択状態として良いかどうか判別する
   * @param item メニュー項目
   */
  private checkSelected(item: MenuItem): MenuItem {
    const [path, queryString] = this.router.url.split(';');
    const urlFragments: (string | URLSearchParams)[] = path.split('/').slice(1);

    if (queryString) {
      const params = new URLSearchParams(queryString);
      urlFragments.push(params);
    }

    item.selected = item.paths.length === urlFragments.length && urlFragments.every((fragment, index) => {
      const pathPart = item.paths[index];
      if (fragment instanceof URLSearchParams && typeof pathPart !== 'string') {
        return Object.keys(pathPart).every(k => fragment.get(k) === pathPart[k]);
      } else {
        return pathPart === fragment;
      }
    });

    return item;
  }

}
