import { Pipe, PipeTransform } from '@angular/core';
import { getWarekiComponent } from 'src/app/utils/date';
import * as moment from 'moment';

@Pipe({
  name: 'warekiDate',
})
export class WarekiDatePipe implements PipeTransform {

  transform(value: moment.MomentInput, mode?: string): any {
    const date = moment(value);
    const wareki = getWarekiComponent(date);

    if (!wareki) {
      return '';
    }

    let nen = wareki.gengo + wareki.year;

    if (mode === 'paren') {
      nen = `${date.year()}（${nen}）`;
    }

    return `${nen}年${wareki.month}月${wareki.date}日`;
  }

}
