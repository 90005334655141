<div mat-dialog-title>エピソードの追加・編集</div>
<div mat-dialog-content [formGroup]="form">
  <mat-form-field class="w-full">
    <mat-label>見出し</mat-label>
    <input matInput formControlName="title">
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>内容</mat-label>
    <textarea matInput rows="5" formControlName="body"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>キャンセル</button>
  <button mat-stroked-button color="primary" (click)="onOkClick()" [disabled]="okDisabled">OK</button>
</div>
