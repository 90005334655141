import { Injectable } from '@angular/core';
import { FileInfo, LoopBackAuth, LoopBackConfig } from '../../loopback';

@Injectable({
  providedIn: 'root',
})
export class FileInfoService {

  constructor(private auth: LoopBackAuth) {}

  getUrl(fileObj: FileInfo): string {
    if (!fileObj) { return ''; }
    const url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
      `/FileInfos/${fileObj.id}/download?access_token=${this.auth.getAccessTokenId()}`;

    return url;
  }
}
