import { Component, OnInit, Inject } from '@angular/core';
import { Note, ReportConfirmation, ReportConfirmationApi } from 'src/loopback';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from 'src/app/services/dialog.service';

@Component({
  selector: 'cm-confirmation-list',
  templateUrl: './confirmation-list.component.html',
  styleUrls: ['./confirmation-list.component.scss'],
})
export class ConfirmationListComponent extends DialogComponentBase<ConfirmationListDialogData, void> implements OnInit {

  confirmations: ReportConfirmation[] = [];

  constructor(
    private confirmationApi: ReportConfirmationApi,
    @Inject(MAT_DIALOG_DATA) protected data: ConfirmationListDialogData,
  ) {
    super();
  }

  ngOnInit() {
    this.confirmationApi.find({
      where: { referenceType: 'Note', referenceId: this.data.record.id },
      include: { relation: 'staff', scope: { include: 'photo' } },
    }).subscribe(res => {
      this.confirmations = res;
    });
  }

}

export interface ConfirmationListDialogData {
  record: Note;
}
