import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import 'chartjs-adapter-moment';
import { environment } from 'src/environments/environment';
import { ErrorHandler, InternalStorage, SDKBrowserModule } from 'src/loopback';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiErrorHandler } from './classes/api-error-handler';
import { SessionStorage } from './classes/session-storage';
import { LoginComponent } from './pages/login/login.component';
import { RoleService } from './services/role.service';
import { SocketService } from './services/socket.service';
import { SharedModule } from './shared/shared.module';

(localeJa[10] as any)[0] = 'y年M月d日';
registerLocaleData(localeJa);

export function initializerFactory(roleService: RoleService) {
  return () => roleService.init().toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SDKBrowserModule.forRoot({
      provide: InternalStorage,
      useClass: SessionStorage,
    }),
    SharedModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ja' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: ApiErrorHandler },
    { provide: APP_INITIALIZER, useFactory: initializerFactory, multi: true, deps: [RoleService] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
