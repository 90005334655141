import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { merge, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Office, Staff } from '../loopback';
import { ScreenSize } from './enums/screen-size.enum';
import { menuConfig } from './menu';
import { GlobalEventService } from './services/global-event.service';
import { GlobalStateService } from './services/global-state.service';
import { RoleService } from './services/role.service';
import { SocketService } from './services/socket.service';
import { UserSettingsService } from './services/user-settings.service';

@Component({
  selector: 'cm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  title = environment.title;
  titleLogo = environment.titleLogo;
  menu = menuConfig;
  sidenavOpened = false;
  sidenavFolded = false;
  forceSidenavHidden = true;
  isMobile = false;
  get currentUser(): Staff { return this.role.currentUser; }
  get currentOffice(): Office { return this.role.currentOffice; }
  loginUsers: Staff[] = [];
  socketSubscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private role: RoleService,
    private state: GlobalStateService,
    private event: GlobalEventService,
    private iconRegistry: MatIconRegistry,
    private breakpoint: BreakpointObserver,
    private socketService: SocketService,
    private userSettings: UserSettingsService,
  ) { }

  ngOnInit() {
    this.breakpoint.observe([
      '(max-width: 599.99px)',
      '(max-width: 959.99px)',
      '(max-width: 1279.99px)',
    ]).subscribe(result => {
      let screenSize = ScreenSize.large;

      if (result.breakpoints['(max-width: 599.99px)']) {
        screenSize = ScreenSize.xsmall;
      } else if (result.breakpoints['(max-width: 959.99px)']) {
        screenSize = ScreenSize.small;
      } else if (result.breakpoints['(max-width: 1279.99px)']) {
        screenSize = ScreenSize.medium;
      }

      this.state.set('screenSize', screenSize);
    });

    this.state.onChanged('screenSize').subscribe(
      change => {
        this.isMobile = change.currentValue === ScreenSize.xsmall;
        if (this.isMobile) {
          this.sidenavOpened = false;
          this.sidenavFolded = false;
        } else {
          this.sidenavOpened = true;
          this.sidenavFolded = JSON.parse(this.userSettings.get('sidenavFolded') || 'false');
        }
      },
    );

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.forceSidenavHidden = (e.urlAfterRedirects === '/login');
      }
      if (e instanceof NavigationStart) {
        if (e.restoredState) {
          this.state.set('enableRouteCache', true);
        } else {
          this.state.set('enableRouteCache', false);
        }
      }
    });

    this.event.on('tokenExpired').subscribe(
      () => this.logout(),
    );

    this.socketSubscriptions.push(merge(
      this.socketService.on('userJoined'),
      this.socketService.on('userLeaved'),
    ).subscribe(
      res => this.loginUsers = res.currentUsers,
    ));

    this.socketSubscriptions.push(merge(
      this.socketService.on('hasNewReport'),
      this.socketService.on('newReport'),
    ).subscribe(
      _ => this.event.trigger('showMenuBadge.report', true),
    ));

    this.iconRegistry.registerFontClassAlias('icomoon').setDefaultFontSetClass('icomoon');
  }

  ngOnDestroy() {
    this.socketSubscriptions.forEach(sub => sub.unsubscribe());
    // HMR時にWebSocketを切断する
    this.socketService.disconnect();
    // オーバーレイ表示中にHMRすると操作不能になるので一旦消す
    const overlayContainers = document.querySelectorAll('.cdk-overlay-container');
    overlayContainers.forEach(container => container.remove());
  }

  toggleSidenav() {
    if (this.isMobile) {
      this.sidenavOpened = !this.sidenavOpened;
    } else {
      this.sidenavFolded = !this.sidenavFolded;
      this.userSettings.set('sidenavFolded', JSON.stringify(this.sidenavFolded));
    }
  }

  logout() {
    this.role.logout().pipe(
      catchError(() => of(null)),
    ).subscribe(
      () => {
        this.router.navigateByUrl('/login');
      },
    );
  }

  onMenuItemClick() {
    if (this.isMobile) {
      this.sidenavOpened = false;
    }
  }
}
