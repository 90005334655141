<div *ngIf="!avatarHidden" class="resident-avatar">
  <div [ngClass]="{male: resident.sex === 1, female: resident.sex === 2}"
    [style.background-image]="resident.photo | fileUrl:'css'"></div>
</div>
<div *ngIf="!nameHidden" class="resident-name-room">
  <div class="resident-name">
    <ruby class="last-name">{{ resident.lastName }}<rt *ngIf="size !== 'small'">{{ resident.lastNameKana }}</rt></ruby>
    <ruby class="first-name">{{ resident.firstName }}<rt *ngIf="size !== 'small'">{{ resident.firstNameKana }}</rt></ruby>
  </div>
  <div *ngIf="room" class="resident-room">
    {{ room.floor.name }} {{ room.name }}
  </div>
</div>
