import { Component, Input } from '@angular/core';
import { Resident } from 'src/loopback';
import { ResidentInfoComponent } from '../../dialogs/resident-info/resident-info.component';
import { TimelineDialogComponent } from '../../dialogs/timeline-dialog/timeline-dialog.component';
import { InsuranceSummary } from 'src/app/classes/insurance-summary';
import { DialogService, DialogWidth } from 'src/app/services/dialog.service';

@Component({
  selector: 'cm-resident-panel-multi',
  templateUrl: './resident-panel-multi.component.html',
  styleUrls: ['./resident-panel-multi.component.scss'],
})
export class ResidentPanelMultiComponent  {

  @Input() residents: Resident[];

  constructor(
    private dialog: DialogService,
  ) { }

  openResidentInfo(resident: Resident) {
    const insuranceCards = InsuranceSummary.fromCards(resident.insuranceCards);

    this.dialog.open(ResidentInfoComponent, {
      data: { resident, insuranceCards },
      size: DialogWidth.xl,
    });
  }

  openNoteTimeline(resident: Resident) {
    this.dialog.open(TimelineDialogComponent, {
      data: { type: 'note', resident },
      size: DialogWidth.lg,
    });
  }

}
