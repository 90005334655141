import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { mergeMapTo } from 'rxjs/operators';
import { InsuranceSummary } from 'src/app/classes/insurance-summary';
import { DialogComponentBase } from 'src/app/services/dialog.service';
import { OptionsService } from 'src/app/services/options.service';
import { InsuranceCard, InsuranceCardApi, Resident, ResidentApi } from 'src/loopback';

class InsuranceCardEditorFormConfig {
  id: number;
  certification: number;
  insuredNumber: string;
  insurerNumber: string;
  startAt: string | Date | moment.Moment;
  expiredAt: string | Date | moment.Moment;
  certifiedAt: string | Date | moment.Moment;
  isPending: boolean;
  isModification: boolean;
}

@Component({
  selector: 'cm-insurance-card-editor',
  templateUrl: './insurance-card-editor.component.html',
  styleUrls: ['./insurance-card-editor.component.scss'],
})
export class InsuranceCardEditorComponent extends DialogComponentBase<InsuranceCardEditorDialogData, InsuranceCard> implements OnInit {

  mode: InsuranceCardEditorMode;
  form: FormGroup<{ [K in keyof InsuranceCardEditorFormConfig]: FormControl<InsuranceCardEditorFormConfig[K]> }>;
  summary: InsuranceSummary;

  get okDisabled() { return this.form.invalid; }
  get detailRequired() {
    return this.mode !== 'renew' && this.mode !== 'modify';
  }

  isSaving = false;

  constructor(
    private formBuilder: FormBuilder,
    public opt: OptionsService,
    private residentApi: ResidentApi,
    private insuranceCardApi: InsuranceCardApi,
    protected ref: MatDialogRef<InsuranceCardEditorComponent, InsuranceCard>,
    @Inject(MAT_DIALOG_DATA) protected data: InsuranceCardEditorDialogData,
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.formBuilder.group<InsuranceCardEditorFormConfig>({
      id: null,
      certification: null,
      insuredNumber: null,
      insurerNumber: null,
      startAt: null,
      expiredAt: null,
      certifiedAt: null,
      isPending: false,
      isModification: false,
    });
    this.mode = this.data.mode;
    this.summary = this.data.summary;

    if (this.mode === 'edit') {
      this.form.reset(this.summary.forEdit);
    } else if (this.summary.next) {
      this.form.reset(this.summary.next);
    }

    if (this.mode === 'renew') {
      this.form.get('startAt').setValue(moment(this.summary.current.expiredAt).add(1, 'day').startOf('day'));
    }

    if (this.mode === 'modify') {
      this.form.get('isModification').setValue(true);
    }

    if (this.mode === 'renew' || this.mode === 'modify') {
      this.form.get('insuredNumber').setValue(this.summary.current.insuredNumber);
      this.form.get('insurerNumber').setValue(this.summary.current.insurerNumber);
      this.form.get('isPending').setValue(true);
    }

    if (this.mode === 'confirm') {
      this.form.get('isPending').setValue(false);
    }

    if (this.detailRequired) {
      const expireValidator: ValidatorFn = control => {
        const expiredAt = moment(control.value);

        if (expiredAt.isSameOrBefore(this.form.value.startAt)) {
          return { expireBeforeStart: true };
        } else if (
          this.mode !== 'edit' && !this.form.value.isModification &&
          expiredAt.isSameOrBefore(this.summary.current && this.summary.current.expiredAt || moment().startOf('day'))
        ) {
          return { expireBeforePrevious: true };
        } else {
          return null;
        }
      };
      this.form.get('expiredAt').setValidators([Validators.required, expireValidator]);
      this.form.get('certification').setValidators(Validators.required);
      this.form.get('insuredNumber').setValidators(Validators.required);
      this.form.get('insurerNumber').setValidators(Validators.required);
    }

    if (this.detailRequired || this.mode === 'modify') {
      const startValidator: ValidatorFn = control => {
        const startAt = moment(control.value);

        if (this.form.value.expiredAt && startAt.isSameOrAfter(this.form.value.expiredAt)) {
          return { startAfterExpire: true };
        } else if (
          this.mode === 'confirm' && this.summary.current &&
          !this.form.value.isModification && startAt.isSameOrBefore(this.summary.current.expiredAt)
        ) {
          return { startBeforePrevious: true };
        } else {
          return null;
        }
      };
      this.form.get('startAt').setValidators([Validators.required, startValidator]);
    }
  }

  save(): Observable<InsuranceCard> {
    const data = this.form.value as InsuranceCard;
    const residentId = this.data.resident.id;
    data.officeId = this.data.resident.officeId;

    if (
      this.mode === 'renew' ||
      this.mode === 'create' ||
      this.mode === 'modify'
    ) {
      if (this.summary.next) {
        delete data.id;
        return this.residentApi.updateByIdInsuranceCards(residentId, this.summary.next.id, data);
      } else {
        return this.residentApi.createInsuranceCards(residentId, data);
      }
    } else {
      const update = this.residentApi.updateByIdInsuranceCards(residentId, data.id, data);

      if (data.isModification && this.summary.current) {
        const realExpiredAt = moment(data.startAt).subtract(1, 'day').toDate();
        return this.insuranceCardApi.patchAttributes(this.summary.current.id, { realExpiredAt }).pipe(mergeMapTo(update));
      } else {
        return update;
      }
    }
  }

  onOkClick() {
    this.isSaving = true;
    this.save().subscribe({
      next: result => this.ref.close(result),
      complete: () => this.isSaving = false,
    });
  }

}

export type InsuranceCardEditorMode = 'create' | 'renew' | 'modify' | 'confirm' | 'edit';

export interface InsuranceCardEditorDialogData {
  mode: InsuranceCardEditorMode;
  summary: InsuranceSummary;
  resident: Resident;
}
