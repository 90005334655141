<div #mainContainer class="editor-main">
  <img #targetImage *ngIf="image" [src]="image" crossorigin="use-credentials" [ngStyle]="viewRect" (load)="onLoadImage()">
  <canvas #editorCanvas [ngStyle]="viewRect"></canvas>
</div>
<mat-toolbar class="page-footer">
  <div class="page-footer-left editor-tools">
    <button mat-button (click)="clear()">クリア</button>
    <mat-button-toggle-group>
      <mat-button-toggle (click)="setColor('black')">
        <mat-icon class="text-black">edit</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle (click)="setColor('red')">
        <mat-icon class="text-[red]">edit</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle (click)="setColor('blue')">
        <mat-icon class="text-[blue]">edit</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle (click)="setColor('lime')">
        <mat-icon class="text-[lime]">edit</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="page-footer-right">
    <button mat-flat-button (click)="cancel()">
      キャンセル
    </button>
    <button mat-flat-button color="primary" (click)="save()">
      <mat-icon>save</mat-icon> 保存
    </button>
  </div>
</mat-toolbar>
