/**
 * ひらがなをカタカナに変換
 * @param str
 * @returns
 */
export function hiraganaToKatakana(str: string) {
  return str.replace(/[\u3041-\u3096]/g, (chr) => {
    return String.fromCharCode(chr.charCodeAt(0) + 0x60);
  });
}

/**
 * カタカナをひらがなに変換
 * @param str
 * @returns
 */
export function katakanaToHiragana(str: string) {
  return str.replace(/[\u30a1-\u30f6]/g, (chr) => {
    return String.fromCharCode(chr.charCodeAt(0) - 0x60);
  });
}

/**
 * 最初の文字を大文字にする
 * @param str
 * @returns
 */
export function ucFirst(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

/**
 * ローマ字表記をカタカナに変換
 * @param str ローマ字表記
 * @returns
 */
export function romaToKatakana(str: string) {
  const replaces: {[roma: string]: string}[] = [
    {
      nn: 'ン', xn: 'ン',
    },
    {
      kya: 'キャ', kyi: 'キィ', kyu: 'キュ', kye: 'キェ', kyo: 'キョ',
      kwa: 'クァ', kwi: 'クィ', kwu: 'クゥ', kwe: 'クェ', kwo: 'クォ',
      gya: 'ギャ', gyi: 'ギィ', gyu: 'ギュ', gye: 'ギェ', gyo: 'ギョ',
      gwa: 'グァ', gwi: 'グィ', gwu: 'グゥ', gwe: 'グェ', gwo: 'グォ',
      sha: 'シャ', shi: 'シ', shu: 'シュ', she: 'シェ', sho: 'ショ',
      sya: 'シャ', syi: 'シィ', syu: 'シュ', sye: 'シェ', syo: 'ショ',
      zya: 'ジャ', zyi: 'ジィ', zyu: 'ジュ', zye: 'ジェ', zyo: 'ジョ',
      jya: 'ジャ', jyi: 'ジィ', jyu: 'ジュ', jye: 'ジェ', jyo: 'ジョ',
      tha: 'テャ', thi: 'ティ', thu: 'テュ', the: 'テェ', tho: 'テョ',
      tya: 'チャ', tyi: 'チィ', tyu: 'チュ', tye: 'チェ', tyo: 'チョ',
      tsa: 'ツァ', tsi: 'ツィ', tsu: 'ツ', tse: 'ツェ', tso: 'ツォ',
      twa: 'トァ', twi: 'トィ', twu: 'トゥ', twe: 'トェ', two: 'トォ',
      cha: 'チャ', chi: 'チ', chu: 'チュ', che: 'チェ', cho: 'チョ',
      dha: 'デャ', dhi: 'ディ', dhu: 'デュ', dhe: 'デェ', dho: 'デョ',
      dya: 'ヂャ', dyi: 'ヂィ', dyu: 'ヂュ', dye: 'ヂェ', dyo: 'ヂョ',
      dwa: 'ドァ', dwi: 'ドィ', dwu: 'ドゥ', dwe: 'ドェ', dwo: 'ドォ',
      nya: 'ニャ', nyi: 'ニィ', nyu: 'ニュ', nye: 'ニェ', nyo: 'ニョ',
      hya: 'ヒャ', hyi: 'ヒィ', hyu: 'ヒュ', hye: 'ヒェ', hyo: 'ヒョ',
      hwa: 'ファ', hwi: 'フィ', hwu: 'フゥ', hwe: 'フェ', hwo: 'フォ',
      fya: 'フャ', fyu: 'フュ', fyo: 'フョ',
      bya: 'ビャ', byi: 'ビィ', byu: 'ビュ', bye: 'ビェ', byo: 'ビョ',
      pya: 'ピャ', pyi: 'ピィ', pyu: 'ピュ', pye: 'ピェ', pyo: 'ピョ',
      vya: 'ヴャ', vyi: 'ヴィ', vyu: 'ヴュ', vye: 'ヴェ', vyo: 'ヴョ',
      mya: 'ミャ', myi: 'ミィ', myu: 'ミュ', mye: 'ミェ', myo: 'ミョ',
      rya: 'リャ', ryi: 'リィ', ryu: 'リュ', rye: 'リェ', ryo: 'リョ',
      wyi: 'ヰ', wye: 'ヱ',
      wha: 'ウァ', whi: 'ウィ', whu: 'ウ', whe: 'ウェ', who: 'ウョ',
      lya: 'ャ', lyi: 'ィ', lyu: 'ュ', lye: 'ェ', lyo: 'ョ',
      ltu: 'ッ', lka: 'ヵ', lke: 'ヶ',
      xya: 'ャ', xyi: 'ィ', xyu: 'ュ', xye: 'ェ', xyo: 'ョ',
      xtu: 'ッ', xka: 'ヵ', xke: 'ヶ',
    },
    {
      ka: 'カ', ki: 'キ', ku: 'ク', ke: 'ケ', ko: 'コ',
      ca: 'カ', ci: 'シ', cu: 'ク', ce: 'セ', co: 'コ',
      qa: 'クァ', qi: 'クィ', qu: 'ク', qe: 'クェ', qo: 'クォ',
      ga: 'ガ', gi: 'ギ', gu: 'グ', ge: 'ゲ', go: 'ゴ',
      sa: 'サ', si: 'シ', su: 'ス', se: 'セ', so: 'ソ',
      za: 'ザ', zi: 'ジ', zu: 'ズ', ze: 'ゼ', zo: 'ゾ',
      ja: 'ジャ', ji: 'ジ', ju: 'ジュ', je: 'ジェ', jo: 'ジョ',
      ta: 'タ', ti: 'チ', tu: 'ツ', te: 'テ', to: 'ト',
      da: 'ダ', di: 'ヂ', du: 'ヅ', de: 'デ', do: 'ド',
      na: 'ナ', ni: 'ニ', nu: 'ヌ', ne: 'ネ', no: 'ノ',
      ha: 'ハ', hi: 'ヒ', hu: 'フ', he: 'ヘ', ho: 'ホ',
      fa: 'ファ', fi: 'フィ', fu: 'フ', fe: 'フェ', fo: 'フォ',
      ba: 'バ', bi: 'ビ', bu: 'ブ', be: 'ベ', bo: 'ボ',
      pa: 'パ', pi: 'ピ', pu: 'プ', pe: 'ペ', po: 'ポ',
      va: 'ヴァ', vi: 'ヴィ', vu: 'ヴ', ve: 'ヴェ', vo: 'ヴォ',
      ma: 'マ', mi: 'ミ', mu: 'ム', me: 'メ', mo: 'モ',
      ya: 'ヤ', yu: 'ユ', ye: 'イェ', yo: 'ヨ',
      ra: 'ラ', ri: 'リ', ru: 'ル', re: 'レ', ro: 'ロ',
      wa: 'ワ', wi: 'ウィ', wu: 'ウ', we: 'ウェ', wo: 'ヲ',
      la: 'ァ', li: 'ィ', lu: 'ゥ', le: 'ェ', lo: 'ォ',
      xa: 'ァ', xi: 'ィ', xu: 'ゥ', xe: 'ェ', xo: 'ォ',
    },
    {
      a: 'ア', i: 'イ', u: 'ウ', e: 'エ', o: 'オ', n: 'ン',
      '-': 'ー',
    },
  ];

  let result = str;

  for (const replace of replaces) {
    const sokuon = Object.keys(replace).filter(k => !/[aiueon-]/.test(k.charAt(0))).map(k => k.charAt(0) + k);

    if (sokuon.length > 0) {
      result = result.replace(new RegExp(sokuon.join('|'), 'g'), match => 'ッ' + replace[match.substring(1)]);
    }

    result = result.replace(new RegExp(Object.keys(replace).join('|'), 'g'), match => replace[match]);
  }

  return result;
}
