import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { scheduleTimelineStart } from 'src/app/constants';
import { DialogComponentBase } from 'src/app/services/dialog.service';
import { PlanWeeklyDetail } from 'src/loopback';

@Component({
  selector: 'cm-scheduled-item-editor',
  templateUrl: './scheduled-item-editor.component.html',
  styleUrls: ['./scheduled-item-editor.component.scss'],
})
export class ScheduledItemEditorComponent extends DialogComponentBase<ScheduledItemEditorDialogData, ScheduledItemEditorDialogResult> implements OnInit {

  form: FormGroup<{
    start: FormControl<number>;
    end: FormControl<number>;
    content: FormControl<string>;
  }>;
  times = Array(25).fill(undefined).map((_, i) => {
    const hour = i + scheduleTimelineStart;
    const label = hour >= 24 ? `翌${hour - 24}:00` : `${hour}:00`;
    return { value: hour, label };
  });
  isAnyTime = false;
  isNew = false;

  get okDisabled() { return this.form.invalid; }

  constructor(
    private formBuilder: FormBuilder,
    protected ref: MatDialogRef<ScheduledItemEditorComponent, ScheduledItemEditorDialogResult>,
    @Inject(MAT_DIALOG_DATA) protected data: ScheduledItemEditorDialogData,
  ) {
    super();
  }

  ngOnInit() {
    if (this.data.item.start === -1) {
      this.isAnyTime = true;
    }
    this.isNew = this.data.isNew;

    this.form = this.formBuilder.group({
      start: [this.data.item.start],
      end: [this.data.item.end],
      content: [this.data.item.content, Validators.required],
    });

    const startTimeValidator: ValidatorFn = control => {
      const start = control.value;
      const end = this.form.value?.end;

      const intersected = this.data.others.some(o => {
        const oStart = parseInt(o.startTime);
        const oEnd = parseInt(o.endTime);
        return oStart <= start && oEnd > start;
      });

      if (start >= end) {
        return { startAfterEnd: true };
      } else if (intersected) {
        return { intersected: true };
      } else {
        return null;
      }
    };

    const endTimeValidator: ValidatorFn = control => {
      const start = this.form.value?.start;
      const end = control.value;

      const intersected = this.data.others.some(o => {
        const oStart = parseInt(o.startTime);
        const oEnd = parseInt(o.endTime);
        return oStart < end && oEnd >= end;
      });

      if (start >= end) {
        return { endBeforeStart: true };
      } else if (intersected) {
        return { intersected: true };
      } else {
        return null;
      }
    };

    if (!this.isAnyTime) {
      this.form.get('start').setValidators([Validators.required, startTimeValidator]);
      this.form.get('end').setValidators([Validators.required, endTimeValidator]);
    }
  }

  onOkClick() {
    this.ref.close({
      action: 'edit',
      item: this.form.value,
    });
  }

  onDeleteClick() {
    this.ref.close({ action: 'delete' });
  }

}

export interface ScheduledItemEditorDialogData {
  item: { start: number, end: number, content?: string };
  isNew: boolean;
  others: PlanWeeklyDetail[];
}

export interface ScheduledItemEditorDialogResult {
  action: 'edit' | 'delete';
  item?: Partial<ScheduledItemEditorDialogData["item"]>;
}
