<div [formGroup]="form" class="flex flex-row gap-2 justify-start items-center h-[24px]">
  <div class="basis-1/2">
    <input matInput #startInput="matDatepickerInput" formControlName="start" [matDatepicker]="startDatepicker"
      [readonly]="true" [max]="form.value.end" class="transparent-input w-full"
      (click)="startDatepicker.open()" (dateChange)="onChange(value)">
    <mat-datepicker #startDatepicker [calendarHeaderComponent]="calendarHeader"></mat-datepicker>
  </div>
  <button *ngIf="!disableClear" mat-icon-button type="button" class="clear-button" (click)="clearStart()">
    <mat-icon>clear</mat-icon>
  </button>
  <span>～</span>
  <div class="basis-1/2">
    <input matInput #endInput="matDatepickerInput" formControlName="end" [matDatepicker]="endDatepicker"
      [readonly]="true" [min]="form.value.start" class="transparent-input w-full"
      (click)="endDatepicker.open()" (dateChange)="onChange(value)">
    <mat-datepicker #endDatepicker [calendarHeaderComponent]="calendarHeader"></mat-datepicker>
  </div>
  <button *ngIf="!disableClear" mat-icon-button type="button" class="clear-button" (click)="clearEnd()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
