<cm-resident-name-card [resident]="resident" [room]="room" class="resident-identity">
</cm-resident-name-card>
<mat-tab-group class="full-screen">
  <mat-tab *ngFor="let name of tabOrder">
    <ng-template matTabLabel>
      <mat-icon class="tab-icon">{{ tabSettings[name].icon }}</mat-icon> {{ tabSettings[name].label }}
    </ng-template>
    <ng-template matTabContent>
      <cm-timeline [compact]="true" [collapsible]="tabSettings[name].timelineCollapsible"
        [hideTime]="tabSettings[name].timelineHideTime" [type]="name" [resident]="resident">
      </cm-timeline>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<button mat-icon-button *ngIf="dismissible" (click)="dismiss.emit()" class="dismiss-button">
  <mat-icon>remove_circle</mat-icon>
</button>
