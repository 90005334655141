import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { Injector, Type } from '@angular/core';
import { Location } from '@angular/common';
import { of } from 'rxjs';

/**
 * ページコンポーネントの共通ロジック
 */
export class PageBase {

  /**
   * ページタイトル
   */
  title: string;

  /**
   * 前のページに戻ることが出来るかどうか
   * できない場合はヘッダに戻るボタンが出ない
   */
  canGoBack = false;

  /**
   * HTMLのtitle要素設定用
   */
  protected titleService: Title;

  protected location: Location;

  inject(injector: Injector) {
    this.titleService = injector.get(Title);
    this.location = injector.get(Location);
  }

  init() {
    this.setTitle(this.title);
    return of(null);
  }

  /**
   * ページタイトルを設定する
   * HTMLのtitle要素には後ろにアプリ名をつける
   * @param title タイトル
   */
  setTitle(title: string) {
    this.title = title;

    const htmlTitle = (title ? `${title} | ` : '') + environment.title;

    this.titleService.setTitle(htmlTitle);
  }

  goBack() {
    this.location.back();
  }
}
