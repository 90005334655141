import { Staff } from "src/loopback";

/**
 * メニュー設定
 */
export const menuConfig: MenuItem[] = [
  { path: 'dashboard', title: 'ダッシュボード', icon: 'dashboard', iconColor: '#303f9f' },
  {
    path: 'care', title: 'ケア記録', caps: ['readCareRecord'], icon: 'assignment', iconColor: '#26a69a',
    condition: (user) => !user.roleGroup.caps['superAdmin'] && !user.office.hasShortStay,
  },
  {
    path: 'care', title: 'ケア記録', caps: ['readCareRecord'], icon: 'assignment', iconColor: '#26a69a',
    condition: (user) => user.roleGroup.caps['superAdmin'] || user.office.hasShortStay,
    children: [
      { path: '', title: '一般居室' },
      { path: '', query: { target: 'short' }, title: 'ショートステイ' },
    ],
  },
  {
    path: 'event', title: 'スケジュール', caps: ['readCareRecord'], icon: 'event_note', iconColor: '#26a69a',
    children: [
      { path: 'office', title: '施設スケジュール' },
      { path: '', title: '利用者スケジュール' },
    ],
  },
  {
    path: 'report', title: '報告書', caps: ['readReport'], icon: 'description', iconColor: '#e64a19', observeBadgeStatus: true,
    children: [
      { path: '', title: '報告書の確認' },
      { path: 'new', title: '報告書の作成', caps: ['createReport'] },
    ],
  },
  {
    path: 'service-plan', title: 'サービス計画管理', caps: ['readServicePlan'], icon: 'assessment', iconColor: '#f9a825',
    children: [
      { path: '', title: 'サービス計画（一般）' },
      {
        path: '', query: { target: 'short' }, title: 'サービス計画（ショート）',
        condition: (user) => user.roleGroup.caps['superAdmin'] || user.office.hasShortStay,
      },
      { path: 'assessment', title: 'アセスメント検索' },
      { path: 'plan', title: '計画書検索' },
    ],
    devideBottom: true,
  },
  {
    path: 'resident', title: '入居者', caps: ['editResident'], icon: 'face', iconColor: '#cccccc',
    children: [
      { path: '', title: '入居者一覧' },
      { path: 'new', title: '入居者新規登録' },
    ],
  },
  {
    path: 'office', title: '施設', caps: ['editOffice'], icon: 'home', iconColor: '#cccccc',
    children: [
      { path: 'my', title: '自施設の情報', caps: ['$office'] },
      { path: '', title: '施設一覧' },
      { path: 'new', title: '施設新規登録', caps: ['superAdmin'] },
    ],
  },
  {
    path: 'user', title: '職員', caps: ['editStaff'], icon: 'person', iconColor: '#cccccc',
    children: [
      { path: '', title: '職員一覧' },
      { path: 'new', title: '新規職員' },
      { path: 'group', title: '権限グループ' },
    ],
  },
  {
    path: 'settings', title: '設定', caps: ['editSettings'], icon: 'settings', iconColor: '#cccccc',
    children: [
      { path: '', query: { category: 'care' }, title: 'ケア記録の設定' },
      { path: '', query: { category: 'service-plan' }, title: 'サービス計画設定' },
      { path: 'company', title: '自社情報設定', caps: ['superAdmin'] },
    ],
  },
];

/**
 * メニュー項目
 */
export interface MenuItem {
  /** 遷移先のルートパス */
  path: string;
  /** メニューでの表示タイトル */
  title: string;
  /** 項目を表示可能なユーザー権限 配列中の全権限を持っている必要がある */
  caps?: string[];
  /** URLに付加するクエリパラメータ */
  query?: { [name: string]: any };
  /**
   * capsとは別に表示可否を判定する関数
   *
   * capsと同時に指定した場合どちらも判定成功する必要がある
   * 引数はログイン中のユーザー
   */
  condition?: (user: Staff) => boolean;
  /** アイコン */
  icon?: string;
  /** アイコンの色 */
  iconColor?: string;
  /** 子メニュー */
  children?: MenuItem[];
  /** [設定不要] ルーターに渡すためのパス設定 */
  paths?: (string | { [name: string]: any })[];
  /** [設定不要] 選択状態 */
  selected?: boolean;
  /** [設定不要] 展開状態 */
  expanded?: boolean;
  /** 外部URL 設定するとpath設定は無効になり外部リンクとなる */
  url?: string;
  /** urlを設定した際のターゲット属性 */
  target?: string;
  /** trueにすると次の項目との間にスペースを空ける */
  devideBottom?: boolean;
  /** 'rounded'に設定すると角丸スタイルになる */
  appearance?: string;
  /** 通知バッジの有無 */
  observeBadgeStatus?: boolean;
}
