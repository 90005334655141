<div *ngIf="type === 'note' && showAddNoteButton" class="timeline-add-note">
  <button mat-fab (click)="openNoteEditor()" matTooltip="新しいノートを追加">
    <mat-icon>add</mat-icon>
  </button>
</div>
<ng-container *ngIf="type === 'note' && enableNoteSearch">
  <div [formGroup]="noteSearchForm" class="timeline-note-search flex flex-row flex-wrap gap-1">
    <div class="flex flex-row gap-4 items-center py-1 px-2 bg-neutral-200 rounded-md">
      <div class="text-neutral-600">投稿者</div>
      <select formControlName="staffId" class="transparent-input">
        <option value="">指定しない</option>
        <option *ngFor="let staff of staffList$ | async" [value]="staff.id">
          {{ staff.lastName }} {{ staff.firstName }}
        </option>
      </select>
    </div>
    <div class="flex flex-row gap-4 items-center py-1 px-2 bg-neutral-200 rounded-md">
      <div class="text-neutral-600">表示期間</div>
      <cm-date-interval-input formControlName="period" [disableClear]="true" class="w-64">
      </cm-date-interval-input>
    </div>
  </div>
  <mat-divider></mat-divider>
</ng-container>
<div class="timeline-bg-line"></div>
<cm-scroll-content #scroller class="page-content">
  <div *ngFor="let item of visibleItems; first as isFirst" class="timeline-day"
    [ngClass]="{'timeline-sticky': !item.date}" (click)="confirm(item)">
    <div *ngIf="item.firstOfDay || isFirst" class="timeline-day-header">
      <div class="timeline-day-date">{{ (item.date.toDate() | date:'M月d日(EEE)') || '固定された投稿' }}</div>
      <div class="timeline-day-divider"></div>
    </div>
    <div class="timeline-item" [ngClass]="item.type" [class.no-resident]="!item.record.residentId">
      <div *ngIf="!compact" class="timeline-left">
        <div *ngIf="!hideTime" class="timeline-item-time">
          <span *ngIf="(item | isTimelineItemType:'note') && !confirmed(item)" class="edit-indicator dirty"></span>
          <ng-container *ngIf="!(item | isTimelineItemType:'schedule') || item.record.withTime">
            {{ item.date.toDate() | date:'shortTime' }}
          </ng-container>
        </div>
      </div>
      <div class="timeline-line">
        <div class="timeline-item-icon rounded-icon" [ngClass]="{medium: compact}" matBadge=" "
          matBadgeColor="warn">
          <mat-icon>{{ typeBadges[item.type].icon }}</mat-icon>
        </div>
      </div>
      <div class="timeline-item-content" [ngClass]="{collapsible: collapsible}">
        <div class="timeline-item-header" (click)="item['_bodyExpanded'] = !item['_bodyExpanded']">
          <ng-container *ngIf="compact">
            <span *ngIf="(item | isTimelineItemType:'note') && !confirmed(item)" class="edit-indicator dirty"></span>
            <span *ngIf="!hideTime"
              class="timeline-item-time">{{ item.date.toDate() | date:'shortTime' }}</span>
          </ng-container>
          <span *ngIf="(item | isTimelineItemType:'schedule') && item.record.endAt != null" class="timeline-item-type">
            〜
            <ng-container *ngIf="item.record.startAt | isSameTime:item.record.endAt:'day';else time2">
              {{ item.record.endAt | date:'HH:mm' }}
            </ng-container>
            <ng-template #time2>
              {{ item.record.endAt | date:'M月d日 HH:mm' }}
            </ng-template>
          </span>
          <span *ngIf="typeBadges[item.type].label as typeLabel" class="timeline-item-type">
            {{ typeLabel }}
          </span>
          <small class="timeline-item-creator">
            <mat-icon [inline]="true">edit</mat-icon>
            <ng-container *ngIf="item.staff; else itemStaffEmpty">
              {{ item.staff.lastName }} {{ item.staff.firstName }}
            </ng-container>
            <ng-template #itemStaffEmpty>(削除された職員)</ng-template>
          </small>
          <small *ngIf="!item.date" class="timeline-item-creator">
            <mat-icon [inline]="true">access_time</mat-icon>
            {{ item.date.toDate() | date:'M月d日(EEE) H:mm' }}
          </small>
          <button *ngIf="canEdit(item)" mat-button (click)="$event.stopPropagation();edit(item)"
            class="edit-button">編集</button>
          <button *ngIf="canDelete(item)" mat-button color="warn"
            (click)="$event.stopPropagation();delete(item)" class="edit-button">削除</button>
        </div>
        <div *ngIf="!resident && item.record.resident as resident" class="timeline-item-resident">
          <cm-resident-name-card [resident]="resident" [room]="resident.moveHistories[0]?.room"
            size="small"></cm-resident-name-card>
        </div>
        <div class="timeline-item-body"
          [@tableExpand]="!collapsible || item['_bodyExpanded'] ? 'expanded' : 'collapsed'">
          <cm-timeline-item-content [item]="item" [compact]="compact" [settings]="settings">
          </cm-timeline-item-content>
        </div>
        <div class="timeline-item-action" *ngIf="item | isTimelineItemType:'note'">
          <div class="left">
            <small class="timeline-item-confirmation-display"
              (click)="openConfirmationList(item)">
              <mat-icon inline>check_circle</mat-icon> {{ $any(item.record)._confirmationsCount }}人が確認済み
            </small>
          </div>
          <div class="right">
            <button *ngIf="item.related" mat-icon-button
              (click)="item['_relatedExpanded'] = !item['_relatedExpanded']" matTooltip="関連記録を見る">
              <mat-icon>assignment</mat-icon>
            </button>
            <button mat-icon-button (click)="openNoteReplyEditor(item)"
              matTooltip="返信">
              <mat-icon>reply</mat-icon>
            </button>
          </div>
        </div>
        <div *ngIf="item.related" class="timeline-item-related"
          [@tableExpand]="item['_relatedExpanded'] ? 'expanded' : 'collapsed'">
          <div class="timeline-item-related-content" [ngClass]="item.related.type">
            <div class="flex flex-row gap-2 justify-start items-center" class="mb-4">
              <div class="timeline-item-icon rounded-icon medium">
                <mat-icon>{{ typeBadges[item.related.type].icon }}</mat-icon>
              </div>
              <span *ngIf="typeBadges[item.related.type].label as typeLabel"
                class="timeline-item-type">
                {{ typeLabel }}
              </span>
            </div>
            <cm-timeline-item-content [item]="item.related" [compact]="compact"
              [settings]="settings">
            </cm-timeline-item-content>
          </div>
        </div>
        <cm-timeline-note-replies *ngIf="item | isTimelineItemType:'note'" [items]="item.replies"
          class="timeline-item-replies" (edit)="edit($event)"
          (delete)="deleteNoteReply(item, $event)"></cm-timeline-note-replies>
      </div>
    </div>
  </div>
</cm-scroll-content>

<mat-toolbar class="page-footer">
  <div class="page-footer-left"></div>
  <div class="page-footer-right">
    <mat-paginator [hidePageSize]="true" [pageSize]="50" (page)="scroller.scrollToTop()">
    </mat-paginator>
  </div>
</mat-toolbar>
