import { Injectable } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { mergeMap, tap, catchError, mapTo } from 'rxjs/operators';

import { GlobalStateService } from './global-state.service';

import { Staff, StaffApi, RoleGroup, Company, Office } from '../../loopback';
import { SocketService } from './socket.service';

/**
 * 権限関連サービス
 */
@Injectable({
  providedIn: 'root',
})
export class RoleService {
  /**
   * ログインユーザー
   */
  get currentUser() { return this.user; }
  private user: Staff;

  /**
   * ログインユーザーの権限グループ
   */
  private role: RoleGroup;

  /**
   * ログインユーザーの所属会社
   */
  get currentCompany(): Company { return this.company; }
  private company: Company;

  /**
   * ログインユーザーの所属施設
   */
  get currentOffice(): Office { return this.office; }
  private office: Office;

  /**
   * 権限変更の通知
   */
  get roleChanged() { return this.roleChangeSubject.asObservable(); }
  private roleChangeSubject = new Subject<RoleGroup>();

  constructor(
    private state: GlobalStateService,
    private staffApi: StaffApi,
    private socketService: SocketService,
  ) {}

  init(): Observable<Staff> {
    return this.resolve();
  }

  /**
   * ログイン処理
   * 管理側アクセス権限がなければ失敗する
   * @param credentials ユーザー名/パスワード
   */
  login(credentials: any): Observable<Staff> {
    return this.staffApi.login(credentials).pipe(
      mergeMap(() => this.resolve()),
      mergeMap(result => {
        if (!result) {
          return throwError('Cannot read user data');
        }

        return of(result);
      }),
    );
  }

  /**
   * ログアウト処理
   */
  logout(): Observable<any> {
    return this.staffApi.logout().pipe(
      catchError(() => of(null)),
      tap(() => {
        this.clear();
        this.socketService.disconnect();
      }),
    );
  }

  /**
   * ユーザーIDから権限グループと会社を取得する
   * @param userId ユーザーID
   */
  resolve(): Observable<Staff> {
    return this.staffApi.me(['roleGroup', 'company', 'office', 'photo']).pipe(
      mergeMap(res => {
        return this.socketService.connect().pipe(mapTo(res));
      }),
      tap(res => {
        if (!this.user || this.user.id !== res.id) {
          this.user = res;
          this.state.set('currentUser', res);
        }

        if (!this.role || this.role.id !== res.roleGroup.id) {
          this.role = res.roleGroup;
          this.roleChangeSubject.next(res.roleGroup);
        }

        this.company = res.company;
        this.office = res.office;
      }),
      catchError(() => {
        this.clear();
        return of(null);
      }),
    );
  }

  clear() {
    this.user = undefined;
    this.role = undefined;
    this.company = undefined;
    this.roleChangeSubject.next(undefined);
    this.state.set('currentUser', undefined);
  }

  /**
   * ログインユーザーが指定した権限を持っているか調べる
   * @param cap 権限
   */
  can(cap: string): boolean {
    if (!this.role) {
      return false;
    }

    if (cap === '$company') {
      return this.currentUser.officeId === null;
    }

    if (cap === '$office') {
      return this.currentUser.officeId !== null;
    }

    return this.role.caps[cap];
  }

  /**
   * ログインユーザーが指定した権限を全て持っているか調べる
   * @param caps 権限の配列
   */
  canEvery(caps: string[]): boolean {
    if (!this.role) {
      return false;
    }

    return caps.every(cap => this.can(cap));
  }

  /**
   * ログインユーザーが指定した権限の何れかを持っているか調べる
   * @param caps 権限の配列
   */
  canSome(caps: string[]): boolean {
    return caps.some(cap => this.can(cap));
  }

}
