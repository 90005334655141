import { Component, Inject, INJECTOR, Injector, OnInit, ViewChild } from '@angular/core';
import { mapTo, mergeMap, tap } from 'rxjs/operators';
import { TypedCareDetailPageTrait } from 'src/app/classes/care-detail-page-trait';
import { TypedDetailPageTrait } from 'src/app/classes/detail-page-trait';
import { PageBase } from 'src/app/classes/page-base';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { ScrollContentComponent } from 'src/app/shared/components/scroll-content/scroll-content.component';
import { Mixin } from 'src/app/utils/mixin';
import { BathingRecord, BathingRecordApi } from 'src/loopback';

@Component({
  selector: 'cm-care-detail-bathing',
  templateUrl: './care-detail-bathing.component.html',
  styleUrls: ['./care-detail-bathing.component.scss'],
})
export class CareDetailBathingComponent extends Mixin(
  PageBase,
  TypedDetailPageTrait(BathingRecord),
  TypedCareDetailPageTrait(BathingRecord),
) implements OnInit {

  isDialog = true;
  preventRedirectAfterCreate = true;
  titleBase = '入浴記録';
  canGoBack = true;
  model = BathingRecord;
  useType = false;
  types: string[] = [];

  @ViewChild(ScrollContentComponent) content: ScrollContentComponent;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    private bathingRecordApi: BathingRecordApi,
    private appSettings: AppSettingsService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.init().pipe(
      tap(result => {
        this.setPropertyFromRecord(result);
        this.setTitle(this.generateTitle(result));
      }),
      mergeMap(result => {
        const officeId = this.resident?.officeId ?? this.residents[0].officeId;

        return this.appSettings.getAll(officeId).pipe(
          tap(settings => {
            this.useType = settings['care-bathing-useType'] || false;
            this.types = settings['care-bathing-type'] || [];
          }),
          mapTo(result),
        );
      }),
    ).subscribe();
  }

  fetch(id: number) {
    return this.bathingRecordApi.findById(id, {
      include: this.recordInclusionDefaults,
    });
  }

  save(data: BathingRecord) {
    if (this.id) {
      return this.bathingRecordApi.patchAttributes(this.id, data);
    } else {
      data.isTook = true;

      if (this.residents) {
        const arr = this.residents.map(r => {
          return { ...data, residentId: r.id, officeId: r.officeId };
        });
        return this.bathingRecordApi.createMany(arr);
      } else {
        data.residentId = this.resident.id;
        data.officeId = this.resident.officeId;
        return this.bathingRecordApi.create(data);
      }
    }
  }

}
