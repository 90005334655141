import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  constructor(
    private matDialog: MatDialog,
  ) { }

  open<D, R>(component: ComponentType<DialogComponentBase<D, R>>, config?: MatDialogConfig<D> & {size?: DialogWidth}) {
    if (config.size) {
      config.width = '99.999%';
      config.maxWidth = config.size;
    }

    return this.matDialog.open<DialogComponentBase<D, R>, D, R>(component, config);
  }

  closeAll() {
    this.matDialog.openDialogs.forEach(ref => ref.close());
  }
}

/**
 * ダイアログ用コンポーネントの基本クラス
 *
 * DialogService.openで使用するコンポーネントはこのクラスを継承する
 * D = ダイアログに渡すデータの型
 * R = ダイアログからの返り値の型
 */
export abstract class DialogComponentBase<D, R> {
  protected data?: D;
  protected ref?: MatDialogRef<DialogComponentBase<D, R>, R>;
}

export enum DialogWidth {
  sm = '360px',
  md = '420px',
  lg = '680px',
  xl = '980px',
}
