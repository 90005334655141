import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { DialogComponentBase } from 'src/app/services/dialog.service';
import { ExcretionRecord } from 'src/loopback';

@Component({
  selector: 'cm-excretion-template-selector',
  templateUrl: './excretion-template-selector.component.html',
  styleUrls: ['./excretion-template-selector.component.scss'],
})
export class ExcretionTemplateSelectorComponent extends DialogComponentBase<ExcretionTemplateSelectorDialogData, ExcretionRecord> implements OnInit {

  useFailed = false;
  useImplements = false;
  columns: string[] = [];
  templates: ExcretionRecord[] = [];

  constructor(
    private appSettings: AppSettingsService,
    protected ref: MatDialogRef<ExcretionTemplateSelectorComponent, ExcretionRecord>,
    @Inject(MAT_DIALOG_DATA) protected data: ExcretionTemplateSelectorDialogData,
  ) {
    super();
  }

  ngOnInit() {
    this.appSettings.getAll(this.data.officeId).subscribe(settings => {
      this.useFailed = settings['care-excretion-useFailed'];
      this.useImplements = settings['care-excretion-useImplement'];
      this.templates = settings['care-excretion-template'];
      this.columns = []
        .concat(this.useFailed ? 'isFailed' : [])
        .concat(this.useImplements ? 'implement' : [])
        .concat(['urineDegree', 'fecesDegree', 'memo']);
    });
  }

  selectTemplate(template: ExcretionRecord) {
    this.ref.close(template);
  }

}


export interface ExcretionTemplateSelectorDialogData {
  officeId: number;
}
