import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * イベントアグリゲーターサービス
 *
 * モジュールやコンポーネントを跨いで値を受け渡したい場合に使用
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalEventService {

  private subjects: {[name: string]: Subject<any> } = {};

  /**
   * イベントの購読
   *
   * @param event イベント名
   * @returns
   */
  on(event: string): Observable<any> {
    if (this.subjects[event] === undefined) {
      this.subjects[event] = new Subject<any>();
    }

    return this.subjects[event].asObservable();
  }

  /**
   * イベントの発行
   *
   * @param event イベント名
   * @param value 値
   * @returns
   */
  trigger(event: string, value: any) {
    const subject = this.subjects[event];

    if (!subject) {
      return;
    }

    subject.next(value);
  }

}
