<div mat-dialog-title>
  {{ resident.lastName }} {{ resident.firstName }}さん{{ type === 'note' ? 'についてのノート' : 'のケア記録' }}
</div>
<div mat-dialog-content>
  <cm-timeline [items]="records" [type]="type" [resident]="resident" [explicitMode]="explicitMode"
    [compact]="compact" [showAddNoteButton]="true" [enableNoteSearch]="true"></cm-timeline>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>閉じる</button>
</div>
