<div mat-dialog-content>
  <div class="resident-panel mb-4">
    <cm-resident-name-card [resident]="resident" [room]="room" [size]="nameCardSize" [avatarHidden]="isOffline"
      class="resident-identity"></cm-resident-name-card>
    <mat-divider [vertical]="true"></mat-divider>
    <dl class="grid-dl resident-summary">
      <dt>年齢</dt>
      <dd>
        {{ resident.birthday | age }}歳 <small>({{ resident.birthday | warekiDate }}生)</small>
        <span *ngIf="isBirthday"> 🎂</span>
      </dd>
      <dt>要介護度</dt>
      <dd>
        {{ (insuranceSummary.current?.certification | optionLabel:'insuranceCertification') || '-' }}
      </dd>
      <dt>認定有効期限</dt>
      <dd>
        {{ (insuranceSummary.current?.startAt | warekiDate:'paren') || '-' }} 〜<br>
        {{ (insuranceSummary.current?.expiredAt | warekiDate:'paren') || '-' }}
        <mat-icon *ngIf="!isOffline && insuranceSummary.status !== 'default'"
          [matTooltip]="insuranceSummary.tooltip" class="insurance-status-icon"
          [ngClass]="insuranceSummary.status">
          {{ insuranceSummary.status === 'pending' ? 'info' : 'warning' }}
        </mat-icon>
      </dd>
    </dl>
  </div>
  <mat-tab-group class="filled">
    <mat-tab label="連絡先">
      <div class="card-container flex flex-row flex-wrap">
        <div *ngFor="let contact of resident.contacts" class="card-wrapper basis-full sm:basis-1/2 md:basis-1/3">
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-icon mat-card-avatar class="text-avatar">
                {{ contactTypeIcons[contact.type] }}
              </mat-icon>
              <mat-card-title>{{ contact.name }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="flex flex-row gap-2 justify-start items-stretch mb-4">
                <mat-icon [inline]="true">phone</mat-icon><span>{{ contact.phone }}</span>
                <ng-container *ngIf="contact.mobilePhone">
                  <mat-divider [vertical]="true"></mat-divider>
                  <mat-icon [inline]="true">smartphone</mat-icon>
                  <span>{{ contact.mobilePhone }}</span>
                </ng-container>
              </div>
              <p>{{ contact.remarks }}</p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="フェイスシート">
      <div class="card-container flex flex-row flex-wrap">
        <div class="card-wrapper basis-full sm:basis-1/2">
          <mat-card appearance="outlined" class="flex-1">
            <mat-card-header>
              <mat-icon mat-card-avatar class="text-avatar">comment</mat-icon>
              <mat-card-title>行きたい場所</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>{{ resident.placeWantToGo }}</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="card-wrapper basis-full sm:basis-1/2">
          <mat-card appearance="outlined" class="flex-1">
            <mat-card-header>
              <mat-icon mat-card-avatar class="text-avatar">comment</mat-icon>
              <mat-card-title>日々の習慣や日課</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>{{ resident.routine }}</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="card-wrapper basis-full sm:basis-1/2">
          <mat-card appearance="outlined" class="flex-1">
            <mat-card-header>
              <mat-icon mat-card-avatar class="text-avatar">comment</mat-icon>
              <mat-card-title>好きな食べ物</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>{{ resident.dietaryHabit }}</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="card-wrapper basis-full sm:basis-1/2">
          <mat-card appearance="outlined" class="flex-1">
            <mat-card-header>
              <mat-icon mat-card-avatar class="text-avatar">comment</mat-icon>
              <mat-card-title>趣味・興味・関心</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>{{ resident.interest }}</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div *ngFor="let episode of resident.episodes"
          class="card-wrapper basis-full sm:basis-1/2">
          <mat-card appearance="outlined" class="flex-1">
            <mat-card-header>
              <mat-icon mat-card-avatar class="text-avatar">comment</mat-icon>
              <mat-card-title>{{ episode.title }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>{{ episode.body }}</p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="医療情報">
      <div class="my-4">
        <h2 class="section-header mat-h2">一般的な医療情報</h2>
        <div class="section-content card-container flex flex-row flex-wrap">
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined" class="flex-1">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">info</mat-icon>
                <mat-card-title>現疾患・既往歴</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ resident.medicalHistory }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined" class="flex-1">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">info</mat-icon>
                <mat-card-title>服薬に関する注意</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ resident.medicinePrecaution }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined" class="flex-1">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">info</mat-icon>
                <mat-card-title>その他の医療情報</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ resident.otherMedicalInfo }}</p>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <h2 class="section-header mat-h2">入居時の認知症の情報</h2>
        <div class="section-content card-container flex flex-row flex-wrap">
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined" class="flex-1">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">info</mat-icon>
                <mat-card-title>認知症状ありと確認した書類</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ resident.dementiaDocument }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined" class="flex-1">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">info</mat-icon>
                <mat-card-title>診断名・原因疾患</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ resident.dementiaFactor }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined" class="flex-1">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">info</mat-icon>
                <mat-card-title>認知症状について</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ resident.dementiaCondition }}</p>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="アセスメント" *ngIf="assessment">
      <div class="my-4">
        <h2 class="section-header mat-h2">ADL</h2>
        <div class="section-content card-container flex flex-row flex-wrap">
          <ng-container *ngFor="let item of assessment.adls">
            <div *ngIf="!item.hiddenInPopup" class="card-wrapper basis-full sm:basis-1/2">
              <mat-card appearance="outlined">
                <mat-card-header>
                  <mat-icon mat-card-avatar class="text-avatar">assessment</mat-icon>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <p>{{ item.body }}</p>
                </mat-card-content>
              </mat-card>
            </div>
          </ng-container>
        </div>
        <h2 class="section-header mat-h2">整容・家事(IADL)</h2>
        <div class="section-content card-container flex flex-row flex-wrap">
          <ng-container *ngFor="let item of assessment.iadls">
            <div *ngIf="!item.hiddenInPopup" class="card-wrapper basis-full sm:basis-1/2">
              <mat-card appearance="outlined">
                <mat-card-header>
                  <mat-icon mat-card-avatar class="text-avatar">assessment</mat-icon>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <p>{{ item.body }}</p>
                </mat-card-content>
              </mat-card>
            </div>
          </ng-container>
        </div>
        <h2 class="section-header mat-h2">認知症状</h2>
        <div class="section-content card-container flex flex-row flex-wrap">
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">assessment</mat-icon>
                <mat-card-title>昔の思い出</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ assessment.oldMemory }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">assessment</mat-icon>
                <mat-card-title>最近の思い出</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ assessment.recentMemory }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">assessment</mat-icon>
                <mat-card-title>物事の手順</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ assessment.procedualMemory }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">assessment</mat-icon>
                <mat-card-title>場所についての見当識</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ assessment.orientationPlace }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">assessment</mat-icon>
                <mat-card-title>時間についての見当識</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ assessment.orientationTime }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">assessment</mat-icon>
                <mat-card-title>人についての見当識</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ assessment.orientationPerson }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div *ngFor="let item of assessment.episodes"
            class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">assessment</mat-icon>
                <mat-card-title>{{ item.title }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ item.body }}</p>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="サービス計画" *ngIf="plan && settings">
      <div class="my-4">
        <h2 class="section-header mat-h2">サービス計画(1)</h2>
        <div class="section-content card-container flex flex-row flex-wrap">
          <div *ngIf="settings['plan-plan1-subjects'].intention" class="card-wrapper basis-full sm:basis-1/2 md:basis-1/3">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">check</mat-icon>
                <mat-card-title>利用者及び家族の生活に対する意向</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ plan.plan1.intention }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div *ngIf="settings['plan-plan1-subjects'].advice" class="card-wrapper basis-full sm:basis-1/2 md:basis-1/3">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">check</mat-icon>
                <mat-card-title>介護認定審査会の意見及びサービスの種類</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ plan.plan1.advice }}</p>
              </mat-card-content>
            </mat-card>
          </div>
          <div *ngIf="settings['plan-plan1-subjects'].policy" class="card-wrapper basis-full sm:basis-1/2 md:basis-1/3">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">check</mat-icon>
                <mat-card-title>総合的な援助の方針</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ plan.plan1.policy }}</p>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <h2 class="section-header mat-h2">サービス計画(2)</h2>
        <div class="section-content card-container flex flex-row flex-wrap">
          <div *ngFor="let item of plan.plan2" class="card-wrapper basis-full sm:basis-1/2">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-icon mat-card-avatar class="text-avatar">check</mat-icon>
                <mat-card-title>{{ item.task }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <h3 class="mat-h3">
                  <ng-container *ngIf="settings['plan-plan2-useShortTermGoal']">長期</ng-container>目標
                </h3>
                <p>
                  {{ item.longTermGoal }}
                  <span
                    *ngIf="settings['plan-plan2-useGoalPeriod']">({{ item.longTermGoalPeriod }})</span>
                </p>
                <ng-container *ngIf="settings['plan-plan2-useShortTermGoal']">
                  <h3 class="mat-h3">短期目標</h3>
                  <p>
                    {{ item.shortTermGoal }}
                    <span
                      *ngIf="settings['plan-plan2-useGoalPeriod']">({{ item.longTermGoalPeriod }})</span>
                  </p>
                </ng-container>
                <h3 class="mat-h3">サービス内容</h3>
                <p>{{ item.service }}</p>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="ケア記録" *ngIf="isOffline">
      <cm-timeline type="careHistory" [resident]="resident" [items]="histories"></cm-timeline>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions>
  <button *ngIf="!isOffline && offlineDataKey" mat-stroked-button color="warn" (click)="deleteOfflineData()">
    <mat-icon>remove</mat-icon> 持ち出し用データを削除
  </button>
  <button *ngIf="!isOffline" mat-stroked-button (click)="saveOfflineData()">
    <mat-icon>archive</mat-icon> 持ち出し用に保存
  </button>
  <button mat-stroked-button mat-dialog-close>閉じる</button>
</div>
