/* eslint-disable */
import {
  Resident,
  Staff,
  Company,
  Office
} from '../index';

declare var Object: any;
export interface CareHistoryInterface {
  "recordedAt"?: Date|string;
  "id"?: number;
  "recordId"?: number;
  "recordType"?: string;
  "residentId"?: number;
  "staffId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "updaterId"?: number;
  record?: any;
  resident?: Resident;
  staff?: Staff;
  company?: Company;
  office?: Office;
}

export class CareHistory implements CareHistoryInterface {
  "recordedAt": Date|string = <any>null;
  "id": number = <any>null;
  "recordId": number = <any>null;
  "recordType": string = '';
  "residentId": number = <any>null;
  "staffId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "updaterId": number = <any>null;
  record?: any;
  resident?: Resident;
  staff?: Staff;
  company?: Company;
  office?: Office;
  constructor(data?: Partial<CareHistoryInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CareHistory`.
   */
  public static getModelName() {
    return "CareHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CareHistory for dynamic purposes.
  **/
  public static factory(data: CareHistoryInterface): CareHistory{
    return new CareHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CareHistory',
      plural: 'CareHistories',
      path: 'CareHistories',
      idName: 'id',
      properties: {
        "recordedAt": {
          name: 'recordedAt',
          type: 'Date|string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "recordId": {
          name: 'recordId',
          type: 'number'
        },
        "recordType": {
          name: 'recordType',
          type: 'string'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        record: {
          name: 'record',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'recordId',
          keyTo: 'id'
        },
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
      }
    }
  }
}
