/* eslint-disable */
import {
  Staff,
  Resident,
  Company,
  Office,
  ReportConfirmation
} from '../index';

declare var Object: any;
export interface NoteInterface {
  "content"?: string;
  "id"?: number;
  "staffId"?: number;
  "residentId"?: number;
  "parentId"?: number;
  "relatedRecordId"?: number;
  "relatedRecordType"?: string;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  staff?: Staff;
  resident?: Resident;
  parent?: Note;
  replies?: Note[];
  relatedRecord?: any;
  company?: Company;
  office?: Office;
  confirmations?: ReportConfirmation[];
}

export class Note implements NoteInterface {
  "content": string = '';
  "id": number = <any>null;
  "staffId": number = <any>null;
  "residentId": number = <any>null;
  "parentId": number = <any>null;
  "relatedRecordId": number = <any>null;
  "relatedRecordType": string = '';
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  staff?: Staff;
  resident?: Resident;
  parent?: Note;
  replies?: Note[];
  relatedRecord?: any;
  company?: Company;
  office?: Office;
  confirmations?: ReportConfirmation[];
  constructor(data?: Partial<NoteInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Note`.
   */
  public static getModelName() {
    return "Note";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Note for dynamic purposes.
  **/
  public static factory(data: NoteInterface): Note{
    return new Note(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Note',
      plural: 'Notes',
      path: 'Notes',
      idName: 'id',
      properties: {
        "content": {
          name: 'content',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "parentId": {
          name: 'parentId',
          type: 'number'
        },
        "relatedRecordId": {
          name: 'relatedRecordId',
          type: 'number'
        },
        "relatedRecordType": {
          name: 'relatedRecordType',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
      },
      relations: {
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        parent: {
          name: 'parent',
          type: 'Note',
          model: 'Note',
          relationType: 'belongsTo',
                  keyFrom: 'parentId',
          keyTo: 'id'
        },
        replies: {
          name: 'replies',
          type: 'Note[]',
          model: 'Note',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'parentId'
        },
        relatedRecord: {
          name: 'relatedRecord',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'relatedRecordId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        confirmations: {
          name: 'confirmations',
          type: 'ReportConfirmation[]',
          model: 'ReportConfirmation',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'referenceId'
        },
      }
    }
  }
}
