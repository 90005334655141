<div mat-dialog-title>{{ data.title }}</div>
<div mat-dialog-content>
  <p *ngFor="let line of data.message">{{ line }}</p>
  <mat-form-field *ngIf="data.type === 'prompt'" class="w-full">
    <input matInput [type]="data.promptInputType" [formControl]="promptText">
  </mat-form-field>
  <mat-form-field *ngIf="data.type === 'promptMultiline'" class="w-full">
    <textarea matInput [formControl]="promptText" rows="5"></textarea>
  </mat-form-field>
  <mat-form-field *ngIf="data.type === 'promptSelect'" class="w-full">
    <mat-select [formControl]="promptText">
      <mat-option *ngFor="let option of data.selectOptions" [value]="option">{{ option }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button *ngIf="data.type !== 'alert'" mat-button mat-dialog-close>{{ data.cancelButtonText }}</button>
  <button mat-stroked-button [color]="data.okButtonColor" (click)="onOkClick()" [disabled]="okDisabled">{{ data.okButtonText }}</button>
</div>
