import * as moment from "moment";

/**
 * 各元号の開始日と期間
 * 新しい順に並べる
 */
export const gengoDefinitions = [
  { name: "令和", from: moment("2019-05-01"), years: 99 },
  { name: "平成", from: moment("1989-01-08"), years: 31 },
  { name: "昭和", from: moment("1926-12-25"), years: 64 },
  { name: "大正", from: moment("1912-07-30"), years: 15 },
  { name: "明治", from: moment("1868-01-25"), years: 45 },
];

export interface WarekiComponent {
  gengo: string;
  year: number;
  month: number;
  date: number;
  hour: number;
  minute: number;
}

/**
 * momentから和暦表記を作成
 * @param date moment
 * @returns
 */
export function getWarekiComponent(date: moment.Moment): WarekiComponent {
  let gengoDef;

  for (const def of gengoDefinitions) {
    if (date.isSameOrAfter(def.from)) {
      gengoDef = def;
      break;
    } else {
      continue;
    }
  }

  if (!gengoDef) {
    return null;
  }

  return {
    gengo: gengoDef.name,
    year: date.year() - gengoDef.from.year() + 1,
    month: date.month() + 1,
    date: date.date(),
    hour: date.hour(),
    minute: date.minute(),
  };
}

/**
 * 和暦表記からmomentオブジェクトを作成
 * @param warekiComponent
 * @returns
 */
export function momentFromWareki(warekiComponent: any) {
  const { gengo, year, month, date, hour, minute } = warekiComponent;

  if (gengo == null || year == null || month == null || date == null) {
    return null;
  }

  const gengoDef = gengoDefinitions.find((def) => def.name === gengo);

  return moment()
    .year(gengoDef.from.year() + year - 1)
    .month(month - 1)
    .date(date)
    .hour(hour || 0)
    .minute(minute || 0)
    .second(0)
    .millisecond(0);
}

/**
 * momentのisSameのパフォーマンスが悪いためネイティブのDateによる代替
 * 回数の多いループの中で実行するような場面ではこちらを使用すること
 * @param a 比較対象1
 * @param b 比較対象2
 * @param unit 比較範囲（日までか時まで）
 */
export function isSameTime(a: Date, b: Date, unit: "month" | "day" | "hour") {
  if (a == null || b == null) {
    return false;
  }

  const sameMonth =
    a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth();

  if (unit === "month") {
    return sameMonth;
  }

  const sameDay = sameMonth && a.getDate() === b.getDate();

  if (unit === "day") {
    return sameDay;
  } else if (unit === "hour") {
    return sameDay && a.getHours() === b.getHours();
  }
}
