import { Type, Injector } from "@angular/core";

export function Mixin<T1>(t1: Type<T1>): Type<T1>;
export function Mixin<T1, T2>(t1: Type<T1>, t2: Type<T2>): Type<T2 & T1>;
export function Mixin<T1, T2, T3>(
  t1: Type<T1>,
  t2: Type<T2>,
  t3: Type<T3>
): Type<T3 & T2 & T1>;
export function Mixin<T1, T2, T3, T4>(
  t1: Type<T1>,
  t2: Type<T2>,
  t3: Type<T3>,
  t4: Type<T4>
): Type<T4 & T3 & T2 & T1>;
export function Mixin<T1, T2, T3, T4, T5>(
  t1: Type<T1>,
  t2: Type<T2>,
  t3: Type<T3>,
  t4: Type<T4>,
  t5: Type<T5>
): Type<T5 & T4 & T3 & T2 & T1>;
export function Mixin(...traits: Type<any>[]) {
  const mixed = class {
    constructor(injector: Injector) {
      for (const trait of traits) {
        trait.prototype.inject.bind(this)(injector);
      }
    }
  };

  for (const trait of traits) {
    const descriptors = Object.getOwnPropertyDescriptors(trait.prototype);
    Object.defineProperties(mixed.prototype, descriptors);
    Object.assign(mixed.prototype, new trait());
    mixed.prototype.constructor = mixed;
  }

  return mixed;
}
