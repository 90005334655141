/* eslint-disable */
import {
  Resident,
  Company,
  Office
} from '../index';

declare var Object: any;
export interface AbsenceInterface {
  "startAt"?: Date|string;
  "endAt"?: Date|string;
  "type"?: number;
  "reason"?: string;
  "id"?: number;
  "residentId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "updaterId"?: number;
  resident?: Resident;
  company?: Company;
  office?: Office;
}

export class Absence implements AbsenceInterface {
  "startAt": Date|string = <any>null;
  "endAt": Date|string = <any>null;
  "type": number = <any>null;
  "reason": string = '';
  "id": number = <any>null;
  "residentId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "updaterId": number = <any>null;
  resident?: Resident;
  company?: Company;
  office?: Office;
  constructor(data?: Partial<AbsenceInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Absence`.
   */
  public static getModelName() {
    return "Absence";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Absence for dynamic purposes.
  **/
  public static factory(data: AbsenceInterface): Absence{
    return new Absence(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Absence',
      plural: 'Absences',
      path: 'Absences',
      idName: 'id',
      properties: {
        "startAt": {
          name: 'startAt',
          type: 'Date|string'
        },
        "endAt": {
          name: 'endAt',
          type: 'Date|string'
        },
        "type": {
          name: 'type',
          type: 'number'
        },
        "reason": {
          name: 'reason',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
      }
    }
  }
}
