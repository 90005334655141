import { Component, OnInit, Inject, INJECTOR, Injector, ViewChild } from '@angular/core';
import { ProgressRecord, ProgressRecordApi, FileInfoApi, FileInfo } from 'src/loopback';
import { CareDetailPageTrait, TypedCareDetailPageTrait } from 'src/app/classes/care-detail-page-trait';
import { PageBase } from 'src/app/classes/page-base';
import { TypedDetailPageTrait } from 'src/app/classes/detail-page-trait';
import { Mixin } from 'src/app/utils/mixin';
import { mergeMap, mapTo, tap } from 'rxjs/operators';
import { Observable, of, iif } from 'rxjs';
import { ProgressTemplateSelectorComponent } from '../progress-template-selector/progress-template-selector.component';
import { ScrollContentComponent } from 'src/app/shared/components/scroll-content/scroll-content.component';
import { MatSelectChange } from '@angular/material/select';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { DialogWidth } from 'src/app/services/dialog.service';
import { FileInfoWithData } from '../../components/file-selector/file-selector.component';

@Component({
  selector: 'cm-care-detail-progress',
  templateUrl: './care-detail-progress.component.html',
  styleUrls: ['./care-detail-progress.component.scss'],
})
export class CareDetailProgressComponent extends Mixin(
  PageBase,
  TypedDetailPageTrait(ProgressRecord),
  TypedCareDetailPageTrait(ProgressRecord),
) implements OnInit {

  isDialog = true;
  preventRedirectAfterCreate = true;
  titleBase = '経過記録';
  model = ProgressRecord;
  places: string[] = [];
  categories: string[] = [];
  canGoBack = true;
  selectedFile: Blob;
  photo: FileInfo;
  photoTouched = false;
  photoRemoved = false;
  _selectTempValue: { [control: string]: string } = {};

  get isDirty() {
    return this.form.dirty || this.photoTouched;
  }

  @ViewChild(ScrollContentComponent) content: ScrollContentComponent;

  constructor(
    private recordApi: ProgressRecordApi,
    private appSettings: AppSettingsService,
    private fileInfoApi: FileInfoApi,
    @Inject(INJECTOR) injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.init().pipe(
      tap(result => {
        this.photo = result ? result.photo : null;
        this.setPropertyFromRecord(result);
        this.setTitle(this.generateTitle(result));
      }),
      mergeMap(result => {
        const officeId = this.resident?.officeId ?? this.residents[0].officeId;

        return this.appSettings.getAll(officeId).pipe(
          tap(settings => {
            this.places = settings['care-progress-place'];
            this.categories = settings['care-progress-category'];

            if (result) {
              if (this.places.indexOf(result.place) === -1) {
                this.places.push(result.place);
              }
              if (this.categories.indexOf(result.category) === -1) {
                this.categories.push(result.category);
              }
            }
          }),
          mapTo(result),
        );
      }),
    ).subscribe();
  }

  fetch(id: number) {
    return this.recordApi.findById(id, {
      include: [...this.recordInclusionDefaults, 'photo'],
    });
  }

  save(data: ProgressRecord): Observable<ProgressRecord | ProgressRecord[]> {
    if (this.id) {
      return this.recordApi.patchAttributes(this.id, data).pipe(
        mergeMap(record => iif(
          () => this.photoRemoved,
          this.recordApi.destroyPhoto(record.id).pipe(mapTo(record)),
          of(record),
        )),
        mergeMap(record => this.uploadPhoto(record.id).pipe(mapTo(record))),
      );
    } else {
      if (this.residents) {
        const arr = this.residents.map(r => {
          return { ...data, residentId: r.id, officeId: r.officeId };
        });
        return this.recordApi.createMany(arr);
      } else {
        data.residentId = this.resident.id;
        data.officeId = this.resident.officeId;
        return this.recordApi.create(data).pipe(
          mergeMap(record => this.uploadPhoto(record.id).pipe(mapTo(record))),
        );
      }
    }
  }

  uploadPhoto(id: number) {
    if (!this.selectedFile) {
      return of(null);
    }

    const formData = new FormData();

    formData.append('file', this.selectedFile);
    formData.append('imageableType', 'ProgressRecord');
    formData.append('imageableId', id.toString());
    formData.append('officeId', this.resident.officeId.toString());

    return this.fileInfoApi.upload(`progressrecord-${id}`, formData);
  }

  openTemplateSelector() {
    this.dialog.open(ProgressTemplateSelectorComponent, {
      data: { officeId: this.resident?.officeId ?? this.residents[0].officeId },
      size: DialogWidth.xl,
    }).afterClosed().subscribe(
      result => {
        if (result) {
          this.form.patchValue(result);
          this.form.markAsDirty();
        }
      },
    );
  }

  onOpenSelect(controlName: string) {
    this._selectTempValue[controlName] = this.form.get(controlName).value;
  }

  onChangeSelect(e: MatSelectChange, controlName: string, options: string[]) {
    if (e.value === -1) {
      this.form.get(controlName).setValue(null);
      this.dialog.open(GenericDialogComponent, {
        data: {
          type: 'prompt',
          title: '入力してください',
        },
        size: DialogWidth.md,
      }).afterClosed().subscribe(
        result => {
          if (typeof result === 'string' && result) {
            options.push(result);
          } else {
            result = this._selectTempValue[controlName];
          }
          this.form.get(controlName).setValue(result);
        },
      );
    }
  }

  onFileChange(file: FileInfoWithData | FileInfoWithData[]) {
    this.photoTouched = true;

    if (file && !Array.isArray(file)) {
      this.selectedFile = file._blob;
    } else {
      this.selectedFile = undefined;

      if (this.photo) {
        this.photoRemoved = true;
      }
    }
  }

}
