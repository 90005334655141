import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, UrlSegment, Route, Router } from '@angular/router';

import { RoleService } from '../services/role.service';

@Injectable({
  providedIn: 'root',
})
export class AuthRequiredGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private role: RoleService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuthorized(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuthorized(route);
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    return this.isAuthorized(route);
  }

  isAuthorized(route: ActivatedRouteSnapshot | Route) {
    const caps = route.data['caps'];
    const authorized = !!this.role.currentUser;

    if (!authorized) {
      this.router.navigate(['/login']);
      return false;
    }

    return caps ? this.roleHasCaps(caps) : authorized;
  }

  roleHasCaps(caps: string[] = []): boolean {
    return this.role.canEvery(caps);
  }
}
