<div *ngIf="!previewFileAvailable" class="drop-area">
  <p>ファイルをドラッグ＆ドロップ</p>
  <p>または</p>
  <button mat-stroked-button (click)="fileSelector.click()">
    <ng-content></ng-content>
  </button>
</div>
<input type="file" #fileSelector [accept]="accept" [multiple]="multiple"
  (change)="onFileSelect($event)" class="hidden">
<ng-container *ngIf="previewFileAvailable" [ngSwitch]="type">
  <mat-list *ngSwitchCase="'file'" cdkDropList (cdkDropListDropped)="sortFiles($event)"
    class="preview-file-list">
    <mat-list-item *ngFor="let file of previewFiles; index as i" cdkDrag class="sortable-list">
      <mat-icon *ngIf="multiple" matListItemIcon cdkDragHandle>drag_handle</mat-icon>
      <div matListItemTitle class="sortable-list-content">
        <span *ngIf="!file._editing">
          <a [href]="file | fileUrl" target="_blank">{{ file.description }}</a>
        </span>
        <mat-form-field *ngIf="file._editing" class="w-full">
          <input matInput [formControl]="file._nameForm">
        </mat-form-field>
      </div>
      <div matListItemMeta>
        <button *ngIf="!file._editing" mat-icon-button (click)="file._editing = true"
          class="sortable-list-remove">
          <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="!file._editing" mat-icon-button (click)="removeFile(i)"
          class="sortable-list-remove">
          <mat-icon>remove_circle</mat-icon>
        </button>
        <button *ngIf="file._editing" mat-icon-button (click)="applyFileNameEdit(i)"
          class="sortable-list-remove">
          <mat-icon>check</mat-icon>
        </button>
        <button *ngIf="file._editing" mat-icon-button (click)="cancelFileNameEdit(file)"
          class="sortable-list-remove">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <div *cdkDragPreview>{{ file.description }}</div>
    </mat-list-item>
    <mat-action-row *ngIf="multiple">
      <button mat-button (click)="fileSelector.click()">
        <mat-icon>add</mat-icon> 追加
      </button>
    </mat-action-row>
  </mat-list>
  <div *ngSwitchCase="'image'" class="preview-image"
    [style.background-image]="previewImageStyle || ('url(' + (previewFiles[0] | fileUrl) + ')') ">
    <button type="button" mat-mini-fab color="primary" class="preview-image-remove"
      (click)="removeFile(0)">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</ng-container>
