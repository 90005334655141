import { Component, ChangeDetectorRef, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DATE_FORMATS, MatDateFormats, DateAdapter } from '@angular/material/core';
import { MatCalendar, MatDatepicker } from '@angular/material/datepicker';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'cm-date-selector-calendar-header',
  templateUrl: './date-selector-calendar-header.component.html',
  styleUrls: ['./date-selector-calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateSelectorCalendarHeaderComponent<D> {

  private navButtonHoldTarget: string;
  private navButtonHoldTimer: Subscription;

  constructor(
    private calendar: MatCalendar<D>,
    private picker: MatDatepicker<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private dateAdapter: DateAdapter<D>,
    private cdRef: ChangeDetectorRef,
  ) {
    this.calendar.stateChanges.subscribe(() => this.cdRef.markForCheck());
  }

  get label() {
    return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel).toLocaleUpperCase();
  }

  selectToday() {
    this.calendar.selected = this.calendar.activeDate = this.dateAdapter.today();
    this.picker.select(this.calendar.selected);
    this.picker.close();
  }

  addCalendarMonths(amount: number) {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, amount);
  }

  onNavMouseDown(target: string) {
    this.navButtonHoldTarget = target;
    this.navButtonHoldTimer = timer(1000).subscribe(() => {
      this.addCalendarMonths(12 * (target === 'prev' ? -1 : 1));
    });
  }

  onNavMouseUp(target: string) {
    if (this.navButtonHoldTimer.closed) {
      return;
    }

    if (target === this.navButtonHoldTarget) {
      this.addCalendarMonths(target === 'prev' ? -1 : 1);
    }

    this.navButtonHoldTimer.unsubscribe();
  }

}
