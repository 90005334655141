<div mat-dialog-title>
  <div class="w-full inline-flex flex-row justify-between items-center">
    <span>{{ title }}</span>
    <button mat-button *ngIf="id && !resident && !residents" color="warn" (click)="delete()">
      <mat-icon>clear</mat-icon> 削除
    </button>
  </div>
</div>
<div mat-dialog-content [formGroup]="form">
  <cm-message-box message="入力エラーがあります。" [listItems]="errorMessages"></cm-message-box>
  <mat-card appearance="outlined" class="meta-card mb-8">
    <mat-card-content>
      <table class="meta-table w-full">
        <tbody>
          <tr *ngIf="resident || residents">
            <th>入居者</th>
            <td>
              <cm-resident-name-card *ngIf="resident" [resident]="resident"
                [room]="resident.moveHistories[0]?.room" size="small"></cm-resident-name-card>
              <span *ngIf="residents" [matTooltip]="residentNames">{{ residents.length }}人の入居者</span>
            </td>
          </tr>
          <tr>
            <th>記録者</th>
            <td>
              <mat-select *ngIf="staffList$ | async as staffList" formControlName="staffId" class="transparent-input">
                <mat-option *ngFor="let staff of staffList" [value]="staff.id">
                  {{ staff.lastName }} {{ staff.firstName }}
                </mat-option>
              </mat-select>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
  </mat-card>
  <div class="section-content">
    <div class="mb-4">
      <mat-checkbox formControlName="withTime">時間指定</mat-checkbox>
      <mat-checkbox [formControl]="withEnd">終了指定</mat-checkbox>
    </div>
    <mat-form-field *ngIf="form.controls.withTime.value" class="w-full">
      <mat-label>開始日時</mat-label>
      <cm-date-time-picker formControlName="startAt"></cm-date-time-picker>
    </mat-form-field>
    <mat-form-field *ngIf="!form.controls.withTime.value" class="w-full">
      <mat-label>開始日</mat-label>
      <input matInput [matDatepicker]="startPicker" formControlName="startAt"
      [max]="form.controls.endAt.value" (click)="startPicker.open()">
      <mat-datepicker #startPicker [calendarHeaderComponent]="calendarHeader"></mat-datepicker>
    </mat-form-field>
    <ng-container *ngIf="withEnd.value">
      <mat-form-field *ngIf="form.controls.withTime.value" class="w-full">
        <mat-label>終了日時</mat-label>
        <cm-date-time-picker formControlName="endAt"></cm-date-time-picker>
      </mat-form-field>
      <mat-form-field *ngIf="!form.controls.withTime.value" class="w-full">
        <mat-label>終了日</mat-label>
        <input matInput [matDatepicker]="endPicker" formControlName="endAt"
        [min]="form.controls.startAt.value" (click)="endPicker.open()">
        <mat-datepicker #endPicker [calendarHeaderComponent]="calendarHeader"></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <mat-form-field *ngIf="!resident && !residents" class="w-full">
      <mat-label>カラー</mat-label>
      <mat-select formControlName="color">
        <mat-option value="red"><span class="text-red-200">■</span> 赤</mat-option>
        <mat-option value="yellow"><span class="text-yellow-200">■</span> 黄</mat-option>
        <mat-option value="green"><span class="text-green-200">■</span> 緑</mat-option>
        <mat-option value="blue"><span class="text-blue-200">■</span> 青</mat-option>
        <mat-option value="purple"><span class="text-purple-200">■</span> 紫</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-divider class="pb-6"></mat-divider>
    <mat-form-field class="w-full">
      <mat-label>予定</mat-label>
      <input matInput formControlName="title">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>内容</mat-label>
      <textarea matInput rows="5" formControlName="memo"></textarea>
    </mat-form-field>
  </div>
  <mat-card appearance="outlined" [hidden]="!resident && !residents">
    <mat-card-content>
      <div class="flex flex-row gap-4">
        <div class="rounded-icon">
          <mat-icon>comment</mat-icon>
        </div>
        <div class="flex-1">
          <mat-form-field class="w-full">
            <mat-label>この記録に関するノート<ng-container *ngIf="!id || !note">を追加</ng-container>
            </mat-label>
            <textarea matInput #noteContent="matInput" rows="5" formControlName="noteContent"></textarea>
            <mat-hint *ngIf="!id || !note">記録の保存と同時にノートに投稿されます</mat-hint>
          </mat-form-field>
          <ng-container *ngIf="note">
            <mat-divider></mat-divider>
            <cm-timeline-note-replies *ngIf="noteReplies && noteReplies.length" [items]="noteReplies"
              class="mb-4"></cm-timeline-note-replies>
            <div class="text-right">
              <button mat-button (click)="openNoteReplyEditor()">
                <mat-icon>reply</mat-icon> 返信
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>キャンセル</button>
  <button mat-button mat-stroked-button *ngIf="resident || residents" (click)="noteContent.focus()">
    <mat-icon>comment</mat-icon> ノートを編集
  </button>
  <button mat-button mat-flat-button type="submit" color="primary" (click)="onSubmit($event)"
    [disabled]="!isDirty || isSaving">
    <mat-icon>save</mat-icon> 保存
  </button>
</div>
