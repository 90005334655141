/* eslint-disable */
import {
  Staff,
  Company,
  Office
} from '../index';

declare var Object: any;
export interface ReportConfirmationInterface {
  "confirmedAt"?: Date|string;
  "id"?: number;
  "staffId"?: number;
  "referenceId"?: number;
  "referenceType"?: string;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "updaterId"?: number;
  staff?: Staff;
  reference?: any;
  company?: Company;
  office?: Office;
}

export class ReportConfirmation implements ReportConfirmationInterface {
  "confirmedAt": Date|string = <any>null;
  "id": number = <any>null;
  "staffId": number = <any>null;
  "referenceId": number = <any>null;
  "referenceType": string = '';
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "updaterId": number = <any>null;
  staff?: Staff;
  reference?: any;
  company?: Company;
  office?: Office;
  constructor(data?: Partial<ReportConfirmationInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ReportConfirmation`.
   */
  public static getModelName() {
    return "ReportConfirmation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ReportConfirmation for dynamic purposes.
  **/
  public static factory(data: ReportConfirmationInterface): ReportConfirmation{
    return new ReportConfirmation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ReportConfirmation',
      plural: 'ReportConfirmations',
      path: 'ReportConfirmations',
      idName: 'id',
      properties: {
        "confirmedAt": {
          name: 'confirmedAt',
          type: 'Date|string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "referenceId": {
          name: 'referenceId',
          type: 'number'
        },
        "referenceType": {
          name: 'referenceType',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        reference: {
          name: 'reference',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'referenceId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
      }
    }
  }
}
