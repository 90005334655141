/* eslint-disable */
import {
  FileInfo
} from '../index';

declare var Object: any;
export interface CompanyInterface {
  "name"?: string;
  "zipCode"?: string;
  "address1"?: string;
  "address2"?: string;
  "phone"?: string;
  "fax"?: string;
  "status": number;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "updaterId"?: number;
  logo?: FileInfo;
}

export class Company implements CompanyInterface {
  "name": string = '';
  "zipCode": string = '';
  "address1": string = '';
  "address2": string = '';
  "phone": string = '';
  "fax": string = '';
  "status": number = 1;
  "id": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "updaterId": number = <any>null;
  logo?: FileInfo;
  constructor(data?: Partial<CompanyInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Company`.
   */
  public static getModelName() {
    return "Company";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Company for dynamic purposes.
  **/
  public static factory(data: CompanyInterface): Company{
    return new Company(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Company',
      plural: 'Companies',
      path: 'Companies',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "zipCode": {
          name: 'zipCode',
          type: 'string'
        },
        "address1": {
          name: 'address1',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "fax": {
          name: 'fax',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        logo: {
          name: 'logo',
          type: 'FileInfo',
          model: 'FileInfo',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'imageableId'
        },
      }
    }
  }
}
