import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { DateSelectorCalendarHeaderComponent } from '../date-selector-calendar-header/date-selector-calendar-header.component';

@Component({
  selector: 'cm-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateSelectorComponent  {

  @Input() dateControl = new FormControl(moment());
  @Input() min: moment.Moment;
  @Input() max = moment();
  @Output() dateChange = new EventEmitter<moment.Moment>();

  calendarHeader = DateSelectorCalendarHeaderComponent;

  constructor(
  ) { }

  onPrev() {
    const prev = this.dateControl.value.subtract(1, 'day');
    this.dateControl.setValue(prev);
  }

  onNext() {
    const next = this.dateControl.value.add(1, 'day');
    this.dateControl.setValue(next);
  }

}
