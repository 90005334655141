<div [formGroup]="form" class="flex flex-row gap-2 justify-start items-center">
  <div class="basis-[10em]">
    <input matInput formControlName="date" [matDatepicker]="datepicker"
      [readonly]="true" class="transparent-input w-full"
      (click)="datepicker.open()" (dateChange)="onChange(value)">
    <mat-datepicker #datepicker [calendarHeaderComponent]="calendarHeader"></mat-datepicker>
  </div>
  <div class="basis-[40px]">
    <select matInput formControlName="hour" (change)="onChange(value)" class="transparent-input">
      <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
    </select>
  </div>
  <span>時</span>
  <div class="basis-[40px]">
    <select matInput formControlName="minute" (change)="onChange(value)" class="transparent-input">
      <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
    </select>
  </div>
  <span>分</span>
</div>
