/* eslint-disable */
import {
  Resident,
  Company,
  Office
} from '../index';

declare var Object: any;
export interface InsuranceCardInterface {
  "certification"?: number;
  "appliedAt"?: Date|string;
  "issuedAt"?: Date|string;
  "certifiedAt"?: Date|string;
  "startAt"?: Date|string;
  "expiredAt"?: Date|string;
  "realExpiredAt"?: Date|string;
  "insuredNumber"?: string;
  "insurerNumber"?: string;
  "isPending"?: boolean;
  "isModification"?: boolean;
  "id"?: number;
  "residentId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "updaterId"?: number;
  resident?: Resident;
  company?: Company;
  office?: Office;
}

export class InsuranceCard implements InsuranceCardInterface {
  "certification": number = <any>null;
  "appliedAt": Date|string = <any>null;
  "issuedAt": Date|string = <any>null;
  "certifiedAt": Date|string = <any>null;
  "startAt": Date|string = <any>null;
  "expiredAt": Date|string = <any>null;
  "realExpiredAt": Date|string = <any>null;
  "insuredNumber": string = '';
  "insurerNumber": string = '';
  "isPending": boolean = false;
  "isModification": boolean = false;
  "id": number = <any>null;
  "residentId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "updaterId": number = <any>null;
  resident?: Resident;
  company?: Company;
  office?: Office;
  constructor(data?: Partial<InsuranceCardInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `InsuranceCard`.
   */
  public static getModelName() {
    return "InsuranceCard";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of InsuranceCard for dynamic purposes.
  **/
  public static factory(data: InsuranceCardInterface): InsuranceCard{
    return new InsuranceCard(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'InsuranceCard',
      plural: 'InsuranceCards',
      path: 'InsuranceCards',
      idName: 'id',
      properties: {
        "certification": {
          name: 'certification',
          type: 'number'
        },
        "appliedAt": {
          name: 'appliedAt',
          type: 'Date|string'
        },
        "issuedAt": {
          name: 'issuedAt',
          type: 'Date|string'
        },
        "certifiedAt": {
          name: 'certifiedAt',
          type: 'Date|string'
        },
        "startAt": {
          name: 'startAt',
          type: 'Date|string'
        },
        "expiredAt": {
          name: 'expiredAt',
          type: 'Date|string'
        },
        "realExpiredAt": {
          name: 'realExpiredAt',
          type: 'Date|string'
        },
        "insuredNumber": {
          name: 'insuredNumber',
          type: 'string'
        },
        "insurerNumber": {
          name: 'insurerNumber',
          type: 'string'
        },
        "isPending": {
          name: 'isPending',
          type: 'boolean'
        },
        "isModification": {
          name: 'isModification',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
      }
    }
  }
}
