import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { romaToKatakana } from 'src/app/utils/string';

export interface AutoKanaSettings {
  [name: string]: string;
}

@Directive({
  selector: '[cmAutoKana][formGroup]',
  exportAs: 'cmAutoKana',
})
export class AutoKanaDirective {

  @Input('cmAutoKana') settings: AutoKanaSettings;
  @Input() formGroup: FormGroup;

  update(settingsName: string, result: string) {
    const kana = this.formGroup.get(this.settings[settingsName]);
    kana.setValue(kana.value + result);
  }
}

@Directive({
  selector: 'input[cmAutoKanaInput]',
  exportAs: 'cmAutoKanaInput',
})
export class AutoKanaInputDirective {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('cmAutoKanaInput') settingsName: string;

  private nameInputBuffer: string[] = [];

  constructor(
    private parent: AutoKanaDirective,
  ) {}

  updateKana() {
    const allowedControlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    let result = '';
    let pointer = 0;

    for (const input of this.nameInputBuffer) {
      if (allowedControlKeys.indexOf(input) === -1) {
        result += romaToKatakana(input);
        pointer = result.length;
      } else if (input === 'Backspace' && pointer !== 0) {
        result = result.slice(0, pointer - 1) + result.slice(pointer);
        pointer -= 1;
      } else if (input === 'Delete' && pointer !== result.length) {
        result = result.slice(0, pointer) + result.slice(pointer + 1);
      } else if (input === 'ArrowLeft' && pointer !== 0) {
        pointer -= 1;
      } else if (input === 'ArrowRight' && pointer !== result.length) {
        pointer += 1;
      }
    }

    this.nameInputBuffer = [];
    this.parent.update(this.settingsName, result);
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    const allowedControlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];

    if (e.isComposing) {
      if (e.key === ' ') {
        // this.updateKana();
      } else if (e.key.length === 1) {
        if (this.nameInputBuffer.length === 0 || allowedControlKeys.indexOf(this.nameInputBuffer[this.nameInputBuffer.length - 1]) !== -1) {
          this.nameInputBuffer.push(e.key);
        } else {
          this.nameInputBuffer[this.nameInputBuffer.length - 1] += e.key;
        }
      } else if (allowedControlKeys.indexOf(e.key) !== -1) {
        this.nameInputBuffer.push(e.key);
      }
    }
  }

  @HostListener('compositionend', ['$event'])
  onNameCompositionEnd(e: CompositionEvent) {
    if (e.data !== '') {
      this.updateKana();
    } else {
      this.nameInputBuffer = [];
    }
  }

}
