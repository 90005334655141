<div [formGroup]="form" class="flex flex-row gap-2 justify-start items-baseline">
  <div class="basis-[60px]">
    <mat-select formControlName="gengo" (selectionChange)="rebuildYears($event.value);onChange(value)">
      <mat-option *ngFor="let gengo of gengos" [value]="gengo">{{ gengo }}</mat-option>
    </mat-select>
  </div>
  <div class="basis-[40px]">
    <mat-select formControlName="year" (selectionChange)="rebuildDates();onChange(value)">
      <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
    </mat-select>
  </div>
  <small>年</small>
  <div class="basis-[40px]">
    <mat-select formControlName="month" (selectionChange)="rebuildDates();onChange(value)">
      <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
    </mat-select>
  </div>
  <small>月</small>
  <ng-container *ngIf="mode !== 'month'">
    <div class="basis-[40px]">
      <mat-select formControlName="date" (selectionChange)="onChange(value)">
        <mat-option *ngFor="let date of dates" [value]="date">{{ date }}</mat-option>
      </mat-select>
    </div>
    <small>日</small>
    <ng-container *ngIf="mode === 'datetime'">
      <div class="basis-[40px]">
        <input matInput formControlName="hour" (change)="onChange(value)">
      </div>
      <small>時</small>
      <div class="basis-[40px]">
        <input matInput formControlName="minute" (change)="onChange(value)">
      </div>
      <small>分</small>
    </ng-container>
  </ng-container>
</div>
