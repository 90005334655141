import { Pipe, PipeTransform } from '@angular/core';
import { OptionPropertyName, OptionsService } from 'src/app/services/options.service';

@Pipe({
  name: 'optionLabel',
})
export class OptionLabelPipe implements PipeTransform {

  constructor(
    private opt: OptionsService,
  ) {}

  transform(value: number, property: OptionPropertyName): any {
    return this.opt.getOptionLabel(property, value);
  }

}
