import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Resident, ResidentApi } from 'src/loopback';
import { residentInclusionDefaults } from 'src/app/constants';
import { ResidentStatus } from 'src/app/enums/resident-status';
import { DialogComponentBase } from 'src/app/services/dialog.service';

@Component({
  selector: 'cm-resident-select',
  templateUrl: './resident-select.component.html',
  styleUrls: ['./resident-select.component.scss'],
})
export class ResidentSelectComponent extends DialogComponentBase<ResidentSelectDialogData, Resident> implements OnInit {

  allResidents: Resident[] = [];
  residents: Resident[] = [];
  onlyOccupying = false;

  constructor(
    protected ref: MatDialogRef<ResidentSelectComponent, Resident>,
    @Inject(MAT_DIALOG_DATA) public data: ResidentSelectDialogData,
    private residentApi: ResidentApi,
  ) {
    super();
  }

  ngOnInit() {
    this.onlyOccupying = this.data?.onlyOccupying;

    this.residentApi.find({
      where: {
        status: { neq: ResidentStatus.deleted },
        ...this.data?.where,
      },
      include: residentInclusionDefaults,
    }).subscribe(
      res => {
        this.allResidents = res;
        this.toggleFilterOnlyOccupying(this.onlyOccupying);
      },
    );
  }

  toggleFilterOnlyOccupying(enable: boolean) {
    if (enable) {
      this.residents = this.allResidents.filter(r => {
        return r.moveHistories[0] && r.moveHistories[0].room;
      });
    } else {
      this.residents = this.allResidents;
    }
  }

  /**
   * 選択ハンドラ
   */
  onSelect(resident: Resident) {
    this.ref.close(resident);
  }

}

export interface ResidentSelectDialogData {
  where?: { [prop: string]: any };
  onlyOccupying?: boolean;
}
