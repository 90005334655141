/* eslint-disable */

declare var Object: any;
export interface ContactInterface {
  "id"?: string;
  "type"?: string;
  "name"?: string;
  "nameKana"?: string;
  "zipCode"?: string;
  "address1"?: string;
  "address2"?: string;
  "phone"?: string;
  "mobilePhone"?: string;
  "fax"?: string;
  "email"?: string;
  "remarks"?: string;
}

export class Contact implements ContactInterface {
  "id": string = '';
  "type": string = '';
  "name": string = '';
  "nameKana": string = '';
  "zipCode": string = '';
  "address1": string = '';
  "address2": string = '';
  "phone": string = '';
  "mobilePhone": string = '';
  "fax": string = '';
  "email": string = '';
  "remarks": string = '';
  constructor(data?: Partial<ContactInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Contact`.
   */
  public static getModelName() {
    return "Contact";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Contact for dynamic purposes.
  **/
  public static factory(data: ContactInterface): Contact{
    return new Contact(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Contact',
      plural: 'Contacts',
      path: 'Contacts',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "nameKana": {
          name: 'nameKana',
          type: 'string'
        },
        "zipCode": {
          name: 'zipCode',
          type: 'string'
        },
        "address1": {
          name: 'address1',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "mobilePhone": {
          name: 'mobilePhone',
          type: 'string'
        },
        "fax": {
          name: 'fax',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
