/* eslint-disable */
import {
  Staff,
  Resident,
  VisitPlan,
  Company,
  Office,
  PlanHistory
} from '../index';

declare var Object: any;
export interface VisitReportInterface {
  "targetMonth"?: Date|string;
  "targetDuration"?: number;
  "visits"?: Array<any>;
  "progress"?: string;
  "detail"?: string;
  "situation"?: string;
  "remark"?: string;
  "id"?: number;
  "staffId"?: number;
  "residentId"?: number;
  "planId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "recordedAt"?: Date|string;
  "updaterId"?: number;
  staff?: Staff;
  resident?: Resident;
  plan?: VisitPlan;
  company?: Company;
  office?: Office;
  history?: PlanHistory;
}

export class VisitReport implements VisitReportInterface {
  "targetMonth": Date|string = <any>null;
  "targetDuration": number = <any>null;
  "visits": Array<any> = <any>[];
  "progress": string = '';
  "detail": string = '';
  "situation": string = '';
  "remark": string = '';
  "id": number = <any>null;
  "staffId": number = <any>null;
  "residentId": number = <any>null;
  "planId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "recordedAt": Date|string = <any>null;
  "updaterId": number = <any>null;
  staff?: Staff;
  resident?: Resident;
  plan?: VisitPlan;
  company?: Company;
  office?: Office;
  history?: PlanHistory;
  constructor(data?: Partial<VisitReportInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VisitReport`.
   */
  public static getModelName() {
    return "VisitReport";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of VisitReport for dynamic purposes.
  **/
  public static factory(data: VisitReportInterface): VisitReport{
    return new VisitReport(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VisitReport',
      plural: 'VisitReports',
      path: 'VisitReports',
      idName: 'id',
      properties: {
        "targetMonth": {
          name: 'targetMonth',
          type: 'Date|string'
        },
        "targetDuration": {
          name: 'targetDuration',
          type: 'number'
        },
        "visits": {
          name: 'visits',
          type: 'Array&lt;any&gt;'
        },
        "progress": {
          name: 'progress',
          type: 'string'
        },
        "detail": {
          name: 'detail',
          type: 'string'
        },
        "situation": {
          name: 'situation',
          type: 'string'
        },
        "remark": {
          name: 'remark',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "planId": {
          name: 'planId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "recordedAt": {
          name: 'recordedAt',
          type: 'Date|string',
          default: <any>null
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        plan: {
          name: 'plan',
          type: 'VisitPlan',
          model: 'VisitPlan',
          relationType: 'belongsTo',
                  keyFrom: 'planId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        history: {
          name: 'history',
          type: 'PlanHistory',
          model: 'PlanHistory',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'recordId'
        },
      }
    }
  }
}
