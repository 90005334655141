/* eslint-disable */
import {
  Company,
  Office,
  Room
} from '../index';

declare var Object: any;
export interface FloorInterface {
  "name"?: string;
  "order"?: number;
  "id"?: number;
  "officeId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "updaterId"?: number;
  company?: Company;
  office?: Office;
  rooms?: Room[];
}

export class Floor implements FloorInterface {
  "name": string = '';
  "order": number = <any>null;
  "id": number = <any>null;
  "officeId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "updaterId": number = <any>null;
  company?: Company;
  office?: Office;
  rooms?: Room[];
  constructor(data?: Partial<FloorInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Floor`.
   */
  public static getModelName() {
    return "Floor";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Floor for dynamic purposes.
  **/
  public static factory(data: FloorInterface): Floor{
    return new Floor(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Floor',
      plural: 'Floors',
      path: 'Floors',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        rooms: {
          name: 'rooms',
          type: 'Room[]',
          model: 'Room',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'floorId'
        },
      }
    }
  }
}
