<div mat-dialog-title>{{ title }}</div>
<div mat-dialog-content [formGroup]="form">
  <mat-card appearance="outlined" class="meta-card mb-8">
    <mat-card-content>
      <table class="meta-table w-full">
        <tbody>
          <tr>
            <th>入居者</th>
            <td>
              <cm-resident-name-card *ngIf="resident" [resident]="resident"
                [room]="resident.moveHistories[0]?.room" size="small"></cm-resident-name-card>
              <span *ngIf="residents" [matTooltip]="residentNames">{{ residents.length }}人の入居者</span>
            </td>
          </tr>
          <tr>
            <th>記録日時</th>
            <td>
              <cm-date-time-picker formControlName="recordedAt"></cm-date-time-picker>
            </td>
          </tr>
          <tr>
            <th>記録者</th>
            <td>
              <mat-select *ngIf="staffList$ | async as staffList" formControlName="staffId" class="transparent-input">
                <mat-option *ngFor="let staff of staffList" [value]="staff.id">
                  {{ staff.lastName }} {{ staff.firstName }}
                </mat-option>
              </mat-select>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
  </mat-card>
  <div class="section-content">
    <div class="flex flex-row gap-4">
      <div class="basis-[70%]">
        <div class="mb-4">
          <button mat-stroked-button (click)="openTemplateSelector()">
            <mat-icon>list</mat-icon> テンプレート
          </button>
        </div>
        <mat-form-field class="w-full">
          <mat-label>分類</mat-label>
          <mat-select formControlName="category" (opened)="onOpenSelect('category')"
            (selectionChange)="onChangeSelect($event, 'category', categories)">
            <mat-option [value]="-1">
              <mat-icon>edit</mat-icon> 自由入力
            </mat-option>
            <mat-option *ngFor="let category of categories" [value]="category">{{ category }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
          <mat-label>場所</mat-label>
          <mat-select formControlName="place" (opened)="onOpenSelect('place')"
            (selectionChange)="onChangeSelect($event, 'place', places)">
            <mat-option [value]="-1">
              <mat-icon>edit</mat-icon> 自由入力
            </mat-option>
            <mat-option *ngFor="let place of places" [value]="place">{{ place }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-autocomplete #placeAutocomplete>
          <mat-option *ngFor="let place of places" [value]="place">{{ place }}</mat-option>
        </mat-autocomplete>
      </div>
      <div class="photo-area basis-[30%]">
        <cm-file-selector type="image" accept="image/*" [currentFile]="photo"
          (fileChange)="onFileChange($event)">
          <mat-icon>photo_camera</mat-icon>
        </cm-file-selector>
      </div>
    </div>
    <mat-form-field class="w-full">
      <mat-label>記録</mat-label>
      <textarea matInput rows="5" formControlName="memo"></textarea>
    </mat-form-field>
  </div>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="flex flex-row gap-4">
        <div class="rounded-icon">
          <mat-icon>comment</mat-icon>
        </div>
        <div class="flex-1">
          <mat-form-field class="w-full">
            <mat-label>この記録に関するノート<ng-container *ngIf="!id || !note">を追加</ng-container></mat-label>
            <textarea matInput #noteContent="matInput" rows="5" formControlName="noteContent"></textarea>
            <mat-hint *ngIf="!id || !note">記録の保存と同時にノートに投稿されます</mat-hint>
          </mat-form-field>
          <ng-container *ngIf="note">
            <mat-divider></mat-divider>
            <cm-timeline-note-replies *ngIf="noteReplies && noteReplies.length" [items]="noteReplies" class="mb-4"></cm-timeline-note-replies>
            <div class="text-right">
              <button mat-button (click)="openNoteReplyEditor()">
                <mat-icon>reply</mat-icon> 返信
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>キャンセル</button>
  <button mat-button mat-stroked-button (click)="noteContent.focus()">
    <mat-icon>comment</mat-icon> ノートを編集
  </button>
  <button mat-button mat-flat-button type="submit" color="primary" (click)="onSubmit($event)"
    [disabled]="!isDirty || isSaving">
    <mat-icon>save</mat-icon> 保存
  </button>
</div>
