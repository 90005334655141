import { Component, HostBinding, Input } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { DateSelectorCalendarHeaderComponent } from '../date-selector-calendar-header/date-selector-calendar-header.component';
import { CM_DATE_SELECTOR_FORMATS_WITHOUT_WEEKDAY } from 'src/app/constants';
import { IntervalInputBase } from 'src/app/classes/interval-input-base';

@Component({
  selector: 'cm-date-interval-input',
  templateUrl: './date-interval-input.component.html',
  styleUrls: ['./date-interval-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: DateIntervalInputComponent },
    { provide: MAT_DATE_FORMATS, useValue: CM_DATE_SELECTOR_FORMATS_WITHOUT_WEEKDAY },
  ],
})
export class DateIntervalInputComponent extends IntervalInputBase {
  static idSeq = 0;
  controlType = 'date-interval-input';

  @Input() disableClear = false;
  @HostBinding() id = `cm-date-interval-input-${DateIntervalInputComponent.idSeq++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  calendarHeader = DateSelectorCalendarHeaderComponent;
}
