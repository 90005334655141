import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericDialogComponent } from '../shared/dialogs/generic-dialog/generic-dialog.component';
import { tap, mergeMapTo } from 'rxjs/operators';
import { GlobalEventService } from '../services/global-event.service';
import { LoopBackAuth } from 'src/loopback';
import { DialogService, DialogWidth } from '../services/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ApiErrorHandler {

  /**
   * アラートの多重ポップアップ防止用フラグ
   */
  alertShown = false;

  constructor(
    private dialog: DialogService,
    private auth: LoopBackAuth,
    private event: GlobalEventService,
  ) { }

  public handleError(errorResponse: HttpErrorResponse): Observable<never> {
    if (
      this.auth.getCurrentUserId() &&
      !/\/Staff\/(me|login|logout)/.test(errorResponse.url) &&
      errorResponse.status === 401 && !this.alertShown
    ) {
      this.alertShown = true;

      return this.dialog.open(GenericDialogComponent, {
        data: {
          type: 'alert',
          title: 'ユーザー認証有効期限切れ',
          message: [
            '再度ログインする必要があります。',
            'OKボタンを押すとログイン画面へ移動します。',
          ],
        },
        size: DialogWidth.md,
        disableClose: true,
      }).afterClosed().pipe(
        tap(() => {
          this.event.trigger('tokenExpired', true);
          this.dialog.closeAll();
          this.alertShown = false;
        }),
        mergeMapTo(throwError(errorResponse.error.error || 'Server error')),
      );
    } else {
      return throwError(errorResponse.error.error || 'Server error');
    }
  }
}
