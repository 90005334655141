<div *ngIf="item | isTimelineItemType:'progress'" class="flex flex-row">
  <div class="flex-1">
    <dl>
      <dt>場所</dt>
      <dd>{{ item.record.place }}</dd>
      <dt>分類</dt>
      <dd>{{ item.record.category }}</dd>
    </dl>
    <div *ngIf="item.record.memo as memo" class="record-summary">{{ memo }}</div>
  </div>
  <a *ngIf="item.record.photo" [href]="item.record.photo | fileUrl" target="_blank" class="block w-48 leading-[0]">
    <img [src]="item.record.photo | fileUrl" class="w-48 h-48 object-contain bg-black"/>
  </a>
</div>
<div *ngIf="item | isTimelineItemType:'hospital'">
  <div *ngIf="item.record.memo as memo" class="record-summary">{{ memo }}</div>
</div>
<div *ngIf="item | isTimelineItemType:'meal'">
  <dl [class.double-column]="!compact">
    <dt>主食</dt>
    <dd>{{ item.record.staple }} %</dd>
    <dt>副食</dt>
    <dd>{{ item.record.sidedish }} %</dd>
    <dt>汁物</dt>
    <dd>{{ item.record.soup }} cc</dd>
    <dt>お茶類</dt>
    <dd>{{ item.record.drink }} cc</dd>
  </dl>
  <div *ngIf="item.record.memo as memo" class="record-summary">{{ memo }}</div>
</div>
<div *ngIf="item | isTimelineItemType:'vital'">
  <dl [class.double-column]="!compact">
    <ng-container *ngIf="item.record.pulse">
      <dt>脈拍</dt>
      <dd>{{ item.record.pulse }} 回/分</dd>
    </ng-container>
    <ng-container *ngIf="item.record.respiration">
      <dt>呼吸</dt>
      <dd>{{ item.record.respiration }} 回/分</dd>
    </ng-container>
    <ng-container *ngIf="item.record.systolicBP">
      <dt>血圧(高)</dt>
      <dd>{{ item.record.systolicBP }} mmHg</dd>
    </ng-container>
    <ng-container *ngIf="item.record.diastolicBP">
      <dt>血圧(低)</dt>
      <dd>{{ item.record.diastolicBP }} mmHg</dd>
    </ng-container>
    <ng-container *ngIf="item.record.temperature">
      <dt>体温</dt>
      <dd>{{ item.record.temperature }} ℃</dd>
    </ng-container>
    <ng-container *ngIf="item.record.oxygen">
      <dt>酸素飽和度</dt>
      <dd>{{ item.record.oxygen }} %</dd>
    </ng-container>
    <ng-container *ngIf="item.record.weight">
      <dt>体重</dt>
      <dd>{{ item.record.weight }} kg</dd>
    </ng-container>
  </dl>
  <div *ngIf="item.record.memo as memo" class="record-summary">{{ memo }}</div>
</div>
<div *ngIf="item | isTimelineItemType:'excretion'">
  <dl [class.double-column]="!compact">
    <ng-container *ngIf="settings['care-excretion-useFailed']">
      <dt>失禁</dt>
      <dd>{{ item.record.isFailed ? 'あり' : 'なし' }}</dd>
    </ng-container>
    <ng-container *ngIf="settings['care-excretion-useImplement']">
      <dt>排泄用具</dt>
      <dd>{{ item.record.implement }}</dd>
    </ng-container>
    <dt>排尿</dt>
    <dd>{{ item.record | excretionDegreeLabel:'urine' }}</dd>
    <dt>排便</dt>
    <dd>{{ item.record | excretionDegreeLabel:'feces' }}</dd>
  </dl>
  <div *ngIf="item.record.memo as memo" class="record-summary">{{ memo }}</div>
</div>
<div *ngIf="item | isTimelineItemType:'bathing'">
  <dl>
    <dt>実施状況</dt>
    <dd>{{ item.record.isTook ? '実施' : '未実施' }}</dd>
    <ng-container *ngIf="settings['care-bathing-useType']">
      <dt>入浴方法</dt>
      <dd>{{ item.record.type }}</dd>
    </ng-container>
  </dl>
  <div *ngIf="item.record.memo as memo" class="record-summary">{{ memo }}</div>
</div>
<div *ngIf="item | isTimelineItemType:'transport'">
  <dl>
    <dt>種別</dt>
    <dd>{{ item.record.direction === 1 ? '行き' : '帰り' }}</dd>
    <dt>出発</dt>
    <dd>{{ item.record.departedAt }} ({{ item.record.departureNote }})</dd>
    <dt>到着</dt>
    <dd>{{ item.record.arrivedAt }} ({{ item.record.arrivalNote }})</dd>
  </dl>
  <div *ngIf="item.record.memo as memo" class="record-summary">{{ memo }}</div>
</div>
<div *ngIf="item | isTimelineItemType:'report'">
  <div class="flex gap-4" [ngClass]="compact ? 'flex-col' : 'flex-row'">
    <cm-badge [ngClass]="compact ? 'flex-initial' : 'basis-[120px]'" [name]="reportBadgeNames[item.record.kind]"></cm-badge>
    <dl>
      <dt>発生日時</dt>
      <dd>{{ item.record.occurredAt | date:'medium' }}</dd>
      <dt>発生場所</dt>
      <dd>{{ item.record.place }}</dd>
    </dl>
  </div>
  <div *ngIf="item.record.summary as summary" class="record-summary">{{ summary }}</div>
</div>
<div *ngIf="item | isTimelineItemType:'schedule'">
  <div><strong>{{ item.record.title }}</strong></div>
  <div *ngIf="item.record.memo as memo" class="record-summary">{{ memo }}</div>
</div>
<div *ngIf="item | isTimelineItemType:'note'" class="record-summary">{{ item.record.content }}</div>
