import { Component, Inject, INJECTOR, Injector, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { TypedCareDetailPageTrait } from 'src/app/classes/care-detail-page-trait';
import { TypedDetailPageTrait } from 'src/app/classes/detail-page-trait';
import { PageBase } from 'src/app/classes/page-base';
import { ScrollContentComponent } from 'src/app/shared/components/scroll-content/scroll-content.component';
import { Mixin } from 'src/app/utils/mixin';
import { TransportRecord, TransportRecordApi } from 'src/loopback';

@Component({
  selector: 'cm-care-detail-transport',
  templateUrl: './care-detail-transport.component.html',
  styleUrls: ['./care-detail-transport.component.scss'],
})
export class CareDetailTransportComponent extends Mixin(
  PageBase,
  TypedDetailPageTrait(TransportRecord),
  TypedCareDetailPageTrait(TransportRecord),
) implements OnInit {

  isDialog = true;
  preventRedirectAfterCreate = true;
  titleBase = '送迎記録';
  model = TransportRecord;
  canGoBack = true;

  get isDirty() {
    return this.form.dirty;
  }

  @ViewChild(ScrollContentComponent) content: ScrollContentComponent;

  constructor(
    private recordApi: TransportRecordApi,
    @Inject(INJECTOR) injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.init().subscribe(
      result => {
        this.setPropertyFromRecord(result);
        this.setTitle(this.generateTitle(result));
      },
    );
  }

  fetch(id: number) {
    return this.recordApi.findById(id, {
      include: this.recordInclusionDefaults,
    });
  }

  save(data: TransportRecord): Observable<TransportRecord | TransportRecord[]> {
    if (this.id) {
      return this.recordApi.patchAttributes(this.id, data);
    } else {
      if (this.residents) {
        const arr = this.residents.map(r => {
          return { ...data, residentId: r.id, officeId: r.officeId };
        });
        return this.recordApi.createMany(arr);
      } else {
        data.residentId = this.resident.id;
        data.officeId = this.resident.officeId;
        return this.recordApi.create(data);
      }
    }
  }

}
