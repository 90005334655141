import { Component, Inject, INJECTOR, Injector, OnInit, ViewChild } from '@angular/core';
import { mapTo, mergeMap, tap } from 'rxjs/operators';
import { TypedCareDetailPageTrait } from 'src/app/classes/care-detail-page-trait';
import { TypedDetailPageTrait } from 'src/app/classes/detail-page-trait';
import { PageBase } from 'src/app/classes/page-base';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { ScrollContentComponent } from 'src/app/shared/components/scroll-content/scroll-content.component';
import { Mixin } from 'src/app/utils/mixin';
import { VitalRecord, VitalRecordApi } from 'src/loopback';

@Component({
  selector: 'cm-care-detail-vital',
  templateUrl: './care-detail-vital.component.html',
  styleUrls: ['./care-detail-vital.component.scss'],
})
export class CareDetailVitalComponent extends Mixin(
  PageBase,
  TypedDetailPageTrait(VitalRecord),
  TypedCareDetailPageTrait(VitalRecord),
) implements OnInit {

  isDialog = true;
  preventRedirectAfterCreate = true;
  titleBase = 'バイタル記録';
  canGoBack = true;
  model = VitalRecord;
  standardValues: { [prop: string]: { min: number; max: number } } = {};

  validators = {};

  validationMessages = {
    pulse: {
      required: '脈拍を入力してください。',
    },
    respiration: {
      required: '呼吸を入力してください。',
    },
    systolicBP: {
      required: '血圧（高）を入力してください。',
    },
    diastolicBP: {
      required: '血圧（低）を入力してください。',
    },
    temperature: {
      required: '体温を入力してください。',
    },
    oxygen: {
      required: '酸素飽和度を入力してください。',
    },
    weight: {
      required: '体重を入力してください。',
    },
  };

  @ViewChild(ScrollContentComponent) content: ScrollContentComponent;

  constructor(
    @Inject(INJECTOR) injector: Injector,
    private vitalRecordApi: VitalRecordApi,
    private appSettings: AppSettingsService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.init().pipe(
      tap(result => {
        if (!result) {
          this.form.get('pulse').setValue(null);
          this.form.get('respiration').setValue(null);
          this.form.get('systolicBP').setValue(null);
          this.form.get('diastolicBP').setValue(null);
          this.form.get('temperature').setValue(null);
          this.form.get('oxygen').setValue(null);
          this.form.get('weight').setValue(null);
        }
        this.setPropertyFromRecord(result);
        this.setTitle(this.generateTitle(result));
      }),
      mergeMap(result => {
        const officeId = this.resident?.officeId ?? this.residents[0].officeId;

        return this.appSettings.getAll(officeId).pipe(
          tap(settings => {
            this.standardValues = {
              pulse: settings['care-vital-pulseStandard'],
              respiration: settings['care-vital-respirationStandard'],
              systolicBP: settings['care-vital-systolicBPStandard'],
              diastolicBP: settings['care-vital-diastolicBPStandard'],
              temperature: settings['care-vital-temperatureStandard'],
              oxygen: settings['care-vital-oxygenStandard'],
            };
          }),
          mapTo(result),
        );
      }),
    ).subscribe();
  }

  fetch(id: number) {
    return this.vitalRecordApi.findById(id, {
      include: this.recordInclusionDefaults,
    });
  }

  save(data: VitalRecord) {
    if (this.id) {
      return this.vitalRecordApi.patchAttributes(this.id, data);
    } else {
      if (this.residents) {
        const arr = this.residents.map(r => {
          return { ...data, residentId: r.id };
        });
        return this.vitalRecordApi.createMany(arr);
      } else {
        data.residentId = this.resident.id;
        return this.vitalRecordApi.create(data);
      }
    }
  }

}
