import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {

  transform(value: moment.MomentInput): number {
    const birthday = moment(value);

    return moment().diff(birthday, 'years');
  }

}
