import { Component, HostBinding, Input, Optional, Self, ViewChild } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatFormFieldControl } from '@angular/material/form-field';
import * as moment from 'moment';
import { FormFieldControlBase } from 'src/app/classes/form-field-control-base';
import { CM_MONTH_SELECTOR_FORMATS } from 'src/app/constants';
import { MonthSelectorCalendarHeaderComponent } from '../month-selector-calendar-header/month-selector-calendar-header.component';

@Component({
  selector: 'cm-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: MonthPickerComponent },
    { provide: MAT_DATE_FORMATS, useValue: CM_MONTH_SELECTOR_FORMATS },
  ],
})
export class MonthPickerComponent extends FormFieldControlBase<moment.Moment> {
  static idSeq = 0;
  controlType = 'month-picker';
  dateControl: FormControl<moment.Moment> = new FormControl();

  @Input() min: moment.Moment;
  @Input() max: moment.Moment;
  @HostBinding() id = `cm-month-picker-${MonthPickerComponent.idSeq++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @ViewChild('datepicker', { static: true }) datepicker: MatDatepicker<moment.Moment>;

  calendarHeader = MonthSelectorCalendarHeaderComponent;

  @Input()
  get value(): moment.Moment {
    return this.dateControl.value;
  }
  set value(value: moment.Moment) {
    if (!moment.isMoment(value)) {
      value = moment(value);
    }
    this.dateControl.setValue(value);
    this.stateChanges.next();
  }

  get empty() {
    return !this.dateControl.value;
  }

  constructor(
    @Optional() @Self() ngControl: NgControl,
  ) {
    super(ngControl);
  }

  onContainerClick() {
    this.datepicker.open();
  }

}
