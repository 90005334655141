<table class="w-full plan-table mb-8">
  <colgroup>
    <col class="time-col">
    <col *ngFor="let column of columns" [style.width.%]="100 / columns.length">
  </colgroup>
  <thead>
    <tr>
      <th class="time-col"></th>
      <th *ngFor="let column of columns">{{ column }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of rows" [style.height.px]="rowHeight">
      <th class="time-col" scope="row">{{ row.label }}</th>
      <td *ngFor="let column of columns" class="data-cell" (click)="openNewItemEditor(row.value, column)">
        <div *ngIf="getDetail(row.value, column) as detail" [ngClass]="['detail-chip', type]"
          [style.height.px]="rowHeight * getDuration(detail) - 1" (click)="$event.stopPropagation();openItemEditor(detail)">
          {{ detail.content }}
        </div>
      </td>
    </tr>
    <tr *ngIf="type === 'daily'" [style.height.px]="rowHeight * 1.5">
      <th>随時実施</th>
      <td *ngFor="let column of columns" class="data-cell" (click)="openNewItemEditor(-1, column)">
        <div *ngIf="getDetail(-1, column) as detail" [ngClass]="['detail-chip', type]"
          [style.height.px]="rowHeight * 1.5 - 1"
          (click)="$event.stopPropagation();openItemEditor(detail)">
          {{ detail.content }}
        </div>
      </td>
    </tr>
  </tbody>
</table>

<table class="w-full plan-table">
  <tbody>
    <tr [style.height.px]="rowHeight * 1.5">
      <th scope="row" class="other-cell other-label">{{ otherCategory }}</th>
      <td class="data-cell other-cell" (click)="openNewItemEditor(-1, otherCategory)">
        <div *ngIf="getDetail(-1, otherCategory) as detail" [ngClass]="['detail-chip', type]"
          [style.height.px]="rowHeight * 1.5 - 1"
          (click)="$event.stopPropagation();openItemEditor(detail)">
          {{ detail.content }}
        </div>
      </td>
    </tr>
  </tbody>
</table>
