/* eslint-disable */
import {
  Resident,
  Staff,
  Company,
  Office,
  PlanHistory,
  AssessmentDetail,
  GenericContent
} from '../index';

declare var Object: any;
export interface AssessmentInterface {
  "oldMemory"?: string;
  "recentMemory"?: string;
  "procedualMemory"?: string;
  "orientationPlace"?: string;
  "orientationTime"?: string;
  "orientationPerson"?: string;
  "remark"?: string;
  "id"?: number;
  "residentId"?: number;
  "staffId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "recordedAt"?: Date|string;
  "previousId"?: number;
  "updaterId"?: number;
  "_adls"?: Array<any>;
  "_iadls"?: Array<any>;
  "_episodes"?: Array<any>;
  resident?: Resident;
  staff?: Staff;
  company?: Company;
  office?: Office;
  history?: PlanHistory;
  previous?: Assessment;
  next?: Assessment;
  adls?: AssessmentDetail[];
  iadls?: AssessmentDetail[];
  episodes?: GenericContent[];
}

export class Assessment implements AssessmentInterface {
  "oldMemory": string = '';
  "recentMemory": string = '';
  "procedualMemory": string = '';
  "orientationPlace": string = '';
  "orientationTime": string = '';
  "orientationPerson": string = '';
  "remark": string = '';
  "id": number = <any>null;
  "residentId": number = <any>null;
  "staffId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "recordedAt": Date|string = <any>null;
  "previousId": number = <any>null;
  "updaterId": number = <any>null;
  "_adls": Array<any> = <any>[];
  "_iadls": Array<any> = <any>[];
  "_episodes": Array<any> = <any>[];
  resident?: Resident;
  staff?: Staff;
  company?: Company;
  office?: Office;
  history?: PlanHistory;
  previous?: Assessment;
  next?: Assessment;
  adls?: AssessmentDetail[];
  iadls?: AssessmentDetail[];
  episodes?: GenericContent[];
  constructor(data?: Partial<AssessmentInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Assessment`.
   */
  public static getModelName() {
    return "Assessment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Assessment for dynamic purposes.
  **/
  public static factory(data: AssessmentInterface): Assessment{
    return new Assessment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Assessment',
      plural: 'Assessments',
      path: 'Assessments',
      idName: 'id',
      properties: {
        "oldMemory": {
          name: 'oldMemory',
          type: 'string'
        },
        "recentMemory": {
          name: 'recentMemory',
          type: 'string'
        },
        "procedualMemory": {
          name: 'procedualMemory',
          type: 'string'
        },
        "orientationPlace": {
          name: 'orientationPlace',
          type: 'string'
        },
        "orientationTime": {
          name: 'orientationTime',
          type: 'string'
        },
        "orientationPerson": {
          name: 'orientationPerson',
          type: 'string'
        },
        "remark": {
          name: 'remark',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "recordedAt": {
          name: 'recordedAt',
          type: 'Date|string',
          default: <any>null
        },
        "previousId": {
          name: 'previousId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "_adls": {
          name: '_adls',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "_iadls": {
          name: '_iadls',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "_episodes": {
          name: '_episodes',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        history: {
          name: 'history',
          type: 'PlanHistory',
          model: 'PlanHistory',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'recordId'
        },
        previous: {
          name: 'previous',
          type: 'Assessment',
          model: 'Assessment',
          relationType: 'belongsTo',
                  keyFrom: 'previousId',
          keyTo: 'id'
        },
        next: {
          name: 'next',
          type: 'Assessment',
          model: 'Assessment',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'previousId'
        },
        adls: {
          name: 'adls',
          type: 'AssessmentDetail[]',
          model: 'AssessmentDetail',
          relationType: 'embedsMany',
                  keyFrom: '_adls',
          keyTo: 'id'
        },
        iadls: {
          name: 'iadls',
          type: 'AssessmentDetail[]',
          model: 'AssessmentDetail',
          relationType: 'embedsMany',
                  keyFrom: '_iadls',
          keyTo: 'id'
        },
        episodes: {
          name: 'episodes',
          type: 'GenericContent[]',
          model: 'GenericContent',
          relationType: 'embedsMany',
                  keyFrom: '_episodes',
          keyTo: 'id'
        },
      }
    }
  }
}
