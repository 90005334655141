import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment';
import { CM_MONTH_SELECTOR_FORMATS } from 'src/app/constants';
import { MonthSelectorCalendarHeaderComponent } from '../month-selector-calendar-header/month-selector-calendar-header.component';

@Component({
  selector: 'cm-month-selector',
  templateUrl: './month-selector.component.html',
  styleUrls: ['./month-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: CM_MONTH_SELECTOR_FORMATS},
  ],
})
export class MonthSelectorComponent  {

  @Input() dateControl = new FormControl(moment());
  @Input() min: moment.Moment;
  @Input() max = moment();
  @Output() dateChange = new EventEmitter<moment.Moment>();
  calendarHeader = MonthSelectorCalendarHeaderComponent;

  constructor() { }

  onPrev() {
    const prev = this.dateControl.value.subtract(1, 'month');
    this.dateControl.setValue(prev);
  }

  onNext() {
    const next = this.dateControl.value.add(1, 'month');
    this.dateControl.setValue(next);
  }

  onMonthSelected(date: moment.Moment) {
    this.dateControl.setValue(date);
  }

}
