/* eslint-disable */
import {
  Resident,
  Company,
  Office,
  Room
} from '../index';

declare var Object: any;
export interface MoveHistoryInterface {
  "movedInAt"?: Date|string;
  "conditionBefore"?: string;
  "remarkBefore"?: string;
  "movedOutAt"?: Date|string;
  "conditionAfter"?: string;
  "remarkAfter"?: string;
  "type"?: number;
  "id"?: number;
  "residentId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "updaterId"?: number;
  "roomId"?: number;
  resident?: Resident;
  company?: Company;
  office?: Office;
  room?: Room;
}

export class MoveHistory implements MoveHistoryInterface {
  "movedInAt": Date|string = <any>null;
  "conditionBefore": string = '';
  "remarkBefore": string = '';
  "movedOutAt": Date|string = <any>null;
  "conditionAfter": string = '';
  "remarkAfter": string = '';
  "type": number = <any>null;
  "id": number = <any>null;
  "residentId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "updaterId": number = <any>null;
  "roomId": number = <any>null;
  resident?: Resident;
  company?: Company;
  office?: Office;
  room?: Room;
  constructor(data?: Partial<MoveHistoryInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MoveHistory`.
   */
  public static getModelName() {
    return "MoveHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MoveHistory for dynamic purposes.
  **/
  public static factory(data: MoveHistoryInterface): MoveHistory{
    return new MoveHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MoveHistory',
      plural: 'MoveHistories',
      path: 'MoveHistories',
      idName: 'id',
      properties: {
        "movedInAt": {
          name: 'movedInAt',
          type: 'Date|string'
        },
        "conditionBefore": {
          name: 'conditionBefore',
          type: 'string'
        },
        "remarkBefore": {
          name: 'remarkBefore',
          type: 'string'
        },
        "movedOutAt": {
          name: 'movedOutAt',
          type: 'Date|string'
        },
        "conditionAfter": {
          name: 'conditionAfter',
          type: 'string'
        },
        "remarkAfter": {
          name: 'remarkAfter',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "roomId": {
          name: 'roomId',
          type: 'number'
        },
      },
      relations: {
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        room: {
          name: 'room',
          type: 'Room',
          model: 'Room',
          relationType: 'belongsTo',
                  keyFrom: 'roomId',
          keyTo: 'id'
        },
      }
    }
  }
}
