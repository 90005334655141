import { Pipe, PipeTransform } from '@angular/core';
import { Absence } from 'src/loopback';
import * as moment from 'moment';

@Pipe({
  name: 'absenceBadge',
})
export class AbsenceBadgePipe implements PipeTransform {

  typeLabels: { [n: number]: string } = {
    1: '外泊',
    2: '入院',
  };

  transform(absence: Absence, target: 'color' | 'label'): any {
    if (!absence) {
      return null;
    }

    const isFuture = moment().isBefore(absence.startAt, 'day');
    const start = moment(absence.startAt).format('M月D日');

    if (target === 'color') {
      return isFuture ? 'accent' : 'warn';
    }

    if (target === 'label') {
      return isFuture ? `${start}から${this.typeLabels[absence.type]}予定です` : `${this.typeLabels[absence.type]}中です`;
    }
  }

}
