import { Injectable, SimpleChange } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

interface State {
  value: any;
  subject: BehaviorSubject<SimpleChange>;
  changed: boolean;
}

/**
 * ステート管理サービス
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  private states: { [name: string]: State } = {};

  /**
   * ステートの変更を購読
   *
   * @param name ステート名
   * @returns
   */
  onChanged(name: string): Observable<SimpleChange> {
    const state = this.getOrCreateState(name);

    return state.subject.asObservable();
  }

  /**
   * 現在のステート値を取得
   *
   * @param name ステート名
   * @returns
   */
  get(name: string): any {
    const state = this.getOrCreateState(name);

    return state.value;
  }

  /**
   * ステート値を設定
   *
   * @param name ステート名
   * @param value 値
   */
  set(name: string, value: any) {
    const state = this.getOrCreateState(name);

    if (state.value !== value) {
      const change = new SimpleChange(state.value, value, !state.changed);
      state.subject.next(change);
      state.value = value;
      state.changed = true;
    }
  }

  private getOrCreateState(name: string): State {
    let state = this.states[name];

    if (state === undefined) {
      const initial = new SimpleChange(null, null, undefined);

      this.states[name] = state = {
        value: null,
        subject: new BehaviorSubject<SimpleChange>(initial),
        changed: false,
      };
    }

    return state;
  }
}
