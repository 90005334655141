/* eslint-disable */
import {
  Plan2Detail
} from '../index';

declare var Object: any;
export interface PlanMonitoringDetailInterface {
  "id"?: string;
  "effect"?: number;
  "satisfaction"?: number;
  "serviceVolume"?: number;
  "evaluation"?: number;
  "comment"?: string;
  "isPrintable"?: boolean;
  "itemId"?: string;
  "plan2DetailId"?: string;
  item?: Plan2Detail;
}

export class PlanMonitoringDetail implements PlanMonitoringDetailInterface {
  "id": string = '';
  "effect": number = <any>null;
  "satisfaction": number = <any>null;
  "serviceVolume": number = <any>null;
  "evaluation": number = <any>null;
  "comment": string = '';
  "isPrintable": boolean = false;
  "itemId": string = '';
  "plan2DetailId": string = '';
  item?: Plan2Detail;
  constructor(data?: Partial<PlanMonitoringDetailInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PlanMonitoringDetail`.
   */
  public static getModelName() {
    return "PlanMonitoringDetail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PlanMonitoringDetail for dynamic purposes.
  **/
  public static factory(data: PlanMonitoringDetailInterface): PlanMonitoringDetail{
    return new PlanMonitoringDetail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PlanMonitoringDetail',
      plural: 'PlanMonitoringDetails',
      path: 'PlanMonitoringDetails',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "effect": {
          name: 'effect',
          type: 'number'
        },
        "satisfaction": {
          name: 'satisfaction',
          type: 'number'
        },
        "serviceVolume": {
          name: 'serviceVolume',
          type: 'number'
        },
        "evaluation": {
          name: 'evaluation',
          type: 'number'
        },
        "comment": {
          name: 'comment',
          type: 'string'
        },
        "isPrintable": {
          name: 'isPrintable',
          type: 'boolean'
        },
        "itemId": {
          name: 'itemId',
          type: 'string'
        },
        "plan2DetailId": {
          name: 'plan2DetailId',
          type: 'string'
        },
      },
      relations: {
        item: {
          name: 'item',
          type: 'Plan2Detail',
          model: 'Plan2Detail',
          relationType: 'belongsTo',
                  keyFrom: 'itemId',
          keyTo: 'id'
        },
      }
    }
  }
}
