import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from 'src/app/services/dialog.service';
import { GenericContent } from 'src/loopback';

@Component({
  selector: 'cm-episode-editor',
  templateUrl: './episode-editor.component.html',
  styleUrls: ['./episode-editor.component.scss'],
})
export class EpisodeEditorComponent extends DialogComponentBase<GenericContent, Partial<GenericContent>> implements OnInit {

  form: FormGroup<{ title: FormControl<string>; body: FormControl<string> }>;

  get okDisabled() { return false; }

  constructor(
    private formBuilder: FormBuilder,
    protected ref: MatDialogRef<EpisodeEditorComponent, Partial<GenericContent>>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: GenericContent,
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: [''],
      body: [''],
    });

    if (this.data) {
      this.form.reset(this.data);
    }
  }

  onOkClick() {
    this.ref.close(this.form.value);
  }

}
