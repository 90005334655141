import { Component, Input, HostBinding } from '@angular/core';
import { Resident, Room } from 'src/loopback';

@Component({
  selector: 'cm-resident-name-card',
  templateUrl: './resident-name-card.component.html',
  styleUrls: ['./resident-name-card.component.scss'],
})
export class ResidentNameCardComponent  {

  @Input() resident: Resident;
  @Input() room: Room;
  @Input() size: ResidentNameCardSize = 'medium';
  @Input() avatarHidden = false;
  @Input() nameHidden = false;
  @Input() avatarPosition: ResidentNameCardAvatarPosition = 'left';
  @HostBinding('class.medium') get hostClassMedium() { return this.size === 'medium'; }
  @HostBinding('class.small') get hostClassSmall() { return this.size === 'small'; }
  @HostBinding('class.avatar-left') get hostClassAvatarLeft() { return this.avatarPosition === 'left'; }
  @HostBinding('class.avatar-right') get hostClassAvatarRight() { return this.avatarPosition === 'right'; }

  constructor() { }

}

export type ResidentNameCardSize = 'medium' | 'small';
export type ResidentNameCardAvatarPosition = 'left' | 'right';
