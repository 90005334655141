<div mat-dialog-title class="flex flex-row justify-between items-baseline">
  <span>入居者を選択</span>
  <small class="mat-body-2">
    <mat-checkbox [checked]="onlyOccupying" (change)="toggleFilterOnlyOccupying($event.checked)">入居中のみ</mat-checkbox>
  </small>
</div>
<div mat-dialog-content>
  <mat-action-list>
    <button mat-list-item *ngFor="let resident of residents" (click)="onSelect(resident)">
      <cm-resident-name-card [resident]="resident" [room]="resident.moveHistories[0]?.room" size="small"></cm-resident-name-card>
    </button>
  </mat-action-list>
</div>
