<div mat-dialog-title [ngSwitch]="mode">
  <ng-template ngSwitchCase="create">情報の登録</ng-template>
  <ng-template ngSwitchCase="edit">情報の編集</ng-template>
  <ng-template ngSwitchCase="modify">区分変更申請</ng-template>
  <ng-template ngSwitchCase="renew">更新申請</ng-template>
  <ng-template ngSwitchCase="confirm">情報の確定</ng-template>
</div>
<div mat-dialog-content [formGroup]="form">
  <p class="description mb-4" [ngSwitch]="mode">
    <ng-template ngSwitchCase="create">
      現在有効な被保険者証の情報を入力してください。
    </ng-template>
    <ng-template ngSwitchCase="modify">
      区分変更申請日を入力してOKボタンを押してください。
    </ng-template>
    <ng-template ngSwitchCase="renew">
      更新の申請を行ったらOKボタンを押してください。
    </ng-template>
    <ng-template ngSwitchCase="confirm">
      更新/区分変更は現在申請中です。認定が完了したら内容を入力してOKボタンを押してください。
    </ng-template>
  </p>
  <mat-form-field *ngIf="detailRequired" class="w-full">
    <mat-label>要介護度</mat-label>
    <mat-select formControlName="certification">
      <mat-option *ngFor="let o of opt.insuranceCertification" [value]="o.value">{{ o.label }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="detailRequired" class="w-full">
    <mat-label>被保険者番号</mat-label>
    <input matInput formControlName="insuredNumber">
  </mat-form-field>
  <mat-form-field *ngIf="detailRequired" class="w-full">
    <mat-label>保険者番号</mat-label>
    <input matInput formControlName="insurerNumber">
  </mat-form-field>
  <mat-form-field *ngIf="mode === 'modify'" class="w-full" floatLabel="always">
    <mat-label>申請日</mat-label>
    <cm-wareki-date-input formControlName="startAt" [required]="true"></cm-wareki-date-input>
    <mat-hint *ngIf="form.controls['startAt'].errors as errors">
      <ng-container *ngIf="errors['startAfterExpire']">開始日は終了日より前にしてください</ng-container>
      <ng-container *ngIf="errors['startBeforePrevious']">開始日は前回終了日より後にしてください</ng-container>
    </mat-hint>
  </mat-form-field>
  <mat-form-field *ngIf="detailRequired" class="w-full" floatLabel="always">
    <mat-label>認定日</mat-label>
    <cm-wareki-date-input formControlName="certifiedAt" [required]="true"></cm-wareki-date-input>
  </mat-form-field>
  <div *ngIf="detailRequired" class="p-4 bg-neutral-100">
    <h4>認定の有効期間</h4>
    <mat-form-field class="w-full" floatLabel="always">
      <mat-label>開始日</mat-label>
      <cm-wareki-date-input formControlName="startAt" [required]="true"></cm-wareki-date-input>
      <mat-hint *ngIf="form.controls['startAt'].errors as errors">
        <ng-container *ngIf="errors['startAfterExpire']">開始日は終了日より前にしてください</ng-container>
        <ng-container *ngIf="errors['startBeforePrevious']">開始日は前回終了日より後にしてください</ng-container>
      </mat-hint>
    </mat-form-field>
    <mat-form-field class="w-full" floatLabel="always">
      <mat-label>終了日</mat-label>
      <cm-wareki-date-input formControlName="expiredAt" [required]="true"></cm-wareki-date-input>
      <mat-hint *ngIf="form.controls['expiredAt'].errors as errors">
        <ng-container *ngIf="errors['expireBeforeStart']">終了日は開始日より後にしてください</ng-container>
        <ng-container *ngIf="errors['expireBeforePrevious']">終了日は前回終了日より後にしてください</ng-container>
      </mat-hint>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>キャンセル</button>
  <button mat-stroked-button color="primary" [disabled]="form.invalid || isSaving" (click)="onOkClick()">OK</button>
</div>
