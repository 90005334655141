<div mat-dialog-title>テンプレートを選択</div>
<div mat-dialog-content>
  <table mat-table matSort [dataSource]="templates"
    class="w-full template-list">
    <ng-container matColumnDef="isFailed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>失禁</th>
      <td mat-cell *matCellDef="let row">{{ row.isFailed ? 'あり' : 'なし' }}</td>
    </ng-container>

    <ng-container matColumnDef="implement">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>排泄用具</th>
      <td mat-cell *matCellDef="let row">{{ row.implement }}</td>
    </ng-container>

    <ng-container matColumnDef="urineDegree">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>排尿量</th>
      <td mat-cell *matCellDef="let row">{{ row | excretionDegreeLabel:'urine' }}</td>
    </ng-container>

    <ng-container matColumnDef="fecesDegree">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>排便量</th>
      <td mat-cell *matCellDef="let row">{{ row | excretionDegreeLabel:'feces' }}</td>
    </ng-container>

    <ng-container matColumnDef="memo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>メモ</th>
      <td mat-cell *matCellDef="let row">{{ row.memo }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columns" (click)="selectTemplate(row)"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>キャンセル</button>
</div>
