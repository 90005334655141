/* eslint-disable */
import {
  Staff,
  Resident,
  Company,
  Office,
  CareHistory,
  Note,
  ReportReview,
  ReportConfirmation
} from '../index';

declare var Object: any;
export interface ReportInterface {
  "kind"?: number;
  "claimant"?: string;
  "claimantInfo"?: string;
  "claimantKind"?: number;
  "occurredAt"?: Date|string;
  "place"?: string;
  "receptionWay"?: number;
  "summary"?: string;
  "reaction"?: string;
  "hospital"?: string;
  "treatment"?: string;
  "contactState"?: string;
  "isCharged"?: boolean;
  "managerName"?: string;
  "comment"?: string;
  "isShared"?: boolean;
  "id"?: number;
  "staffId"?: number;
  "residentId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "recordedAt"?: Date|string;
  "noteContent"?: string;
  "updaterId"?: number;
  staff?: Staff;
  resident?: Resident;
  company?: Company;
  office?: Office;
  history?: CareHistory;
  note?: Note;
  review?: ReportReview;
  confirmations?: ReportConfirmation[];
}

export class Report implements ReportInterface {
  "kind": number = <any>null;
  "claimant": string = '';
  "claimantInfo": string = '';
  "claimantKind": number = <any>null;
  "occurredAt": Date|string = <any>null;
  "place": string = '';
  "receptionWay": number = <any>null;
  "summary": string = '';
  "reaction": string = '';
  "hospital": string = '';
  "treatment": string = '';
  "contactState": string = '';
  "isCharged": boolean = false;
  "managerName": string = '';
  "comment": string = '';
  "isShared": boolean = false;
  "id": number = <any>null;
  "staffId": number = <any>null;
  "residentId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "recordedAt": Date|string = <any>null;
  "noteContent": string = '';
  "updaterId": number = <any>null;
  staff?: Staff;
  resident?: Resident;
  company?: Company;
  office?: Office;
  history?: CareHistory;
  note?: Note;
  review?: ReportReview;
  confirmations?: ReportConfirmation[];
  constructor(data?: Partial<ReportInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Report`.
   */
  public static getModelName() {
    return "Report";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Report for dynamic purposes.
  **/
  public static factory(data: ReportInterface): Report{
    return new Report(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Report',
      plural: 'Reports',
      path: 'Reports',
      idName: 'id',
      properties: {
        "kind": {
          name: 'kind',
          type: 'number'
        },
        "claimant": {
          name: 'claimant',
          type: 'string'
        },
        "claimantInfo": {
          name: 'claimantInfo',
          type: 'string'
        },
        "claimantKind": {
          name: 'claimantKind',
          type: 'number'
        },
        "occurredAt": {
          name: 'occurredAt',
          type: 'Date|string'
        },
        "place": {
          name: 'place',
          type: 'string'
        },
        "receptionWay": {
          name: 'receptionWay',
          type: 'number'
        },
        "summary": {
          name: 'summary',
          type: 'string'
        },
        "reaction": {
          name: 'reaction',
          type: 'string'
        },
        "hospital": {
          name: 'hospital',
          type: 'string'
        },
        "treatment": {
          name: 'treatment',
          type: 'string'
        },
        "contactState": {
          name: 'contactState',
          type: 'string'
        },
        "isCharged": {
          name: 'isCharged',
          type: 'boolean'
        },
        "managerName": {
          name: 'managerName',
          type: 'string'
        },
        "comment": {
          name: 'comment',
          type: 'string'
        },
        "isShared": {
          name: 'isShared',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "recordedAt": {
          name: 'recordedAt',
          type: 'Date|string',
          default: <any>null
        },
        "noteContent": {
          name: 'noteContent',
          type: 'string'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        history: {
          name: 'history',
          type: 'CareHistory',
          model: 'CareHistory',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'recordId'
        },
        note: {
          name: 'note',
          type: 'Note',
          model: 'Note',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'relatedRecordId'
        },
        review: {
          name: 'review',
          type: 'ReportReview',
          model: 'ReportReview',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'reportId'
        },
        confirmations: {
          name: 'confirmations',
          type: 'ReportConfirmation[]',
          model: 'ReportConfirmation',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'referenceId'
        },
      }
    }
  }
}
