/* eslint-disable */
import {
  Staff,
  Resident,
  Company,
  Office,
  PlanHistory,
  VisitSummary,
  VisitReport,
  VisitPlanDetail
} from '../index';

declare var Object: any;
export interface VisitPlanInterface {
  "contactRelationship"?: string;
  "contactName"?: string;
  "contactZipCode"?: string;
  "contactAddress"?: string;
  "contactPhone"?: string;
  "clinicName"?: string;
  "clinicDoctor"?: string;
  "clinicZipCode"?: string;
  "clinicAddress"?: string;
  "clinicPhone"?: string;
  "supporterName"?: string;
  "supporterManager"?: string;
  "supporterPhone"?: string;
  "goal"?: string;
  "remark"?: string;
  "id"?: number;
  "staffId"?: number;
  "residentId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "recordedAt"?: Date|string;
  "previousId"?: number;
  "updaterId"?: number;
  "_details"?: Array<any>;
  staff?: Staff;
  resident?: Resident;
  company?: Company;
  office?: Office;
  history?: PlanHistory;
  previous?: VisitPlan;
  next?: VisitPlan;
  summaries?: VisitSummary[];
  reports?: VisitReport[];
  details?: VisitPlanDetail[];
}

export class VisitPlan implements VisitPlanInterface {
  "contactRelationship": string = '';
  "contactName": string = '';
  "contactZipCode": string = '';
  "contactAddress": string = '';
  "contactPhone": string = '';
  "clinicName": string = '';
  "clinicDoctor": string = '';
  "clinicZipCode": string = '';
  "clinicAddress": string = '';
  "clinicPhone": string = '';
  "supporterName": string = '';
  "supporterManager": string = '';
  "supporterPhone": string = '';
  "goal": string = '';
  "remark": string = '';
  "id": number = <any>null;
  "staffId": number = <any>null;
  "residentId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "recordedAt": Date|string = <any>null;
  "previousId": number = <any>null;
  "updaterId": number = <any>null;
  "_details": Array<any> = <any>[];
  staff?: Staff;
  resident?: Resident;
  company?: Company;
  office?: Office;
  history?: PlanHistory;
  previous?: VisitPlan;
  next?: VisitPlan;
  summaries?: VisitSummary[];
  reports?: VisitReport[];
  details?: VisitPlanDetail[];
  constructor(data?: Partial<VisitPlanInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VisitPlan`.
   */
  public static getModelName() {
    return "VisitPlan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of VisitPlan for dynamic purposes.
  **/
  public static factory(data: VisitPlanInterface): VisitPlan{
    return new VisitPlan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VisitPlan',
      plural: 'VisitPlans',
      path: 'VisitPlans',
      idName: 'id',
      properties: {
        "contactRelationship": {
          name: 'contactRelationship',
          type: 'string'
        },
        "contactName": {
          name: 'contactName',
          type: 'string'
        },
        "contactZipCode": {
          name: 'contactZipCode',
          type: 'string'
        },
        "contactAddress": {
          name: 'contactAddress',
          type: 'string'
        },
        "contactPhone": {
          name: 'contactPhone',
          type: 'string'
        },
        "clinicName": {
          name: 'clinicName',
          type: 'string'
        },
        "clinicDoctor": {
          name: 'clinicDoctor',
          type: 'string'
        },
        "clinicZipCode": {
          name: 'clinicZipCode',
          type: 'string'
        },
        "clinicAddress": {
          name: 'clinicAddress',
          type: 'string'
        },
        "clinicPhone": {
          name: 'clinicPhone',
          type: 'string'
        },
        "supporterName": {
          name: 'supporterName',
          type: 'string'
        },
        "supporterManager": {
          name: 'supporterManager',
          type: 'string'
        },
        "supporterPhone": {
          name: 'supporterPhone',
          type: 'string'
        },
        "goal": {
          name: 'goal',
          type: 'string'
        },
        "remark": {
          name: 'remark',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "recordedAt": {
          name: 'recordedAt',
          type: 'Date|string',
          default: <any>null
        },
        "previousId": {
          name: 'previousId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "_details": {
          name: '_details',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        history: {
          name: 'history',
          type: 'PlanHistory',
          model: 'PlanHistory',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'recordId'
        },
        previous: {
          name: 'previous',
          type: 'VisitPlan',
          model: 'VisitPlan',
          relationType: 'belongsTo',
                  keyFrom: 'previousId',
          keyTo: 'id'
        },
        next: {
          name: 'next',
          type: 'VisitPlan',
          model: 'VisitPlan',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'previousId'
        },
        summaries: {
          name: 'summaries',
          type: 'VisitSummary[]',
          model: 'VisitSummary',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'planId'
        },
        reports: {
          name: 'reports',
          type: 'VisitReport[]',
          model: 'VisitReport',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'planId'
        },
        details: {
          name: 'details',
          type: 'VisitPlanDetail[]',
          model: 'VisitPlanDetail',
          relationType: 'embedsMany',
                  keyFrom: '_details',
          keyTo: 'id'
        },
      }
    }
  }
}
