/* eslint-disable */
import {
  Company,
  Office,
  MoveHistory,
  CareHistory,
  Absence,
  PlanHistory,
  Assessment,
  Plan,
  FileInfo,
  GenericContent,
  Contact,
  InsuranceCard,
  VisitPlan,
  ScheduleEvent
} from '../index';

declare var Object: any;
export interface ResidentInterface {
  "firstName"?: string;
  "lastName"?: string;
  "firstNameKana"?: string;
  "lastNameKana"?: string;
  "sex"?: number;
  "birthday"?: Date|string;
  "zipCode"?: string;
  "address1"?: string;
  "address2"?: string;
  "phone"?: string;
  "mobilePhone"?: string;
  "fax"?: string;
  "email"?: string;
  "isMountainous"?: boolean;
  "isLivingAlone"?: boolean;
  "independentDegree"?: number;
  "isUnderInquiry"?: boolean;
  "isTrial"?: boolean;
  "isProvisional"?: boolean;
  "remarks"?: string;
  "residenceState"?: string;
  "neighborhoodState"?: string;
  "background"?: string;
  "placeWantToGo"?: string;
  "routine"?: string;
  "dietaryHabit"?: string;
  "interest"?: string;
  "medicalHistory"?: string;
  "medicinePrecaution"?: string;
  "otherMedicalInfo"?: string;
  "dementiaDocument"?: string;
  "dementiaFactor"?: string;
  "dementiaCondition"?: string;
  "firstPlanDate"?: Date|string;
  "status": number;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "updaterId"?: number;
  "fileIds"?: Array<any>;
  "_episodes"?: Array<any>;
  "_contacts"?: Array<any>;
  company?: Company;
  office?: Office;
  moveHistories?: MoveHistory[];
  careHistories?: CareHistory[];
  absences?: Absence[];
  planHistories?: PlanHistory[];
  assessments?: Assessment[];
  plans?: Plan[];
  photo?: FileInfo;
  files?: FileInfo[];
  episodes?: GenericContent[];
  contacts?: Contact[];
  insuranceCards?: InsuranceCard[];
  visitPlans?: VisitPlan[];
  scheduleEvents?: ScheduleEvent[];
}

export class Resident implements ResidentInterface {
  "firstName": string = '';
  "lastName": string = '';
  "firstNameKana": string = '';
  "lastNameKana": string = '';
  "sex": number = <any>null;
  "birthday": Date|string = <any>null;
  "zipCode": string = '';
  "address1": string = '';
  "address2": string = '';
  "phone": string = '';
  "mobilePhone": string = '';
  "fax": string = '';
  "email": string = '';
  "isMountainous": boolean = false;
  "isLivingAlone": boolean = false;
  "independentDegree": number = <any>null;
  "isUnderInquiry": boolean = false;
  "isTrial": boolean = false;
  "isProvisional": boolean = false;
  "remarks": string = '';
  "residenceState": string = '';
  "neighborhoodState": string = '';
  "background": string = '';
  "placeWantToGo": string = '';
  "routine": string = '';
  "dietaryHabit": string = '';
  "interest": string = '';
  "medicalHistory": string = '';
  "medicinePrecaution": string = '';
  "otherMedicalInfo": string = '';
  "dementiaDocument": string = '';
  "dementiaFactor": string = '';
  "dementiaCondition": string = '';
  "firstPlanDate": Date|string = <any>null;
  "status": number = 1;
  "id": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "updaterId": number = <any>null;
  "fileIds": Array<any> = <any>[];
  "_episodes": Array<any> = <any>[];
  "_contacts": Array<any> = <any>[];
  company?: Company;
  office?: Office;
  moveHistories?: MoveHistory[];
  careHistories?: CareHistory[];
  absences?: Absence[];
  planHistories?: PlanHistory[];
  assessments?: Assessment[];
  plans?: Plan[];
  photo?: FileInfo;
  files?: FileInfo[];
  episodes?: GenericContent[];
  contacts?: Contact[];
  insuranceCards?: InsuranceCard[];
  visitPlans?: VisitPlan[];
  scheduleEvents?: ScheduleEvent[];
  constructor(data?: Partial<ResidentInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Resident`.
   */
  public static getModelName() {
    return "Resident";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Resident for dynamic purposes.
  **/
  public static factory(data: ResidentInterface): Resident{
    return new Resident(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Resident',
      plural: 'Residents',
      path: 'Residents',
      idName: 'id',
      properties: {
        "firstName": {
          name: 'firstName',
          type: 'string'
        },
        "lastName": {
          name: 'lastName',
          type: 'string'
        },
        "firstNameKana": {
          name: 'firstNameKana',
          type: 'string'
        },
        "lastNameKana": {
          name: 'lastNameKana',
          type: 'string'
        },
        "sex": {
          name: 'sex',
          type: 'number'
        },
        "birthday": {
          name: 'birthday',
          type: 'Date|string'
        },
        "zipCode": {
          name: 'zipCode',
          type: 'string'
        },
        "address1": {
          name: 'address1',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "mobilePhone": {
          name: 'mobilePhone',
          type: 'string'
        },
        "fax": {
          name: 'fax',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "isMountainous": {
          name: 'isMountainous',
          type: 'boolean'
        },
        "isLivingAlone": {
          name: 'isLivingAlone',
          type: 'boolean'
        },
        "independentDegree": {
          name: 'independentDegree',
          type: 'number'
        },
        "isUnderInquiry": {
          name: 'isUnderInquiry',
          type: 'boolean'
        },
        "isTrial": {
          name: 'isTrial',
          type: 'boolean'
        },
        "isProvisional": {
          name: 'isProvisional',
          type: 'boolean'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "residenceState": {
          name: 'residenceState',
          type: 'string'
        },
        "neighborhoodState": {
          name: 'neighborhoodState',
          type: 'string'
        },
        "background": {
          name: 'background',
          type: 'string'
        },
        "placeWantToGo": {
          name: 'placeWantToGo',
          type: 'string'
        },
        "routine": {
          name: 'routine',
          type: 'string'
        },
        "dietaryHabit": {
          name: 'dietaryHabit',
          type: 'string'
        },
        "interest": {
          name: 'interest',
          type: 'string'
        },
        "medicalHistory": {
          name: 'medicalHistory',
          type: 'string'
        },
        "medicinePrecaution": {
          name: 'medicinePrecaution',
          type: 'string'
        },
        "otherMedicalInfo": {
          name: 'otherMedicalInfo',
          type: 'string'
        },
        "dementiaDocument": {
          name: 'dementiaDocument',
          type: 'string'
        },
        "dementiaFactor": {
          name: 'dementiaFactor',
          type: 'string'
        },
        "dementiaCondition": {
          name: 'dementiaCondition',
          type: 'string'
        },
        "firstPlanDate": {
          name: 'firstPlanDate',
          type: 'Date|string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "fileIds": {
          name: 'fileIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "_episodes": {
          name: '_episodes',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "_contacts": {
          name: '_contacts',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        moveHistories: {
          name: 'moveHistories',
          type: 'MoveHistory[]',
          model: 'MoveHistory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'residentId'
        },
        careHistories: {
          name: 'careHistories',
          type: 'CareHistory[]',
          model: 'CareHistory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'residentId'
        },
        absences: {
          name: 'absences',
          type: 'Absence[]',
          model: 'Absence',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'residentId'
        },
        planHistories: {
          name: 'planHistories',
          type: 'PlanHistory[]',
          model: 'PlanHistory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'residentId'
        },
        assessments: {
          name: 'assessments',
          type: 'Assessment[]',
          model: 'Assessment',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'residentId'
        },
        plans: {
          name: 'plans',
          type: 'Plan[]',
          model: 'Plan',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'residentId'
        },
        photo: {
          name: 'photo',
          type: 'FileInfo',
          model: 'FileInfo',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'imageableId'
        },
        files: {
          name: 'files',
          type: 'FileInfo[]',
          model: 'FileInfo',
          relationType: 'referencesMany',
                  keyFrom: 'fileIds',
          keyTo: 'id'
        },
        episodes: {
          name: 'episodes',
          type: 'GenericContent[]',
          model: 'GenericContent',
          relationType: 'embedsMany',
                  keyFrom: '_episodes',
          keyTo: 'id'
        },
        contacts: {
          name: 'contacts',
          type: 'Contact[]',
          model: 'Contact',
          relationType: 'embedsMany',
                  keyFrom: '_contacts',
          keyTo: 'id'
        },
        insuranceCards: {
          name: 'insuranceCards',
          type: 'InsuranceCard[]',
          model: 'InsuranceCard',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'residentId'
        },
        visitPlans: {
          name: 'visitPlans',
          type: 'VisitPlan[]',
          model: 'VisitPlan',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'residentId'
        },
        scheduleEvents: {
          name: 'scheduleEvents',
          type: 'ScheduleEvent[]',
          model: 'ScheduleEvent',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'residentId'
        },
      }
    }
  }
}
