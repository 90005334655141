<mat-sidenav-container [class.folded]="sidenavFolded"
  [class.opened]="!forceSidenavHidden && sidenavOpened" [class.mobile]="isMobile">
  <mat-sidenav #sidenav [mode]="isMobile ? 'over' : 'side'"
    [(opened)]="!forceSidenavHidden && sidenavOpened">
    <mat-toolbar class="site-bar">
      <mat-toolbar-row>
        <button *ngIf="!sidenavFolded" mat-icon-button class="-ml-2" (click)="toggleSidenav()">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <cm-staff-name-card *ngIf="currentUser" class="staff-name-card w-full"
          [staff]="currentUser" [showOffice]="true" avatarPosition="right" size="small"
          [avatarOnly]="sidenavFolded" (click)="sidenavFolded ? toggleSidenav() : null">
        </cm-staff-name-card>
      </mat-toolbar-row>
    </mat-toolbar>
    <cm-menu [menuConfig]="menu" [compact]="sidenavFolded" (itemClick)="onMenuItemClick()">
    </cm-menu>
    <div class="login-users">
      <h5 class="login-users-title">現在ログイン中</h5>
      <div class="login-users-list">
        <cm-staff-name-card *ngFor="let user of loginUsers" [staff]="user" [avatarOnly]="true"
          size="small" [avatarSizeOverride]="24" [matTooltip]="user.lastName + ' ' + user.firstName"></cm-staff-name-card>
      </div>
    </div>
    <mat-toolbar class="site-footer-bar">
      <button mat-button class="logout-button w-full" (click)="logout()">
        <mat-icon>eject</mat-icon><span *ngIf="!sidenavFolded">ログアウト</span>
      </button>
    </mat-toolbar>
  </mat-sidenav>
  <mat-sidenav-content>
    <button *ngIf="isMobile && !forceSidenavHidden" class="mobile-menu-toggle" (click)="toggleSidenav()">
      <span></span>
      <span></span>
      <span></span>
    </button>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
