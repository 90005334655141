/* eslint-disable */
import {
  Staff,
  Resident,
  Company,
  Office,
  PlanHistory,
  Plan2Detail,
  PlanWeeklyDetail,
  PlanDailyDetail,
  PlanReview,
  PlanMonitoring
} from '../index';

declare var Object: any;
export interface PlanInterface {
  "plan1"?: any;
  "id"?: number;
  "staffId"?: number;
  "residentId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "officeId"?: number;
  "recordedAt"?: Date|string;
  "previousId"?: number;
  "updaterId"?: number;
  "_plan2"?: Array<any>;
  "_weekly"?: Array<any>;
  "_daily"?: Array<any>;
  staff?: Staff;
  resident?: Resident;
  company?: Company;
  office?: Office;
  history?: PlanHistory;
  previous?: Plan;
  next?: Plan;
  plan2?: Plan2Detail[];
  weekly?: PlanWeeklyDetail[];
  daily?: PlanDailyDetail[];
  reviews?: PlanReview[];
  monitorings?: PlanMonitoring[];
}

export class Plan implements PlanInterface {
  "plan1": any = <any>null;
  "id": number = <any>null;
  "staffId": number = <any>null;
  "residentId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "officeId": number = <any>null;
  "recordedAt": Date|string = <any>null;
  "previousId": number = <any>null;
  "updaterId": number = <any>null;
  "_plan2": Array<any> = <any>[];
  "_weekly": Array<any> = <any>[];
  "_daily": Array<any> = <any>[];
  staff?: Staff;
  resident?: Resident;
  company?: Company;
  office?: Office;
  history?: PlanHistory;
  previous?: Plan;
  next?: Plan;
  plan2?: Plan2Detail[];
  weekly?: PlanWeeklyDetail[];
  daily?: PlanDailyDetail[];
  reviews?: PlanReview[];
  monitorings?: PlanMonitoring[];
  constructor(data?: Partial<PlanInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Plan`.
   */
  public static getModelName() {
    return "Plan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Plan for dynamic purposes.
  **/
  public static factory(data: PlanInterface): Plan{
    return new Plan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Plan',
      plural: 'Plans',
      path: 'Plans',
      idName: 'id',
      properties: {
        "plan1": {
          name: 'plan1',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "residentId": {
          name: 'residentId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "recordedAt": {
          name: 'recordedAt',
          type: 'Date|string',
          default: <any>null
        },
        "previousId": {
          name: 'previousId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
        "_plan2": {
          name: '_plan2',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "_weekly": {
          name: '_weekly',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "_daily": {
          name: '_daily',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        staff: {
          name: 'staff',
          type: 'Staff',
          model: 'Staff',
          relationType: 'belongsTo',
                  keyFrom: 'staffId',
          keyTo: 'id'
        },
        resident: {
          name: 'resident',
          type: 'Resident',
          model: 'Resident',
          relationType: 'belongsTo',
                  keyFrom: 'residentId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
        history: {
          name: 'history',
          type: 'PlanHistory',
          model: 'PlanHistory',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'recordId'
        },
        previous: {
          name: 'previous',
          type: 'Plan',
          model: 'Plan',
          relationType: 'belongsTo',
                  keyFrom: 'previousId',
          keyTo: 'id'
        },
        next: {
          name: 'next',
          type: 'Plan',
          model: 'Plan',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'previousId'
        },
        plan2: {
          name: 'plan2',
          type: 'Plan2Detail[]',
          model: 'Plan2Detail',
          relationType: 'embedsMany',
                  keyFrom: '_plan2',
          keyTo: 'id'
        },
        weekly: {
          name: 'weekly',
          type: 'PlanWeeklyDetail[]',
          model: 'PlanWeeklyDetail',
          relationType: 'embedsMany',
                  keyFrom: '_weekly',
          keyTo: 'id'
        },
        daily: {
          name: 'daily',
          type: 'PlanDailyDetail[]',
          model: 'PlanDailyDetail',
          relationType: 'embedsMany',
                  keyFrom: '_daily',
          keyTo: 'id'
        },
        reviews: {
          name: 'reviews',
          type: 'PlanReview[]',
          model: 'PlanReview',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'planId'
        },
        monitorings: {
          name: 'monitorings',
          type: 'PlanMonitoring[]',
          model: 'PlanMonitoring',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'planId'
        },
      }
    }
  }
}
