import { Injector, Type } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { isObservable, Observable, of } from 'rxjs';
import { map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { DialogService, DialogWidth } from '../services/dialog.service';
import { ScrollContentComponent } from '../shared/components/scroll-content/scroll-content.component';
import { GenericDialogComponent } from '../shared/dialogs/generic-dialog/generic-dialog.component';
import { OmitOptional } from '../types';

export interface BasicMasterModel {
  id?: number;
  createdAt?: Date|string;
  updatedAt?: Date|string;
  updaterId?: number;
}

/**
 * 詳細ページの共通ロジック
 */
export class DetailPageTrait<T extends BasicMasterModel> {
  /**
   * 表示中のデータのID
   * 新規の場合はundefinedのまま
   */
  id: number;

  /**
   * モデルクラス
   */
  model: Type<T>;

  /**
   * 更新/作成用フォームのReactiveForm
   */
  form: FormGroup<{ [K in keyof OmitOptional<T>]?: FormControl<T[K]> }>;

  /**
   * エラーメッセージの表示用
   */
  errorMessages: string[] = [];

  /**
   * バリデータの設定
   */
  validators: { [field in keyof OmitOptional<T>]?: ValidatorFn | ValidatorFn[] } = {};

  /**
   * バリデーションメッセージの設定
   */
  validationMessages: { [field: string]: { [errorName: string]: string } } = {};

  /**
   * データ作成日の表示用（今のところ使っていない）
   */
  createdAt: Date|string;

  /**
   * データ更新日の表示用
   */
  updatedAt: Date|string;

  /**
   * 新規作成後の遷移を行うかどうか
   */
  preventRedirectAfterCreate = false;

  /**
   * 新規作成後の遷移先
   * 指定しない場合は作成したデータの編集画面になる
   */
  routeAfterCreate: any[];

  /**
   * スクローラーのコンポーネント
   */
  content: ScrollContentComponent;

  /**
   * 保存等の多重送信防止用フラグ
   */
  isSaving = false;

  protected formBuilder: FormBuilder;
  protected route: ActivatedRoute;
  protected router: Router;
  protected snackBar: MatSnackBar;
  protected dialog: DialogService;

  inject(injector: Injector) {
    this.formBuilder = injector.get(FormBuilder);
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.snackBar = injector.get(MatSnackBar);
    this.dialog = injector.get(DialogService);
  }

  /**
   * 初期化処理
   * 継承コンポーネントのコンストラクタで呼び出す
   * ルートパラメータのIDがある(=既存データの編集)の場合は
   * APIからデータを取得してReactiveFormの設定まで行う
   */
  init(): Observable<T> {
    const sample: OmitOptional<T> = new this.model();

    delete sample.id;
    delete sample.createdAt;
    delete sample.updatedAt;
    delete sample.updaterId;

    this.form = new FormGroup((Object.keys(sample) as (keyof OmitOptional<T>)[]).reduce((acc, key) => {
      const control = new FormControl(sample[key]);
      const validators = this.validators?.[key];
      if (validators) {
        control.setValidators(validators);
      }
      acc[key] = control;
      return acc;
    }, {} as { [K in keyof OmitOptional<T>]?: FormControl<T[K]> }));

    return this.route.params.pipe(
      tap(params => this.id = params['id'] ? +params['id'] : null),
      mergeMap(params => {
        const beforeFetch = this.beforeFetch(params);
        return isObservable(beforeFetch) ? beforeFetch.pipe(mapTo(params)) : of(params);
      }),
      mergeMap(_params => {
        if (this.id) {
          return this.fetch(this.id).pipe(
            tap(res => {
              this.createdAt = res.createdAt;
              this.updatedAt = res.updatedAt;

              this.setFormValue(res);
            }),
          );
        } else {
          return of(null);
        }
      }),
    );
  }

  /**
   * form設定・ルートパラメータ取得後、メインデータ取得前に実行
   * formやルートパラメータに関して処理を行う場合に使用
   * @param _params ルートパラメータ
   */
  beforeFetch(_params: Params): Observable<any> | void {}

  /**
   * データの取得
   * @param _id データのID
   */
  fetch(_id: number): Observable<T> {
    return of(null);
  }

  /**
   * データの保存
   * @param _data データ
   */
  save(_data: T): Observable<T | T[]> {
    return of(null);
  }

  afterSave(_result: T | T[]) {}

  /**
   * ReactiveFormに値を設定
   * @param value データ
   */
  setFormValue(value: T) {
    this.form.reset(value as any);
  }

  /**
   * 各項目のエラーをチェックしてエラーメッセージを設定する
   * validかどうかを真偽値で返す
   */
  checkValidity(): boolean {
    this.errorMessages = [];

    Object.keys(this.validationMessages).forEach(field => {
      const fieldArray = field.split('.');
      const messages = this.validationMessages[field];

      let controls: AbstractControl[] = [this.form];

      for (const f of fieldArray) {
        const c = controls[0];

        if (f === '*') {
          if (c instanceof FormGroup) {
            controls = [];

            Object.keys(c.controls).forEach(key => {
              controls.push(c.controls[key]);
            });
          } else if (c instanceof FormArray) {
            controls = c.controls;
          }

          if (!controls.length) {
            break;
          }
        } else {
          const next = c.get(f);

          if (!next) {
            controls = [];
            break;
          }

          controls = [next];
        }
      }

      for (const control of controls) {
        if (control && !control.valid && control.errors) {
          Object.keys(control.errors).forEach(key => {
            this.errorMessages.push(messages[key]);
          });
        }
      }
    });

    return this.errorMessages.length === 0;
  }

  /**
   * 保存ボタンクリックイベントで呼び出されることを想定
   * 処理終了後にSnackBarでメッセージを表示する
   * @param _e DOMイベント
   */
  onSubmit(_e: MouseEvent) {
    if (!this.checkValidity()) {
      if (this.content) {
        this.content.scrollToTop();
      }
      return;
    }

    const data = this.form.value as T;

    this.isSaving = true;
    this.save(data).subscribe(
      res => {
        if (this.content) {
          this.content.scrollToTop();
        }

        if (!Array.isArray(res)) {
          this.setFormValue(res);
        }

        this.afterSave(res);
        this.snackBar.open('正常に保存されました', 'OK', {duration: 2000});

        if (this.router && !this.id && !this.preventRedirectAfterCreate) {
          if (this.routeAfterCreate) {
            this.router.navigate(this.routeAfterCreate);
          } else if (!Array.isArray(res)) {
            this.router.navigate(['..', res.id], {relativeTo: this.route});
          }
        }
      },
      _err => {
        this.snackBar.open('保存時にエラーが発生しました', 'OK', {duration: 2000});
      },
      () => {
        this.isSaving = false;
      },
    );
  }

  get isDirty(): boolean {
    return this.form.dirty;
  }

  get canDeactivate(): Observable<boolean>|boolean {
    if (!this.isDirty) {
      return true;
    }

    return this.dialog.open(GenericDialogComponent, {
      data: {
        type: 'confirm',
        title: '編集内容が保存されていません',
        message: '保存せずにページを移動しようとしています。保存していない編集内容は失われますがよろしいですか?',
      },
      size: DialogWidth.sm,
    }).afterClosed().pipe(map(result => !!result));
  }
}

export function TypedDetailPageTrait<T>(_model: Type<T>): Type<DetailPageTrait<T>> {
  return DetailPageTrait as Type<DetailPageTrait<T>>;
}
