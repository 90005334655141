/* eslint-disable */
import { Injectable } from '@angular/core';
import { Company } from '../../models/Company';
import { Office } from '../../models/Office';
import { Resident } from '../../models/Resident';
import { MoveHistory } from '../../models/MoveHistory';
import { Staff } from '../../models/Staff';
import { Floor } from '../../models/Floor';
import { Room } from '../../models/Room';
import { Note } from '../../models/Note';
import { CareHistory } from '../../models/CareHistory';
import { ProgressRecord } from '../../models/ProgressRecord';
import { HospitalRecord } from '../../models/HospitalRecord';
import { MealRecord } from '../../models/MealRecord';
import { VitalRecord } from '../../models/VitalRecord';
import { ExcretionRecord } from '../../models/ExcretionRecord';
import { BathingRecord } from '../../models/BathingRecord';
import { TransportRecord } from '../../models/TransportRecord';
import { Absence } from '../../models/Absence';
import { Report } from '../../models/Report';
import { ReportReview } from '../../models/ReportReview';
import { ReportConfirmation } from '../../models/ReportConfirmation';
import { PlanHistory } from '../../models/PlanHistory';
import { Assessment } from '../../models/Assessment';
import { AssessmentDetail } from '../../models/AssessmentDetail';
import { Plan } from '../../models/Plan';
import { Plan2Detail } from '../../models/Plan2Detail';
import { PlanWeeklyDetail } from '../../models/PlanWeeklyDetail';
import { PlanDailyDetail } from '../../models/PlanDailyDetail';
import { Option } from '../../models/Option';
import { RoleGroup } from '../../models/RoleGroup';
import { FileInfo } from '../../models/FileInfo';
import { GenericContent } from '../../models/GenericContent';
import { Contact } from '../../models/Contact';
import { InsuranceCard } from '../../models/InsuranceCard';
import { PlanReview } from '../../models/PlanReview';
import { PlanMonitoring } from '../../models/PlanMonitoring';
import { PlanMonitoringDetail } from '../../models/PlanMonitoringDetail';
import { VisitPlan } from '../../models/VisitPlan';
import { VisitSummary } from '../../models/VisitSummary';
import { VisitReport } from '../../models/VisitReport';
import { VisitPlanDetail } from '../../models/VisitPlanDetail';
import { Announcement } from '../../models/Announcement';
import { ScheduleEvent } from '../../models/ScheduleEvent';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    Company: Company,
    Office: Office,
    Resident: Resident,
    MoveHistory: MoveHistory,
    Staff: Staff,
    Floor: Floor,
    Room: Room,
    Note: Note,
    CareHistory: CareHistory,
    ProgressRecord: ProgressRecord,
    HospitalRecord: HospitalRecord,
    MealRecord: MealRecord,
    VitalRecord: VitalRecord,
    ExcretionRecord: ExcretionRecord,
    BathingRecord: BathingRecord,
    TransportRecord: TransportRecord,
    Absence: Absence,
    Report: Report,
    ReportReview: ReportReview,
    ReportConfirmation: ReportConfirmation,
    PlanHistory: PlanHistory,
    Assessment: Assessment,
    AssessmentDetail: AssessmentDetail,
    Plan: Plan,
    Plan2Detail: Plan2Detail,
    PlanWeeklyDetail: PlanWeeklyDetail,
    PlanDailyDetail: PlanDailyDetail,
    Option: Option,
    RoleGroup: RoleGroup,
    FileInfo: FileInfo,
    GenericContent: GenericContent,
    Contact: Contact,
    InsuranceCard: InsuranceCard,
    PlanReview: PlanReview,
    PlanMonitoring: PlanMonitoring,
    PlanMonitoringDetail: PlanMonitoringDetail,
    VisitPlan: VisitPlan,
    VisitSummary: VisitSummary,
    VisitReport: VisitReport,
    VisitPlanDetail: VisitPlanDetail,
    Announcement: Announcement,
    ScheduleEvent: ScheduleEvent,

  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
