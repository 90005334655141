import { Pipe, PipeTransform } from '@angular/core';
import { AppSettingsItem, AppSettingsItemType } from 'src/app/services/app-settings.service';

@Pipe({
  name: 'isSettingsItemType',
})
export class IsSettingsItemTypePipe implements PipeTransform {

  transform<T extends AppSettingsItemType>(item: AppSettingsItem, type: T): item is AppSettingsItem<T> {
    return item.type === type;
  }

}
