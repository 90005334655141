import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { ExcretionRecord } from 'src/loopback';
import { OptionItem, OptionsService } from 'src/app/services/options.service';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'excretionDegreeLabel',
  pure: false,
})
export class ExcretionDegreeLabelPipe implements PipeTransform {

  constructor(
    private opt: OptionsService,
  ) {}

  transform(record: ExcretionRecord, kind: 'urine' | 'feces'): string {
    let found: OptionItem;

    if (kind === 'urine') {
      found = this.opt.urineDegrees.find(i => i.value === record.urineDegree);
    } else {
      found = this.opt.fecesDegrees.find(i => i.value === record.fecesDegree);
    }

    if (found) {
      if (found.value === -1) {
        return (kind === 'urine' ? record.urineAmount : record.fecesAmount) + ' cc';
      } else {
        return found.label;
      }
    }

    return null;
  }

}
