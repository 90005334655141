import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { YubinBangoService } from 'src/app/services/yubin-bango.service';


type AddressField = 'zip' | 'region' | 'locality' | 'street' | 'extended';

export interface AutoAddressSettings {
  [name: string]: { [field in AddressField]: string };
}

@Directive({
  selector: '[cmAutoAddress][formGroup]',
  exportAs: 'cmAutoAddress',
})
export class AutoAddressDirective {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('cmAutoAddress') settings: AutoAddressSettings;
  @Input() formGroup: FormGroup;

  constructor(
    private yubinBango: YubinBangoService,
  ) { }

  async execute(name: string) {
    const fields = this.settings[name];

    if (!fields) {
      throw new Error(`AutoAddressDirective: settings "${name}" is undefined.`);
    }

    const addr = await this.yubinBango.autoAddress(this.formGroup.get(fields.zip).value);
    // 複数のフィールドが同じコントロールに割り当てられた場合を想定して
    // 入力は必ず以下の順番で行われるようにする
    const addressFields = ['region', 'locality', 'street', 'extended'] as const;
    // 住所コントロールを一旦空にする
    addressFields.forEach(fieldName => {
      const field = this.formGroup.get(fields[fieldName]);
      if (field && field.value !== '') {
        field.setValue('');
      }
    });
    // 住所検索結果を入力
    // 同じコントロールだった場合は後ろに追加する
    addressFields.forEach(fieldName => {
      const field_1 = this.formGroup.get(fields[fieldName]);
      if (field_1) {
        field_1.setValue(field_1.value + addr[fieldName]);
      }
    });
  }

}

@Directive({
  selector: 'button[cmAutoAddressButton]',
})
export class AutoAddressButtonDirective {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('cmAutoAddressButton') settingsName: string;

  constructor(
    private parent: AutoAddressDirective,
  ) {}

  @HostListener('click') onclick() {
    if (this.parent) {
      this.parent.execute(this.settingsName);
    }
  }

}
