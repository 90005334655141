import { Component, EventEmitter, HostBinding, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'cm-full-screen-textarea',
  templateUrl: './full-screen-textarea.component.html',
  styleUrls: ['./full-screen-textarea.component.scss'],
})
export class FullScreenTextareaComponent implements OnInit {

  title = '';
  control = new FormControl('');
  buttonLabel = 'OK';
  buttonClick = new EventEmitter<string>();
  @HostBinding('style.height.px') height: number;

  @ViewChild('textarea', { static: true }) textarea: MatInput;

  constructor() { }

  setValue(text: string) {
    this.control.setValue(text);
  }

  ngOnInit() {
    this.textarea.focus();
    window.scrollTo(0, window.innerHeight);
    setTimeout(() => {
      const availHeight = window.innerHeight;
      window.scrollTo(0, 0);
      this.height = availHeight;
    }, 200);
  }

}
