import { Component, Input, HostBinding } from '@angular/core';

/**
 * 巡回のステータス表示用バッジ
 */
@Component({
  selector: 'cm-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  /**
   * ラベルとアイコンの設定
   */
  badges: { [name: string]: { label: string; icon: string } } = {
    accident: { label: '事故', icon: 'new_releases'},
    complaint: { label: '苦情', icon: 'sentiment_dissatisfied'},
    nearmiss: { label: 'ヒヤリハット', icon: 'droplet' },
    notice: { label: '連絡', icon: 'announcement'},
    unknown: { label: '', icon: ''},
  };

  /**
   * 現在のバッジ
   */
  currentBadge: { label?: string; icon?: string };

  @Input() type: 'horizontal' | 'vertical' = 'horizontal';

  /**
   * バッジ名
   */
  @Input()
  set name(name: string) {
    this._name = name;
    this.currentBadge = this.badges[name] || {};
  }
  private _name: string;

  /**
   * スタイル用クラス
   */
  @HostBinding('class') get class() { return `${this._name} ${this.type}`; }

  constructor() { }

}
