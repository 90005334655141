import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TimelineItem } from 'src/app/classes/timeline';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'cm-timeline-note-replies',
  templateUrl: './timeline-note-replies.component.html',
  styleUrls: ['./timeline-note-replies.component.scss'],
})
export class TimelineNoteRepliesComponent  {

  @Input() items: TimelineItem<'note'>[];
  @Output() edit = new EventEmitter<TimelineItem<'note'>>();
  @Output() delete = new EventEmitter<TimelineItem<'note'>>();

  constructor(
    private role: RoleService,
  ) { }

  canEdit(item: TimelineItem<'note'>) {
    return item.record.staffId === this.role.currentUser.id;
  }

  deleteItem(item: TimelineItem<'note'>) {
    const index = this.items.indexOf(item);
    this.items.splice(index, 1);
    this.delete.emit(item);
  }
}
