import { AfterViewInit, Directive, EventEmitter, Input, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { MatFormFieldControl } from '@angular/material/form-field';
import * as moment from 'moment';
import { FormFieldControlBase } from './form-field-control-base';

export interface DateInterval {
  start: moment.Moment;
  end: moment.Moment;
}

@Directive()
export class IntervalInputBase extends FormFieldControlBase<DateInterval> implements MatFormFieldControl<DateInterval>, ControlValueAccessor, AfterViewInit {

  static idSeq = 0;
  form: FormGroup<{ [K in keyof DateInterval]: FormControl<DateInterval[K]> }>;
  controlType = 'date-interval-input';

  @ViewChild('startInput', { static: true }) startInput: MatDatepickerInput<moment.Moment>;
  @ViewChild('endInput', { static: true }) endInput: MatDatepickerInput<moment.Moment>;

  @Input()
  get value(): DateInterval {
    return {
      start: this.form.value.start,
      end: this.form.value.end,
    };
  }
  set value(value: DateInterval) {
    if (!value) {
      value = {start: null, end: null};
    }
    this.form.setValue(value);
    this.stateChanges.next();
  }

  @Output() changed = new EventEmitter<DateInterval>();

  get empty() {
    return !this.form.value.start && !this.form.value.end;
  }

  constructor(
    protected formBuilder: FormBuilder,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    super(ngControl);

    this.form = this.formBuilder.group({
      start: [null],
      end: [null],
    });
  }

  ngAfterViewInit() {
    // form fieldに内包する場合、どちらのpickerも同じ位置に出てしまうので
    // それぞれのinputの下に出るようにするためのハック
    this.startInput.getConnectedOverlayOrigin = () => {
      return this.startInput['_elementRef'];
    };
    this.endInput.getConnectedOverlayOrigin = () => {
      return this.endInput['_elementRef'];
    };
  }

  setDisabledState(disabled: boolean) {
    this.form[disabled ? 'disable' : 'enable']();
    this.disabled = disabled;
  }

  clearStart() {
    this.form.get('start').setValue(null);
    this.onChange(this.value);
  }

  clearEnd() {
    this.form.get('end').setValue(null);
    this.onChange(this.value);
  }

  onChange(value: any): void {
    if (this._onChange) {
      this._onChange(value);
    }
    this.changed.emit(value);
  }
}
