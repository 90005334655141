import { Component, Inject, Injector, INJECTOR, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AES, enc, HmacSHA256 } from 'crypto-js';
import { filter } from 'rxjs/operators';
import { PageBase } from 'src/app/classes/page-base';
import { DialogService, DialogWidth } from 'src/app/services/dialog.service';
import { RoleService } from 'src/app/services/role.service';
import { GenericDialogComponent } from 'src/app/shared/dialogs/generic-dialog/generic-dialog.component';
import { ResidentInfoComponent, ResidentInfoDialogData } from 'src/app/shared/dialogs/resident-info/resident-info.component';
import { Mixin } from 'src/app/utils/mixin';
import { environment } from 'src/environments/environment';

/**
 * ログインページ(url: /login)
 */
@Component({
  selector: 'cm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends Mixin(PageBase) implements OnInit {

  title = 'ログイン';
  appTitle = environment.title;
  appTitleLogo = environment.titleLogo;

  /**
   * ログインフォームのReactiveForm
   */
  form: FormGroup<{ usernameOrEmail: FormControl<string>; password: FormControl<string> }>;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private role: RoleService,
    private dialog: DialogService,
    @Inject(INJECTOR) injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.init();

    this.form = this.formBuilder.group({
      usernameOrEmail: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  /**
   * フォーム送信イベントのハンドラ
   * @param e DOMイベント
   */
  onSubmit(e: Event): void {
    const value = this.form.value;
    const credentials: {username?: string; email?: string; password: string} = {password: value.password};

    if (value.usernameOrEmail.indexOf('@') !== -1) {
      credentials.email = value.usernameOrEmail;
    } else {
      credentials.username = value.usernameOrEmail;
    }

    this.role.login(credentials).subscribe(
      () => {
        this.router.navigateByUrl('/dashboard');
      },
      () => {
        this.snackBar.open('ログインに失敗しました', 'OK', {duration: 3000});
      },
    );
  }

  showOfflineData() {
    this.dialog.open(GenericDialogComponent, {
      data: {
        type: 'prompt',
        promptValidators: [Validators.required, Validators.pattern(/^\d*$/)],
        promptInputType: 'tel',
        title: '暗証番号を入力してください',
      },
      size: DialogWidth.md,
    }).afterClosed().pipe(
      filter<string>(res => !!res),
    ).subscribe(
      pass => {
        let data: ResidentInfoDialogData;

        try {
          data = this.getOfflineData(pass);
        } catch (e) {
          console.error(e);
          this.dialog.open(GenericDialogComponent, {
            data: {
              type: 'alert',
              title: 'データの読み込みに失敗しました',
              message: [
                '暗証番号が間違っているか、データが保存されていません。',
                '確認の上、再度お試しください。',
              ],
            },
            size: DialogWidth.md,
          });

          return;
        }

        data.isOffline = true;

        this.dialog.open(ResidentInfoComponent, {
          data,
          size: DialogWidth.xl,
        });
      },
    );
  }

  private getOfflineData(pass: string): ResidentInfoDialogData {
    const hash = HmacSHA256('cm.offlineData', pass).toString();
    const encrypted = localStorage.getItem(hash);

    if (!encrypted) {
      throw new Error('Data not found');
    }

    const decrypted = AES.decrypt(encrypted, pass).toString(enc.Utf8);

    return JSON.parse(decrypted);
  }

}
