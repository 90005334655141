import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cm-custom-slider',
  templateUrl: './custom-slider.component.html',
  styleUrls: ['./custom-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSliderComponent),
      multi: true,
    },
  ],
})
export class CustomSliderComponent implements OnChanges, ControlValueAccessor {

  @Input() min = 0;
  @Input() max = 100;
  @Input() step = 1;
  @Input() standardRange = { min: 0, max: 100 };

  trackLeft: number;
  trackRight: number;
  sliderColor = 'accent';

  _value: number | null;
  slider: FormControl<number> = new FormControl(null);
  private onChangeCallback: (value: any) => void = () => {};
  private onTouchedCallback: () => void = () => {};

  get value(): number | null {
    return this._value;
  }

  @Input()
  set value(value: number | null) {
    this.writeValue(value);
  }

  writeValue(value: number | null): void {
    if (value !== this._value) {
      this._value = value;
      this.slider.setValue(value);
      this.setSliderColor(value);
      this.onChangeCallback(value);
    }
  }

  setSliderColor(value: number | null) {
    if (this.standardRange && value >= this.standardRange.min && value <= this.standardRange.max) {
      this.sliderColor = 'primary';
    } else {
      this.sliderColor = 'accent';
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.slider.disable();
    } else {
      this.slider.enable();
    }
  }

  setTrackPosition() {
    if (this.standardRange) {
      const allRange = this.max - this.min;
      this.trackLeft = (this.standardRange.min - this.min) / allRange * 100;
      this.trackRight = 100 - (this.standardRange.max - this.min) / allRange * 100;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.standardRange) {
      this.setTrackPosition();
    }
  }

  onSliderChange(value: number) {
    this.writeValue(value);
  }

  onSliderTouched() {
    this.onTouchedCallback();
  }
}
