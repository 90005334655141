import { Component, Inject, INJECTOR, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { mapTo, mergeMap, tap } from 'rxjs/operators';
import { TypedCareDetailPageTrait } from 'src/app/classes/care-detail-page-trait';
import { TypedDetailPageTrait } from 'src/app/classes/detail-page-trait';
import { PageBase } from 'src/app/classes/page-base';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { DialogWidth } from 'src/app/services/dialog.service';
import { OptionsService } from 'src/app/services/options.service';
import { ScrollContentComponent } from 'src/app/shared/components/scroll-content/scroll-content.component';
import { Mixin } from 'src/app/utils/mixin';
import { ExcretionRecord, ExcretionRecordApi } from 'src/loopback';
import { ExcretionTemplateSelectorComponent } from '../excretion-template-selector/excretion-template-selector.component';

@Component({
  selector: 'cm-care-detail-excretion',
  templateUrl: './care-detail-excretion.component.html',
  styleUrls: ['./care-detail-excretion.component.scss'],
})
export class CareDetailExcretionComponent extends Mixin(
  PageBase,
  TypedDetailPageTrait(ExcretionRecord),
  TypedCareDetailPageTrait(ExcretionRecord),
) implements OnInit {

  isDialog = true;
  preventRedirectAfterCreate = true;
  titleBase = '排泄記録';
  canGoBack = true;
  model = ExcretionRecord;
  useFailed = false;
  useImplements = false;
  implements: string[] = [];

  validators = {
    urineAmount: [Validators.required],
    fecesAmount: [Validators.required],
  };

  validationMessages = {
    urineAmount: {
      required: '排尿量を入力してください。',
    },
    fecesAmount: {
      required: '排便量を入力してください。',
    },
  };

  @ViewChild(ScrollContentComponent) content: ScrollContentComponent;

  constructor(
    public opt: OptionsService,
    private appSettings: AppSettingsService,
    @Inject(INJECTOR) injector: Injector,
    private excretionRecordApi: ExcretionRecordApi,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.init().pipe(
      tap(result => {
        this.setPropertyFromRecord(result);
        this.setTitle(this.generateTitle(result));

        const isFailed = this.form.get('isFailed');
        if (!result) {
          isFailed.setValue(null);
        }

        const urineAmount = this.form.get('urineAmount');
        if (result && result.urineDegree === -1) {
          urineAmount.enable();
        } else {
          urineAmount.disable();
        }

        const fecesAmount = this.form.get('fecesAmount');
        if (result && result.fecesDegree === -1) {
          fecesAmount.enable();
        } else {
          fecesAmount.disable();
        }
      }),
      mergeMap(result => {
        const officeId = this.resident?.officeId ?? this.residents[0].officeId;

        return this.appSettings.getAll(officeId).pipe(
          tap(settings => {
            this.useFailed = settings['care-excretion-useFailed'] || false;
            this.useImplements = settings['care-excretion-useImplement'] || false;
            this.implements = settings['care-excretion-implement'] || [];
          }),
          mapTo(result),
        );
      }),
    ).subscribe();
  }

  setPropertyFromRecord(record?: ExcretionRecord): void {
    super.setPropertyFromRecord(record);

    if (!record) {
      this.form.get('urineDegree').setValue(0);
      this.form.get('fecesDegree').setValue(0);
    }
  }

  fetch(id: number) {
    return this.excretionRecordApi.findById(id, {
      include: this.recordInclusionDefaults,
    });
  }

  save(data: ExcretionRecord) {
    if (data.urineDegree !== -1) {
      data.urineAmount = null;
    }
    if (data.fecesDegree !== -1) {
      data.fecesAmount = null;
    }

    if (this.id) {
      return this.excretionRecordApi.patchAttributes(this.id, data);
    } else {
      if (this.residents) {
        const arr = this.residents.map(r => {
          return { ...data, residentId: r.id, officeId: r.officeId };
        });
        return this.excretionRecordApi.createMany(arr);
      } else {
        data.residentId = this.resident.id;
        data.officeId = this.resident.officeId;
        return this.excretionRecordApi.create(data);
      }
    }
  }

  openTemplateSelector() {
    this.dialog.open(ExcretionTemplateSelectorComponent, {
      data: { officeId: this.resident?.officeId ?? this.residents[0].officeId },
      size: DialogWidth.xl,
    }).afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.form.patchValue(result);
      this.onUrineSelectionChange(result.urineDegree);
      this.onFecesSelectionChange(result.fecesDegree);
      this.form.markAsDirty();
    });
  }

  onUrineSelectionChange(value: number) {
    const urineAmount = this.form.get('urineAmount');
    if (value === -1) {
      urineAmount.enable();
    } else {
      urineAmount.disable();
    }
  }

  onFecesSelectionChange(value: number) {
    const fecesAmount = this.form.get('fecesAmount');
    if (value === -1) {
      fecesAmount.enable();
    } else {
      fecesAmount.disable();
    }
  }
}
