/* eslint-disable */
import {
  Floor,
  MoveHistory,
  Company,
  Office
} from '../index';

declare var Object: any;
export interface RoomInterface {
  "name"?: string;
  "order"?: number;
  "capacity"?: number;
  "type"?: number;
  "charge"?: number;
  "remark"?: string;
  "status": number;
  "id"?: number;
  "floorId"?: number;
  "officeId"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "companyId"?: number;
  "updaterId"?: number;
  floor?: Floor;
  moveHistories?: MoveHistory[];
  company?: Company;
  office?: Office;
}

export class Room implements RoomInterface {
  "name": string = '';
  "order": number = <any>null;
  "capacity": number = <any>null;
  "type": number = <any>null;
  "charge": number = <any>null;
  "remark": string = '';
  "status": number = 1;
  "id": number = <any>null;
  "floorId": number = <any>null;
  "officeId": number = <any>null;
  "createdAt": Date|string = <any>null;
  "updatedAt": Date|string = <any>null;
  "companyId": number = <any>null;
  "updaterId": number = <any>null;
  floor?: Floor;
  moveHistories?: MoveHistory[];
  company?: Company;
  office?: Office;
  constructor(data?: Partial<RoomInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Room`.
   */
  public static getModelName() {
    return "Room";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Room for dynamic purposes.
  **/
  public static factory(data: RoomInterface): Room{
    return new Room(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Room',
      plural: 'Rooms',
      path: 'Rooms',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "capacity": {
          name: 'capacity',
          type: 'number'
        },
        "type": {
          name: 'type',
          type: 'number'
        },
        "charge": {
          name: 'charge',
          type: 'number'
        },
        "remark": {
          name: 'remark',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "floorId": {
          name: 'floorId',
          type: 'number'
        },
        "officeId": {
          name: 'officeId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "updaterId": {
          name: 'updaterId',
          type: 'number'
        },
      },
      relations: {
        floor: {
          name: 'floor',
          type: 'Floor',
          model: 'Floor',
          relationType: 'belongsTo',
                  keyFrom: 'floorId',
          keyTo: 'id'
        },
        moveHistories: {
          name: 'moveHistories',
          type: 'MoveHistory[]',
          model: 'MoveHistory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'roomId'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        office: {
          name: 'office',
          type: 'Office',
          model: 'Office',
          relationType: 'belongsTo',
                  keyFrom: 'officeId',
          keyTo: 'id'
        },
      }
    }
  }
}
