import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'addTime',
})
export class AddTimePipe implements PipeTransform {

  transform(value: any, amount: moment.DurationInputArg1, unit: moment.DurationInputArg2): Date {
    return moment(value).add(amount, unit).toDate();
  }

}
