<mat-list>
  <h3 matSubheader>{{ residents.length }}人の入居者を選択中</h3>
  <mat-list-item *ngFor="let resident of residents">
    <div class="flex flex-row w-full">
      <cm-resident-name-card class="flex-1" [resident]="resident" [room]="resident.moveHistories[0]?.room" size="small"></cm-resident-name-card>
      <button mat-icon-button class="flex-initial" (click)="openResidentInfo(resident)">
        <mat-icon>face</mat-icon>
      </button>
      <button mat-icon-button class="flex-initial" (click)="openNoteTimeline(resident)">
        <mat-icon>comment</mat-icon>
      </button>
    </div>
  </mat-list-item>
</mat-list>
