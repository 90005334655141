import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { DialogComponentBase } from 'src/app/services/dialog.service';
import { ProgressRecord } from 'src/loopback';

@Component({
  selector: 'cm-progress-template-selector',
  templateUrl: './progress-template-selector.component.html',
  styleUrls: ['./progress-template-selector.component.scss'],
})
export class ProgressTemplateSelectorComponent extends DialogComponentBase<ProgressTemplateSelectorDialogData, ProgressRecord> implements OnInit {

  categories: string[] = [];
  templates: ProgressRecord[] = [];
  selectedCategory: string;
  currentList = new MatTableDataSource<{place: string; memo: string}>();

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private appSettings: AppSettingsService,
    protected ref: MatDialogRef<ProgressTemplateSelectorComponent, ProgressRecord>,
    @Inject(MAT_DIALOG_DATA) protected data: ProgressTemplateSelectorDialogData,
  ) {
    super();
  }

  ngOnInit() {
    this.appSettings.getAll(this.data.officeId).subscribe(settings => {
      this.categories = settings['care-progress-category'];
      this.templates = settings['care-progress-template'];
    });
  }

  selectCategory(category: string) {
    this.selectedCategory = category;
    this.currentList.data = this.templates.filter(t => t.category === category);
    setTimeout(() => this.currentList.sort = this.sort);
  }

  selectTemplate(template: ProgressRecord) {
    this.ref.close(template);
  }

}

export interface ProgressTemplateSelectorDialogData {
  officeId: number;
}
