import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TimelineItem } from 'src/app/classes/timeline';
import { reportBadgeNames } from 'src/app/constants';
import { AppSettingsDictionary } from 'src/app/services/app-settings.service';
import { RecordType } from 'src/app/types';
import { FileInfo, ProgressRecord, ProgressRecordApi } from 'src/loopback';

@Component({
  selector: 'cm-timeline-item-content',
  templateUrl: './timeline-item-content.component.html',
  styleUrls: ['./timeline-item-content.component.scss'],
})
export class TimelineItemContentComponent implements OnInit {

  @Input() item: TimelineItem;
  @Input() compact = false;
  @Input() settings: Partial<AppSettingsDictionary> = {};

  reportBadgeNames = reportBadgeNames;

  constructor(private progressRecordApi: ProgressRecordApi, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.item.type === 'progress') {
      this.progressRecordApi.getPhoto(this.item.record.id).subscribe(res => {
        (this.item.record as ProgressRecord).photo = new FileInfo(res);
        this.cdRef.markForCheck();
      });
    }
  }

}
