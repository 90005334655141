import { Component, HostBinding, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { DateSelectorCalendarHeaderComponent } from '../date-selector-calendar-header/date-selector-calendar-header.component';

@Component({
  selector: 'cm-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: DateTimePickerComponent },
  ],
})
export class DateTimePickerComponent implements MatFormFieldControl<moment.Moment>, ControlValueAccessor {
  static idSeq = 0;
  autofilled?: boolean;

  form: FormGroup<{ date: FormControl<moment.Moment>; hour: FormControl<number>; minute: FormControl<number> }>;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'warekit-date-input';

  hours: number[] = [];
  minutes: number[] = [];

  calendarHeader = DateSelectorCalendarHeaderComponent;

  @HostBinding() id = `cm-date-time-picker-${DateTimePickerComponent.idSeq++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input()
  get value(): moment.Moment {
    const value = this.form.value;

    if (!value.date) {
      value.date = moment();
    }

    return value.date.hour(+value.hour).minute(+value.minute).second(0).millisecond(0);
  }
  set value(value: moment.Moment) {
    if (!moment.isMoment(value)) {
      value = moment(value);
    }

    if (value.isValid()) {
      this.form.setValue({
        date: value,
        hour: value.hour(),
        minute: value.minute(),
      });
    }
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.form.value.date && !this.form.value.hour && !this.form.value.minute;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = !!required;
    this.stateChanges.next();
  }
  private _required: boolean;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = !!disabled;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get readonly() {
    return this._readonly;
  }
  set readonly(readonly: boolean) {
    this._readonly = !!readonly;
    this.stateChanges.next();
  }
  private _readonly: boolean;

  private _onChange: (value: any) => void;
  private _onTouched: (value: any) => void;

  constructor(
    protected formBuilder: FormBuilder,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this.form = this.formBuilder.group({
      date: [moment()],
      hour: [0],
      minute: [0],
    });

    for (let h = 0; h < 24; h++) {
      this.hours.push(h);
    }

    for (let h = 0; h < 60; h++) {
      this.minutes.push(h);
    }

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick() {

  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onChange(value: any) {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  setDisabledState(disabled: boolean) {
    this.form[disabled ? 'disable' : 'enable']();
    this.disabled = disabled;
  }
}
