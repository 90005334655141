import { Component, HostBinding } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatFormFieldControl } from '@angular/material/form-field';
import * as moment from 'moment';
import { CM_MONTH_SELECTOR_FORMATS } from 'src/app/constants';
import { MonthSelectorCalendarHeaderComponent } from '../month-selector-calendar-header/month-selector-calendar-header.component';
import { IntervalInputBase } from 'src/app/classes/interval-input-base';

@Component({
  selector: 'cm-month-interval-input',
  templateUrl: './month-interval-input.component.html',
  styleUrls: ['./month-interval-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: MonthIntervalInputComponent },
    { provide: MAT_DATE_FORMATS, useValue: CM_MONTH_SELECTOR_FORMATS },
  ],
})
export class MonthIntervalInputComponent extends IntervalInputBase {
  static idSeq = 0;
  controlType = 'month-interval-input';

  @HostBinding() id = `cm-month-interval-input-${MonthIntervalInputComponent.idSeq++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  calendarHeader = MonthSelectorCalendarHeaderComponent;

  onMonthSelected(date: moment.Moment, target: 'start' | 'end', picker: MatDatepicker<moment.Moment>) {
    const value = this.form.value;
    value[target] = date;
    this.writeValue(value);
    this.onChange(value);
    picker.close();
  }
}
