import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { Note, NoteApi, Resident } from 'src/loopback';
import { FullScreenTextareaComponent } from '../shared/dialogs/full-screen-textarea/full-screen-textarea.component';
import { OverlayService } from './overlay.service';
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root',
})
export class NoteUtilService {

  constructor(
    private noteApi: NoteApi,
    private role: RoleService,
    private overlay: OverlayService,
  ) {}

  createNote(resident: Resident, text: string) {
    const note = new Note({
      content: text.trim(),
      staffId: this.role.currentUser.id,
      residentId: resident ? resident.id : null,
      parentId: null,
    });
    delete note.id;
    delete note.createdAt;
    delete note.updatedAt;

    return this.noteApi.create(note).pipe(
      mergeMap(res => {
        return this.noteApi.createConfirmations(res.id, {
          confirmedAt: new Date(),
          staffId: res.staffId,
        }).pipe(
          tap(confirmation => {
            confirmation.staff = this.role.currentUser;
            res.confirmations = [confirmation];
            res.staff = this.role.currentUser;
          }),
          mapTo(res),
        );
      }),
    );
  }

  createReply(parent: Note, text: string) {
    const note = new Note({
      content: text.trim(),
      staffId: this.role.currentUser.id,
      residentId: parent.residentId,
    });
    delete note.id;
    delete note.createdAt;
    delete note.updatedAt;

    return this.noteApi.createReplies(parent.id, note).pipe(
      map(res => {
        res.staff = this.role.currentUser;
        return res;
      }),
    );
  }

  update(note: Note, text: string) {
    note.content = text;

    return this.noteApi.patchAttributes(note.id, note).pipe(
      map(res => {
        res.staff = this.role.currentUser;
        return res;
      }),
    );
  }

  openEditor(resident: Resident) {
    const compRef = this.overlay.showComponent(FullScreenTextareaComponent);
    let title = 'ノートを追加';

    if (resident) {
      title = resident.lastName + ' ' + resident.firstName + 'さんについての' + title;
    }

    compRef.instance.title = title;

    return compRef.instance.buttonClick.pipe(
      tap(() => this.overlay.hide()),
      mergeMap(text => text !== undefined ? this.createNote(resident, text) : EMPTY),
    );
  }

  openReplyEditor(parent: Note) {
    const compRef = this.overlay.showComponent(FullScreenTextareaComponent);
    compRef.instance.title = '返信する';

    return compRef.instance.buttonClick.pipe(
      tap(() => this.overlay.hide()),
      mergeMap(text => text !== undefined ? this.createReply(parent, text) : EMPTY),
    );
  }

  openUpdateEditor(note: Note) {
    const compRef = this.overlay.showComponent(FullScreenTextareaComponent);
    compRef.instance.setValue(note.content);

    return compRef.instance.buttonClick.pipe(
      tap(() => this.overlay.hide()),
      mergeMap(text => text !== undefined ? this.update(note, text) : EMPTY),
    );
  }
}
