<div mat-dialog-title>{{ title }}</div>
<div mat-dialog-content [formGroup]="form">
  <cm-message-box message="入力エラーがあります。" [listItems]="errorMessages"></cm-message-box>
  <mat-card appearance="outlined" class="meta-card mb-4">
    <mat-card-content>
      <table class="meta-table w-full">
        <tbody>
          <tr>
            <th>入居者</th>
            <td>
              <cm-resident-name-card *ngIf="resident" [resident]="resident"
                [room]="resident.moveHistories[0]?.room" size="small"></cm-resident-name-card>
              <span *ngIf="residents" [matTooltip]="residentNames">{{ residents.length }}人の入居者</span>
            </td>
          </tr>
          <tr>
            <th>記録日時</th>
            <td>
              <cm-date-time-picker formControlName="recordedAt"></cm-date-time-picker>
            </td>
          </tr>
          <tr>
            <th>記録者</th>
            <td>
              <mat-select *ngIf="staffList$ | async as staffList" formControlName="staffId" class="transparent-input">
                <mat-option *ngFor="let staff of staffList" [value]="staff.id">
                  {{ staff.lastName }} {{ staff.firstName }}
                </mat-option>
              </mat-select>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
  </mat-card>
  <div class="section-content">
    <div class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[150px]">
        <mat-label>脈拍</mat-label>
        <input type="number" pattern="\d*" matInput formControlName="pulse" [value]="form.value.pulse">
        <span matTextSuffix>回/分</span>
      </mat-form-field>
      <cm-custom-slider class="flex-1" formControlName="pulse" [min]="0" [max]="150"
        [standardRange]="standardValues['pulse']" [value]="form.value.pulse">
      </cm-custom-slider>
    </div>
    <div class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[150px]">
        <mat-label>呼吸</mat-label>
        <input type="number" pattern="\d*" matInput formControlName="respiration"
          [value]="form.value.respiration">
        <span matTextSuffix>回/分</span>
      </mat-form-field>
      <cm-custom-slider class="flex-1" formControlName="respiration" [min]="0" [max]="150"
        [standardRange]="standardValues['respiration']" [value]="form.value.respiration">
      </cm-custom-slider>
    </div>
    <div class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[150px]">
        <mat-label>血圧(高)</mat-label>
        <input type="number" pattern="\d*" matInput formControlName="systolicBP"
          [value]="form.value.systolicBP">
        <span matTextSuffix>mmHg</span>
      </mat-form-field>
      <cm-custom-slider class="flex-1" formControlName="systolicBP" [min]="0" [max]="250"
        [standardRange]="standardValues['systolicBP']" [value]="form.value.systolicBP">
      </cm-custom-slider>
    </div>
    <div class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[150px]">
        <mat-label>血圧(低)</mat-label>
        <input type="number" pattern="\d*" matInput formControlName="diastolicBP"
          [value]="form.value.diastolicBP">
        <span matTextSuffix>mmHg</span>
      </mat-form-field>
      <cm-custom-slider class="flex-1" formControlName="diastolicBP" [min]="0" [max]="150"
        [standardRange]="standardValues['diastolicBP']" [value]="form.value.diastolicBP">
      </cm-custom-slider>
    </div>
    <div class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[150px]">
        <mat-label>体温</mat-label>
        <input type="tel" step="0.1" matInput formControlName="temperature"
          [value]="form.value.temperature">
        <span matTextSuffix>℃</span>
      </mat-form-field>
      <cm-custom-slider class="flex-1" formControlName="temperature" [min]="34" [max]="42"
        [step]="0.1" [standardRange]="standardValues['temperature']"
        [value]="form.value.temperature">
      </cm-custom-slider>
    </div>
    <div class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[150px]">
        <mat-label>酸素飽和度</mat-label>
        <input type="number" pattern="\d*" matInput formControlName="oxygen" [value]="form.value.oxygen">
        <span matTextSuffix>%</span>
      </mat-form-field>
      <cm-custom-slider class="flex-1" formControlName="oxygen" [min]="0" [max]="100"
        [standardRange]="standardValues['oxygen']" [value]="form.value.oxygen">
      </cm-custom-slider>
    </div>
    <div class="flex flex-row gap-4 justify-start items-center">
      <mat-form-field class="basis-[150px]">
        <mat-label>体重</mat-label>
        <input type="tel" matInput formControlName="weight" [value]="form.value.weight">
        <span matTextSuffix>kg</span>
      </mat-form-field>
      <mat-slider class="flex-1" color="primary" discrete
        [min]="30" [max]="100" [step]="0.1"><input matSliderThumb formControlName="weight" [value]="form.value.weight" /></mat-slider>
    </div>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <mat-form-field class="section-content w-full">
    <mat-label>メモ</mat-label>
    <textarea matInput rows="5" formControlName="memo"></textarea>
  </mat-form-field>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="flex flex-row gap-4">
        <div class="rounded-icon">
          <mat-icon>comment</mat-icon>
        </div>
        <div class="flex-1">
          <mat-form-field class="w-full">
            <mat-label>この記録に関するノート<ng-container *ngIf="!id || !note">を追加</ng-container>
            </mat-label>
            <textarea matInput #noteContent="matInput" rows="5" formControlName="noteContent"></textarea>
            <mat-hint *ngIf="!id || !note">記録の保存と同時にノートに投稿されます</mat-hint>
          </mat-form-field>
          <ng-container *ngIf="note">
            <mat-divider></mat-divider>
            <cm-timeline-note-replies *ngIf="noteReplies && noteReplies.length" [items]="noteReplies"
              class="mb-4"></cm-timeline-note-replies>
            <div class="text-right">
              <button mat-button (click)="openNoteReplyEditor()">
                <mat-icon>reply</mat-icon> 返信
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>キャンセル</button>
  <button mat-button mat-stroked-button (click)="noteContent.focus()">
    <mat-icon>comment</mat-icon> ノートを編集
  </button>
  <button mat-button mat-flat-button type="submit" color="primary" (click)="onSubmit($event)"
    [disabled]="!isDirty || isSaving">
    <mat-icon>save</mat-icon> 保存
  </button>
</div>
