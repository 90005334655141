import { trigger, state, style, transition, animate, AnimationTriggerMetadata } from '@angular/animations';

export const searchVisibility: AnimationTriggerMetadata = trigger('searchVisibility', [
  state('shown', style({
    transform: 'translate(0, 0)',
  })),
  state('hidden', style({
    transform: 'translate(0, -101%)',
  })),
  transition('hidden => shown', animate('160ms cubic-bezier(0.0, 0.0, 0.2, 1)')),
  transition('shown => hidden', animate('160ms cubic-bezier(0.4, 0.0, 1, 1)')),
]);

export const tableExpand: AnimationTriggerMetadata = trigger('tableExpand', [
  state('collapsed', style({height: '0', minHeight: '0'})),
  state('expanded', style({height: '*'})),
  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);
