import { Component, AfterViewInit, Input, ViewChild, ElementRef, HostBinding, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import * as jdenticon from 'jdenticon';
import { MD5 } from 'crypto-js';

/**
 * 自動生成ユーザーアイコン付きのユーザー名表示
 */
@Component({
  selector: 'cm-identicon',
  templateUrl: './identicon.component.html',
  styleUrls: ['./identicon.component.scss'],
})
export class IdenticonComponent implements AfterViewInit, OnChanges {
  /**
   * ユーザーID
   */
  @Input() userName: string;

  /**
   * 幅と高さ
   */
  @Input() size = 48;

  /**
   * アイコンへの参照
   */
  @ViewChild('icon', { static: true }) icon: ElementRef;

  viewInitialized = false;

  ngAfterViewInit() {
    this.update();
    this.viewInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.viewInitialized && changes.userName) {
      this.update();
    }
  }

  update() {
    const hash = MD5(this.userName).toString();
    jdenticon.update(this.icon.nativeElement, hash);
  }

}
