/* eslint-disable */

declare var Object: any;
export interface GenericContentInterface {
  "id"?: string;
  "order"?: number;
  "title"?: string;
  "body"?: string;
}

export class GenericContent implements GenericContentInterface {
  "id": string = '';
  "order": number = <any>null;
  "title": string = '';
  "body": string = '';
  constructor(data?: Partial<GenericContentInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GenericContent`.
   */
  public static getModelName() {
    return "GenericContent";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GenericContent for dynamic purposes.
  **/
  public static factory(data: GenericContentInterface): GenericContent{
    return new GenericContent(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GenericContent',
      plural: 'GenericContents',
      path: 'GenericContents',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "title": {
          name: 'title',
          type: 'string'
        },
        "body": {
          name: 'body',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
