<div mat-dialog-title>確認済みの職員</div>
<div mat-dialog-content>
  <mat-list #list>
    <mat-list-item *ngFor="let confirmation of confirmations">
      <cm-staff-name-card matListAvatar [staff]="confirmation.staff" size="small" [avatarOnly]="true">
      </cm-staff-name-card>
      <div matListItemTitle>{{ confirmation.staff.lastName }} {{ confirmation.staff.firstName }}</div>
      <p matListItemLine>{{ confirmation.confirmedAt | date:'medium' }}に確認</p>
    </mat-list-item>
  </mat-list>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>閉じる</button>
</div>
