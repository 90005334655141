import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Chart, Legend, LinearScale, LineController, LineElement, PointElement, TimeScale, Tooltip } from 'chart.js';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LoopBackConfig } from './loopback';

if (environment.production) {
  enableProdMode();
}

LoopBackConfig.setBaseURL(environment.apiUrl);
LoopBackConfig.filterOnUrl();
LoopBackConfig.whereOnUrl();

Chart.register(
  LineController,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
);

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
