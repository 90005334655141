import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { FileInfoService } from 'src/app/services/file-info.service';
import { FileInfoWithData } from '../components/file-selector/file-selector.component';

@Pipe({
  name: 'fileUrl',
})
export class FileUrlPipe implements PipeTransform {

  constructor(
    private fileInfo: FileInfoService,
    private sanitizer: DomSanitizer,
  ) {}

  transform(file: FileInfoWithData, type: string = 'normal'): string | SafeUrl | SafeStyle {
    if (!file) {
      return null;
    }

    if (file._blob) {
      const url = URL.createObjectURL(file._blob);
      if (type === 'css') {
        return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
      } else {
        return this.sanitizer.bypassSecurityTrustUrl(url);
      }
    }

    let url = this.fileInfo.getUrl(file);
    const preventCache = file.name.endsWith('-D');

    if (preventCache) {
      url += (url.indexOf('?') === -1 ? '?' : '&') + '_=' + Date.now();
    }

    if (type === 'css') {
      return `url(${url})`;
    } else {
      return url;
    }
  }

}
