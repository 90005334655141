<div mat-dialog-title>職員を選択</div>
<div mat-dialog-content>
  <mat-selection-list #list>
    <mat-list-option *ngFor="let staff of staffs" [value]="staff" [selected]="selectedIds.indexOf(staff.id) !== -1">
      <cm-staff-name-card matListItemAvatar [staff]="staff" size="small" [avatarOnly]="true"></cm-staff-name-card>
      <div matListItemTitle>{{ staff.lastName }} {{ staff.firstName }}</div>
    </mat-list-option>
  </mat-selection-list>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>キャンセル</button>
  <button mat-stroked-button color="primary" (click)="onOkClick(list.selectedOptions)" [disabled]="okDisabled">OK</button>
</div>
